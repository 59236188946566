import { Box, Grid, TextFieldProps } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../constants";
import { CoreFormLayout, CoreFormPath } from "../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../styled/CoreForm.label";
import { CoreFormCaption } from "../styled/CoreFormCaption";
import { CoreFormFieldError } from "../styled/CoreFormFieldError";
import NoneElement from "./NoneElement";
import { CoreFormStack } from "../layout/CoreForm.stack";
import { getDurationObject } from "../../../../models/Record/origination";
import { StyledTextField } from "../styled/CoreFormText.field";

export type CoreDurationInputProps<T> = CoreFormPath<T> &
    CoreFormLayout & { registerDisabled?: boolean } & Omit<
        TextFieldProps,
        "error"
    > & {
        disabled?: boolean;
        labelProps?: CoreFormLabelProps;
        caption?: React.ReactNode;
        customStyles?: React.CSSProperties;
    };

const validateTime = (value: string, max: number) => {
    const numberValue: number = Math.min(Number(value), max);
    return numberValue > 0 ? numberValue : null;
}

export function CoreDurationInput<T>({
    path,
    labelProps,
    caption,
    disabled = false,
    customStyles = {},
    layout = "vertical",
    registerDisabled = false,
    ...muiTextFieldProps
}: CoreDurationInputProps<T>) {
    const formMethods = useFormContext<T>();
    const value = formMethods.watch(path);
    const errorMessage = _.get(formMethods.formState.errors, path)?.message;
    const [durationObject, setDurationObject] = useState(getDurationObject(value));
    const [stringValue, setStringValue] = useState<string>(durationObject.value || '');

    useEffect(() => {
        const hours: number = 3600 * Number(durationObject.hours || 0);
        const minutes: number = 60 * Number(durationObject.minutes || 0);
        const total: number = hours + minutes + Number(durationObject.seconds || 0);

        const newValue = total > 0 ? total : null;

        setStringValue(getDurationObject(newValue).value);
        formMethods.setValue(path, newValue);
    }, [durationObject]);
    
    const labelElement = <CoreFormLabel {...labelProps} />;

    const textFieldProps = {
        disabled,
        defaultValue: CORE_FORM_STRING_DEFAULT_VALUE,
        error: !_.isEmpty(errorMessage),
        sx: {
            ...customStyles,
        },
        inputProps: {
            "data-testid": `input-${path}`,
        },
        onKeyDown: (e) => {
            if (!['Backspace','Tab'].includes(e.key) && isNaN(Number(e.key))) {
                e.preventDefault();
            }
        },
        ...muiTextFieldProps,
    };

    const fieldElement = (
        <CoreFormStack direction='row'>
            <StyledTextField
                {...textFieldProps}
                onChange={(e) => {
                    setDurationObject({ ...durationObject, hours: validateTime(e.target.value, 9999)})
                }}
                placeholder="Hours"
                value={durationObject.hours}
            />
            <StyledTextField
                {...textFieldProps}
                onChange={(e) => {
                    setDurationObject({ ...durationObject, minutes: validateTime(e.target.value, 59)})
                }}
                placeholder="Minutes"
                value={durationObject.minutes}
            />
            <StyledTextField
                {...textFieldProps}
                onChange={(e) => {
                    setDurationObject({ ...durationObject, seconds: validateTime(e.target.value, 59)})
                }}
                placeholder="Seconds"
                value={durationObject.seconds}
            />
        </CoreFormStack>
    );

    const element = (
        <>
            {(!stringValue && disabled) &&
                <NoneElement />
            }

            {(stringValue && disabled) && (
                <StyledTextField
                    {...textFieldProps}
                    disabled={disabled}
                    value={stringValue}
                />
            )}

            {!disabled && (
                fieldElement
            )}
        </>
    )

    const elements =(
        <>
            {labelProps && labelElement}
            {element}
        </>
    );

    return (
        <Box>
            {elements}
            {caption && !errorMessage && (
                <CoreFormCaption>{caption}</CoreFormCaption>
            )}
            {errorMessage && (
                <CoreFormFieldError path={path} message={errorMessage} />
            )}
        </Box>
    );
}

CoreDurationInput.defaultProps = {
    caption: null,
};
