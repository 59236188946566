import { RecordBuilderInterface } from '../interfaces'
import {
    ASSET_DESTINATION_PLATFORMS,
    ASSET_CODES,
    ASSET_TYPES,
    SCHEDULING,
    SCHEDULING_PLATFORMS,
    SCHEDULING_SYSTEMS,
    SUPPLY_CHAIN_IDS,
    CLIENT_IDS,
    ALTERNATE_IDS,
    ORIGINATION,
    TITLES,
    DESCRIPTIONS,
    RIGHTS,
    IDS,
    ID_TYPES,
    CONTRIBUTOR_SECTIONS,
    NUMBERING,
    DESCRIPTORS,
    KNOWN_AS,
} from '../../../models/RecordBuilder'
import map from '../../../services/ApiClients/RecordBuilder/map'

const MapRecordBuilderConfiguration = (data: any): RecordBuilderInterface => {
    return {
        [TITLES]: data[map[TITLES].dataPath] || [],
        [DESCRIPTIONS]: data[map[DESCRIPTIONS].dataPath] || [],
        [CLIENT_IDS]: data[map[IDS].dataPath].filter(
            (item) => item.type == ID_TYPES.client
        ),
        [SUPPLY_CHAIN_IDS]: data[map[IDS].dataPath].filter(
            (item) => item.type == ID_TYPES.supplychain,
        ),
        [ALTERNATE_IDS]: data[map[IDS].dataPath].filter(
            (item) => item.type == ID_TYPES.alternate,
        ),
        [ORIGINATION]: data[map[ORIGINATION].dataPath] || [],
        [NUMBERING]: data[map[NUMBERING].dataPath] || [],
        [DESCRIPTORS]: data[map[DESCRIPTORS].dataPath] || [],
        [KNOWN_AS]: data[map[KNOWN_AS].dataPath] || [],
        [ASSET_TYPES]: data[map[ASSET_TYPES].dataPath] || [],
        [ASSET_CODES]: data[map[ASSET_CODES].dataPath] || [],
        [ASSET_DESTINATION_PLATFORMS]: data[map[ASSET_DESTINATION_PLATFORMS].dataPath] || [],
        [SCHEDULING]: data[map[SCHEDULING].dataPath] || [],
        [RIGHTS]: data[map[RIGHTS].dataPath] || [],
        [SCHEDULING_PLATFORMS]: data[map[SCHEDULING_PLATFORMS].dataPath] || [],
        [SCHEDULING_SYSTEMS]: data[map[SCHEDULING_SYSTEMS].dataPath] || [],
        [CONTRIBUTOR_SECTIONS]: data[map[CONTRIBUTOR_SECTIONS].dataPath] || [],
    }
}

export default MapRecordBuilderConfiguration