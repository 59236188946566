import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { isEmpty } from "lodash"
import { useStores } from "../../store/rootStore";
import CoreDynamicForm from '../../../../../core/Form/dynamic'
import { coreDialogProps } from "../../utils/constants"
import { CoreDialog } from "../../../../../core/Dialog/CoreDialog";
import { CreditListInterface } from "../../store/interfaces"
import { actionTypes } from "../../config/creditList.config"

const UpdateCreditListForm = observer(() => {
    const { 
        creditListUpdateStore,
        creditListValidationStore,
        rootStore
    } = useStores();

    useEffect(() => {
        creditListUpdateStore.initialize()

        creditListValidationStore.initialize()
        creditListValidationStore.setActionType(actionTypes.create)

        return () => {
            creditListUpdateStore.reset()
        }
    }, [])

    if (isEmpty(creditListUpdateStore.coreFormFields)) {
        return <></>
    }

    return (
        <CoreDialog
            {...coreDialogProps}
            open={true}
            setOpen={(open) => {}}
            title="Edit Credit List"
            errorAlerts={creditListValidationStore.errorAlerts}
            buttons={{
                discard: {
                    label: "Cancel",
                    handleOnDiscard: () => {
                        rootStore.setShowCreditListUpdateForm(false)
                        creditListUpdateStore.reset()
                    }
                },
                submit: {
                    handleOnSubmit: () => rootStore.handleUpdateCreditList(),
                    label: "Submit",
                    disabled: false
                }
            }}>
                
            <CoreDynamicForm 
                fields={creditListUpdateStore.coreFormFields} 
                data={creditListUpdateStore.creditList}
                onChange={(values: CreditListInterface) => creditListUpdateStore.setCreditList(values)}
                defaultValues={creditListUpdateStore.creditListOriginal}
                handleErrors={(errors: any) => creditListValidationStore.setErrors(errors)}
                stackStyles={{
                    padding: 0
                }}
            />
        </CoreDialog>
    )
})

export default UpdateCreditListForm