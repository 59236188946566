
import React, { useEffect } from "react";
import _ from "lodash";
import { useAutocompleteSuggestions } from "../../../Autocomplete/useAutocompleteSuggestions";
import { usePopperState } from "../../utils/usePopperState";
import { CoreFormLabelProps } from "../styled/CoreForm.label";
import { AutocompleteSuggestions } from "../../../Autocomplete/AutocompleteSuggestions";
import { useFormContext } from "react-hook-form";
import { CoreFormLayout } from "../../types";
import { TextFieldProps } from "@mui/material";
import NoneElement from "./NoneElement";
import { ChipsArray } from "./Select/components/ChipsArray";

export type CoreAutocompleteProps<T> = CoreFormLayout &
    { registerDisabled?: boolean } & Omit<
        TextFieldProps,
        "error"
    > & {
        disabled?: boolean;
        labelProps?: CoreFormLabelProps;
        customStyles?: React.CSSProperties;
        suggestions?: any[]
        arrayPath: string
        inputPath: string
        chipsLabel?: string
        isFixedList?: boolean
        valueKey?: string //Used if you want the payload to be an array of strings
        textKey?: string 
        chipArrayStyles?: any,
        dataType?: string
    };

export function CoreAutocomplete<T>({
    labelProps = {
        text: "Tags",
    },
    placeholder = "Enter an option...",
    arrayPath,
    inputPath,
    disabled,
    suggestions = [],
    chipsLabel,
    isFixedList,
    valueKey,
    textKey = "text",
    chipArrayStyles = {},
    dataType
}: CoreAutocompleteProps<T>) {
    const formMethods = useFormContext();

    const { addNewValue, replace, value } = useAutocompleteSuggestions({
        arrayPath,
        inputPath
    }); 

    const { open, closePopper, openPopper } = usePopperState();
    const arrayValue: string[] = formMethods.getValues(arrayPath);

    return (
        <>
            {(_.isEmpty(arrayValue) && disabled) &&
                <NoneElement
                    text="None Selected"
                />
            }

            {(!_.isEmpty(arrayValue) && disabled) &&
                <ChipsArray 
                    selected={arrayValue}
                    options={suggestions}
                    replace={replace}
                    formMethods={formMethods}
                    path={arrayPath}
                    chipsLabel={chipsLabel}
                    textKey={textKey}
                    chipArrayStyles={{
                        "& .MuiChip-deleteIcon": {
                            display: disabled && "none"
                        },
                        ...chipArrayStyles
                    }}
                />
            }

            {!disabled && (
                <AutocompleteSuggestions
                    labelProps={labelProps}
                    placeholder={placeholder}
                    suggestions={suggestions.filter((suggestion) => (
                        suggestion?.text?.toLowerCase()?.includes(value?.toLowerCase()) && 
                        !_.some(arrayValue, suggestion)
                    ))}
                    completeSuggestions={suggestions}
                    path={inputPath}
                    arrayPath={arrayPath}
                    headerText="Suggestions"
                    onClick={(option: (typeof suggestions)[0]) => {
                        if (!option[inputPath]) {
                            return
                        }

                        if (!suggestions.includes(option)) {
                            const newValue = dataType === 'String' 
                                ? option[inputPath]
                                : { text: option[textKey], value: option[valueKey] }

                            addNewValue(newValue)
                        } else {
                            addNewValue(valueKey ? option[valueKey] : option);
                        }
                        
                        closePopper();
                    }}
                    addNewValue={addNewValue}
                    replace={replace}
                    popperProps={{
                        open,
                        closePopper,
                        openPopper,
                    }}
                    disabled={disabled}
                    isFixedList={isFixedList}
                    chipsLabel={chipsLabel}
                    textInputStyles={{
                        "& .MuiOutlinedInput-input::placeholder": {
                            color: 'rgba(47, 47, 47, 0.57)',
                            opacity: 1,
                        },
                    }}
                    chipArrayStyles={chipArrayStyles}
                    textKey={textKey}
                />
            )}
        </>
    );
}