import _ from "lodash";

export default function UpdateDataTransformer(
    updatedData = {},
    originalData = {},
) {
    let result = {};

    const deepObjectCompare = (objectKey) => {
        let target = updatedData[objectKey];
        let originalTarget = originalData[objectKey];
        result[objectKey] = {};

        for (let key in target) {
            if (target.hasOwnProperty(key) && originalTarget) {
                if (!_.isEqual(target[key], originalTarget[key])) {
                    result[objectKey][key] = target[key];
                }
            }

            if (!originalTarget) {
                result[objectKey][key] = target[key];
            }
        }
    };

    const compare = (objectKey) => {
        let target = updatedData[objectKey];
        let originalTarget = originalData[objectKey];
        result[objectKey] = [];

        if (!_.isEqual(target, originalTarget)) {
            result[objectKey] = target;
        }
    };

    const compareSingleValue = (valueKey) => {
        if (updatedData[valueKey] !== originalData[valueKey]) {
            result[valueKey] = updatedData[valueKey];
        }
    };

    if (typeof updatedData == "object" && typeof originalData == "object") {
        if (updatedData.eidr_id !== undefined) {
            compare("eidr_id");
        }

        if (updatedData.security_level !== undefined) {
            compareSingleValue("security_level");
        }

        if (updatedData.status !== undefined) {
            compareSingleValue("status");
        }

        if (updatedData.original_title !== undefined) {
            compare("original_title");
        }

        // Objects

        if (updatedData.ids !== undefined) {
            deepObjectCompare("ids");
        }

        if (updatedData.origination !== undefined) {
            deepObjectCompare("origination");
        }

        if (updatedData.numbering !== undefined) {
            deepObjectCompare("numbering");
        }

        if (updatedData.origination_v2 !== undefined) {
            deepObjectCompare("origination_v2");
        }

        if (updatedData.descriptors !== undefined) {
            deepObjectCompare("descriptors");
        }

        if (updatedData.properties !== undefined) {
            deepObjectCompare("properties");
        }

        if (updatedData.regional_properties !== undefined) {
            deepObjectCompare("regional_properties");
        }

        if (updatedData.classification !== undefined) {
            deepObjectCompare("classification");
        }

        if (updatedData.season_numbering !== undefined) {
            deepObjectCompare("season_numbering");
        }

        if (updatedData.episode_numbering !== undefined) {
            deepObjectCompare("episode_numbering");
        }

        if (updatedData.short_numbering !== undefined) {
            deepObjectCompare("short_numbering");
        }

        if (updatedData.storyline_numbering !== undefined) {
            deepObjectCompare("storyline_numbering");
        }

        if (updatedData.taxonomies !== undefined) {
            deepObjectCompare("taxonomies");
        }

        if (updatedData.additional_information !== undefined) {
            result.additional_information = updatedData.additional_information;
        }

        if (updatedData.ratings !== undefined) {
            result.ratings = updatedData.ratings;
        }

        if (updatedData.box_office_and_performance !== undefined) {
            result.box_office_and_performance =
                updatedData.box_office_and_performance;
        }

        if (updatedData.age_guidance !== undefined) {
            result.age_guidance = updatedData.age_guidance;
        }

        if (updatedData.verified_fields !== undefined) {
            deepObjectCompare("verified_fields");
        }

        // Arrays

        if (updatedData.titles !== undefined) {
            compare("titles");
        }

        if (updatedData.synopses !== undefined) {
            compare("synopses");
        }

        if (updatedData.certifications !== undefined) {
            compare("certifications");
        }

        if (updatedData.links !== undefined) {
            compare("links");
        }

        if (updatedData.games !== undefined) {
            compare("games");
        }

        if (updatedData.images !== undefined) {
            compare("images");
        }

        if (updatedData.credit_lists !== undefined) {
            result.credit_lists = updatedData.credit_lists;
        }

        if (updatedData.contributors !== undefined) {
            compare("contributors");
        }

        if (updatedData.rights !== undefined) {
            compare("rights");
        }

        if (updatedData.channels !== undefined) {
            compare("channels");
        }

        if (updatedData.characters !== undefined) {
            compare("characters");
        }

        if (updatedData.linkage !== undefined) {
            compare("linkage");
        }

        if (updatedData.shows !== undefined) {
            compare("shows");
        }

        if (updatedData.assets !== undefined) {
            compare("assets");
        }

        if (updatedData.running_order !== undefined) {
            compare("running_order");
        }

        if (updatedData.regions !== undefined) {
            compare("regions");
        }

        if (updatedData.localisations !== undefined) {
            compare("localisations");
        }

        if (updatedData.financials !== undefined) {
            compare("financials");
        }

        if (updatedData.awards !== undefined) {
            compare("awards");
        }

        if (updatedData.content_advisories !== undefined) {
            compare("content_advisories");
        }

        if (updatedData.supply_chain !== undefined) {
            compare("supply_chain");
        }

        if (updatedData.trivia !== undefined) {
            compare("trivia");
        }

        if (updatedData.programming_dates !== undefined) {
            compare("programming_dates");
        }

        if (updatedData.scheduling_conditions !== undefined) {
            compare("scheduling_conditions");
        }

        if (updatedData.catalogs !== undefined) {
            compare("catalogs");
        }

        if (updatedData.ancillaries !== undefined) {
            compare("ancillaries");
        }

        if (!_.isEmpty(updatedData.options)) {
            compare("options");
        } else {
            delete result.options;
        }

        if (updatedData.release_dates !== undefined) {
            result.release_dates = updatedData.release_dates;
        }

        if (updatedData.akas !== undefined) {
            result.akas = updatedData.akas;
        }
    }

    return result;
}
