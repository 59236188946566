export enum DataType {
    Array = 'Array',
    Boolean = 'Boolean',
    Integer = 'Integer',
    Link = 'Link',
    Number = 'Number',
    Object = 'Object',
    String = 'String'
}

export enum InputType {
    Boolean = 'Boolean',
    Date = 'Date',
    DateTime = 'DateTime',
    Select = 'Select',
    SelectMultiple = 'SelectMultiple',
    SelectMultipleChips = 'SelectMultipleChips',
    String = 'String',
    Text = 'Text',
    TextArea = 'TextArea',
    Year = 'Year',
    Time = 'Time',
    Duration = 'Duration',
    PhoneNumber = 'PhoneNumber',
    Autocomplete = 'Autocomplete',
    ChipEntry = 'ChipEntry',
    ColorPalette = 'ColorPalette',
    TextFramed = 'TextFramed',
    SelectFramed = 'SelectFramed',
    Numeric = 'Numeric',
    Alphanumeric = 'Alphanumeric',
    // Special Components
    AutocompleteContributors = 'AutocompleteContributors'
}

export enum BooleanInputType {
    Checkbox = 'Checkbox',
    Select = 'Select',
    ToggleSwitch = 'ToggleSwitch',
    RadioButton = 'RadioButton',
}

export enum SelectType {
    Select = InputType.Select,
    SelectMultiple = InputType.SelectMultiple,
    SelectMultipleChips = InputType.SelectMultipleChips,
    Autocomplete = InputType.Autocomplete,
    SelectFramed = InputType.SelectFramed
}

export enum MultiSelectType {
    SelectMultiple = InputType.SelectMultiple,
    SelectMultipleChips = InputType.SelectMultipleChips,
    Autocomplete = InputType.Autocomplete,
}

export enum DateType {
    Date = 'Date',
    DateTime = 'DateTime',
    Time = 'Time',
    Year = 'Year'
}

export enum Permission {
    Edit = 'Edit',
    Add = 'Add',
    Remove = 'Remove',
    View = 'View'
}

type BasicFeatureFlag = {
    enabled: boolean
}

export type BooleanDisplayProperty = {
    falseLabel: string
    trueLabel: string
    type: BooleanInputType;
}

export type EditableOption = {
    booleanDisplay?: BooleanDisplayProperty
    enabled: boolean
    inputType?: InputType
    optionsManagedList?: {
        enabled: boolean
        key?: string
        textField?: string
        valueField?: string
        dynamic?: boolean
        itemDataType?: string
        option?: {
            dataType: DataType
            textField: string
            valueField: string
        }
    }
    dateTime?: {
        format: string
    }
    characterCounter?: {
        enabled: boolean
    }
}

export type LayerOption = {
    level: number
    columns: number
}

export type ValidationFeatureFlag = {
    enabled: boolean
    validation: ValidationLayer
}

export type IconOption = {
    enabled: boolean
    path: string
    source: IconSource
}

export enum IconSource {
    Fabric = "Fabric",
}

export enum ValidationLayer {
    None = "None",
    API = "API",
    Client = "Client",
    All = "All"
}

export enum DateTimeOptionLayer {
    DateTime = "DateTime",
    Date = "Date",
    Time = "Time"
}

export enum DateOptionFormat {
    MMDDYYYY = "MM/DD/YYYY",
    DDMMYYYY = "DD/MM/YYYY"
}

//Do not reorder
export enum TimeOptionFormat {
    HHMMSSAMPM = "hh:mm:ss A",
    HHMMAMPM = "hh:mm A",
    HHMMSS = "HH:mm:ss",
    HHMM = "HH:mm",
}

export type Permissions = {
    archive: Permission[]
    categories: Permission[]
    optionsManagedList: Permission[]
    archive: Permission[]
}

export type BuilderPermissions = {
    create: boolean
    update: boolean
    delete: boolean
}

export type ValidationOption = {
    readOnly: ValidationFeatureFlag
    required: ValidationFeatureFlag
    unique: ValidationFeatureFlag & { withinCatalog: any }
    characterLimit: ValidationFeatureFlag & { limit: number, minLimit?: number }
    rangeLimit: ValidationFeatureFlag & { min: number, max: number },
    email: ValidationFeatureFlag,
    url: ValidationFeatureFlag
}

export type MessageOption = {
    enabled: boolean
    code: string
}

export type MessagesOption = {
    editWarning: MessageOption 
    archiveWarning: MessageOption
}

type FieldOptions = {
    default?: {
        enabled: boolean
        value?: any
    }
    layer?: LayerOption
    editable?: EditableOption
    validation?: ValidationOption
    hidden?: BasicFeatureFlag
    hiddenForCategories?: string[]
    icon?: IconOption
    includeInSNS?: BasicFeatureFlag
    taskManager?: BasicFeatureFlag
    messages?: MessagesOption
}

export enum ViewType {
    OneWay = 'OneWay',
    TwoWay = 'TwoWay',
    ThreeWay = 'ThreeWay',
}

export default interface Field {
    id?: number
    key: string
    displayName?: string
    order: number
    archived: boolean
    dataType?: DataType
    label?: String
    view?: ViewType
    options: FieldOptions
    categories?: string[]
    permissions?: Permissions
    [key: string]: any
}