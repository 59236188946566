import React from "react";
import axios, { AxiosResponse } from 'axios'
import { CmsInterface } from "../../../components/Global/interfaces"
import { recordServiceV2Headers } from "../utils/generateHeaders"
import { RecordChangesCreditsInterface } from "../../../features/Credits/v4/store/interfaces"

export async function getRecordCreditLists(cmsData: CmsInterface, metaId: string): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2Records = "" } = apiUrls;

    return axios.get(`${recordServiceV2Records}/${metaId}/credit-lists`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function getRecordCredits(cmsData: CmsInterface, metaId: string): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2Records = "" } = apiUrls;

    return axios.get(`${recordServiceV2Records}/${metaId}/credits`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function getCreditListCredits(cmsData: CmsInterface, creditListId: string): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2CreditLists = "" } = apiUrls;

    return axios.get(`${recordServiceV2CreditLists}/${creditListId}/credits`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function getCreditByLocale(cmsData: CmsInterface, creditId: string, locale: string = 'ENG'): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2Credits = "" } = apiUrls;

    return axios.get(`${recordServiceV2Credits}/${creditId}?locale=${locale}`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function getRecordLanguages(cmsData: CmsInterface, metaId: string): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2Records = "" } = apiUrls;

    return axios.get(`${recordServiceV2Records}/${metaId}/credits/locales`, recordServiceV2Headers(user) )
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}

export async function postCreditsCreditListsToRecord(cmsData: CmsInterface, metaId: string, data: RecordChangesCreditsInterface): Promise<AxiosResponse> {
    const { 
        apiUrls = {},
        user = {}
    } = cmsData || {};

    const { recordServiceV2Records = "" } = apiUrls;

    const allowedFields = [
        'credits',
        'credit_lists'
    ]

    const filteredData = Object.keys(data)
        .filter(key => allowedFields.includes(key))
        .reduce((result, key) => {
            result[key] = data[key];
            return result;
        }, {})

    return axios.post(`${recordServiceV2Records}/${metaId}/credits`, filteredData, recordServiceV2Headers(user))
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            return {
                error: error,
                ...error
            }
        });
}