import { 
    RootStoreInterface,
    CreditsStoreInterface,
    ActiveCreditsStoreInterface,
    InctiveCreditsStoreInterface,
    ContributorsStoreInterface,
    BulkActionsStoreInterface,
    OptionsStoreInterface,
    PermissionsStoreInterface,
    CreditListsStoreInterface,

    CreditListStoreInterface,
    CreditListCreateStoreInterface,
    CreditListUpdateStoreInterface,
    CreditListValidationStoreInterface,
    
    CreditFormStoreInterface,
    CreditFormCreateStoreInterface,
    CreditFormUpdateStoreInterface,
    CreditFormValidationStoreInterface,
} from "./interfaces"

export const rootStoreInitialState: RootStoreInterface = {
    cmsData: {},
    meta_id: '',
    disabled: false,
    showCreditListCreateForm: false,
    showCreditListUpdateForm: false,
    showCreditCreateForm: false,
    showCreditUpdateForm: false,
    coreDialog: {},
    dispatch: () => {},
    handleFormChange: () => {},
};

export const creditsStoreInitialState: CreditsStoreInterface = {
    credits: [],
    originalCredits: [],
    fetching: false,
};

export const activeCreditsStoreInitialState: ActiveCreditsStoreInterface = {
    activeCredits: [],
    activeTableCredits: [],
    tableConfig: {},
    selected: [],
    selectedId: ''
};

export const inactiveCreditsStoreInitialState: InctiveCreditsStoreInterface = {
    tableConfig: {},
    inactiveCredits: [],
    inactiveTableCredits: [],
    selected: [],
};

export const contributorsStoreInitialState: ContributorsStoreInterface = {
    contributors: [],
    fetching: false,
};

export const bulkActionsStoreInitialState: BulkActionsStoreInterface = {};

export const optionsStoreInitialState: OptionsStoreInterface = {
    languageCode: '',
    languageOptions: [],
    roles: [],
    creditLocales: []
};

export const permissionsStoreInitialState: PermissionsStoreInterface = {
    userHasDeletePermission: false,
    userHasUpdatePermission: false,
    userHasCreatePermission: false,
};

export const creditListsStoreInitialState: CreditListsStoreInterface = {
    creditLists: [],
    originalCreditLists: [],
    selectedCreditList: {},
    fetching: false,
    error: false,
    defaultCreditListCode: "ORIG"
};

// Credit List

export const creditListStoreInitialState: CreditListStoreInterface = {
    configuration: {},
    coreFormFields: [],
    creditListOriginal: {},
    creditList: {}
};

export const createStoreInitialState: CreditListCreateStoreInterface = {};

export const updateStoreInitialState: CreditListUpdateStoreInterface = {};

export const validationStoreInitialState: CreditListValidationStoreInterface = {
    errorAlerts: [],
    errors: null,
    actionType: ''
};

// Credit Form

export const creditFormStoreInitialState: CreditFormStoreInterface = {
    configuration: {},
    coreFormFields: [],
    creditOriginal: {},
    credit: {},
    defaultLocale: '',
    fetching: false,
    changeId: ''
};

export const creditFormCreateStoreInitialState: CreditFormCreateStoreInterface = {};

export const creditFormUpdateStoreInitialState: CreditFormUpdateStoreInterface = {};

export const creditFormValidationStoreInitialState: CreditFormValidationStoreInterface = {
    errorAlerts: [],
    errors: null
};