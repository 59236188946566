import { RootStore } from './rootStore'
import FieldInterface from '../interfaces/Field.interface'
import { action, extendObservable, makeObservable, observable } from "mobx";

interface InitialInterface {
    fields: FieldInterface[]
}

const initialState: InitialInterface = {
    fields: [],
};

export class FormStore {

    fields: FieldInterface[]
    disabled: boolean = false
    rootStore: RootStore
    category?: string = ""

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            initialize: action,
            disabled: observable,
        });

        extendObservable(this, initialState);
    }

    initialize(
        fields,
        disabled,
        category = "",
    ): void {

        this.reset()
        this.fields = fields
        this.disabled = disabled
        this.category = category
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }
}