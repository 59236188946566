import React from "react";
import { extendObservable, makeObservable, action, computed } from 'mobx'
import { isEqual, differenceWith, isEmpty, isString } from 'lodash';
import { rootStoreInitialState } from "./state"
import { CoreDialogInterface, CreditListInterface, RecordChangesCreditsInterface } from "./interfaces"
import { RecordInterface } from "../../../../models/Record/model"
import { globalStateInterface } from "../../../../components/Global/interfaces"
import { CreditsStore } from "./creditsStore"
import { ActiveCreditsStore } from "./activeCreditsStore"
import { InactiveCreditsStore } from "./inactiveCreditsStore"
import { BulkActionsStore } from "./bulkActionsStore"
import { OptionsStore } from "./optionsStore"
import { PermissionsStore } from "./permissionsStore"
import { CreditListsStore } from "./creditListsStore"

// Credit List
import { CreditListStore } from "./credit-list/creditListStore"
import { CreditListCreateStore } from "./credit-list/creditListCreateStore"
import { CreditListUpdateStore } from "./credit-list/creditListUpdateStore"
import { CreditListValidationStore } from "./credit-list/creditListValidationStore"

// Credit Form
import { CreditFormStore } from "./credit-form/creditFormStore"
import { CreditFormCreateStore } from "./credit-form/creditFormCreateStore"
import { CreditFormUpdateStore } from "./credit-form/creditFormUpdateStore"
import { CreditFormValidationStore } from "./credit-form/creditFormValidationStore"

// Credits Table
import { ContributorsStore } from "./contributorsStore";
import { setSnackbar } from "../../../../components/Global/store/reducer"

export class RootStore {
    rootStore: RootStore;
    creditsStore: CreditsStore
    activeCreditsStore: ActiveCreditsStore
    inactiveCreditsStore: InactiveCreditsStore
    bulkActionsStore: BulkActionsStore
    optionsStore: OptionsStore
    permissionsStore: PermissionsStore
    creditListsStore: CreditListsStore
    contributorsStore: ContributorsStore

    // Credit List
    creditListStore: CreditListStore
    creditListCreateStore: CreditListCreateStore
    creditListUpdateStore: CreditListUpdateStore
    creditListValidationStore: CreditListValidationStore

    // Credit Form
    creditFormStore: CreditFormStore
    creditFormCreateStore: CreditFormCreateStore
    creditFormUpdateStore: CreditFormUpdateStore
    creditFormValidationStore: CreditFormValidationStore

    globalState: globalStateInterface;
    dispatch: any;
    meta_id: string;
    recordData: RecordInterface;
    disabled: boolean;
    showCreditListCreateForm: boolean;
    showCreditListUpdateForm: boolean;
    showCreditCreateForm: boolean;
    showCreditUpdateForm: boolean;
    coreDialog: CoreDialogInterface | {}

    handleFormChange: Function

    constructor() {
        this.rootStore = this;

        this.creditsStore = new CreditsStore(this)
        this.activeCreditsStore = new ActiveCreditsStore(this)
        this.inactiveCreditsStore = new InactiveCreditsStore(this)
        this.bulkActionsStore = new BulkActionsStore(this)
        this.optionsStore = new OptionsStore(this)
        this.permissionsStore = new PermissionsStore(this)
        this.creditListsStore = new CreditListsStore(this)
        this.contributorsStore = new ContributorsStore(this)

        // Credit List
        this.creditListStore = new CreditListStore(this)
        this.creditListCreateStore = new CreditListCreateStore(this)
        this.creditListUpdateStore = new CreditListUpdateStore(this)
        this.creditListValidationStore = new CreditListValidationStore(this)

        // Credit Form
        this.creditFormStore = new CreditFormStore(this)
        this.creditFormCreateStore = new CreditFormCreateStore(this)
        this.creditFormUpdateStore = new CreditFormUpdateStore(this)
        this.creditFormValidationStore = new CreditFormValidationStore(this)

        makeObservable(this, {
            reset: action,

            setDisabled: action,
            setShowCreditListCreateForm: action,
            setShowCreditListUpdateForm: action,
            setShowCreditCreateForm: action,
            setShowCreditUpdateForm: action,
            setCoreDialog: action,
            isFetchingCredits: computed,

            handleCreditListChange: action,
            handleCreditListEdit: action,
            handleDispatchError: action,
            handleTransformItem: action,
            handleTransformItems: action,
            handleLocaleChange: action,
            handleFetchCreditLists: action
        })

        extendObservable(this, rootStoreInitialState);
    }

    async initialize(globalState: globalStateInterface, dispatch: any, recordData: RecordInterface, handleFormChange: Function) {
        this.dispatch = dispatch
        this.globalState = globalState
        this.handleFormChange = handleFormChange
        this.meta_id = recordData?.meta_id
        this.recordData = recordData

        this.optionsStore.initialize()
        this.permissionsStore.initialize()

        this.creditListsStore.initialize()
        this.creditsStore.initialize()
        this.contributorsStore.initialize()

        this.activeCreditsStore.initialize()
        this.inactiveCreditsStore.initialize()

        await this.optionsStore.handleSetRoles()
        await this.handleInitialCreditsFetch()
    }

    reset(): void {
        //
    }

    getChangedObjects(oldArray: Array<any>, newArray: Array<any>) {
        return differenceWith(newArray, oldArray, isEqual);
    }

    setDisabled(disabled: boolean): void {
        this.disabled = disabled
    }

    setShowCreditListCreateForm(showCreditListCreateForm: boolean): void {
        this.showCreditListCreateForm = showCreditListCreateForm
    }

    setShowCreditListUpdateForm(showCreditListUpdateForm: boolean): void {
        this.showCreditListUpdateForm = showCreditListUpdateForm
    }

    setShowCreditCreateForm(showCreditCreateForm: boolean): void {
        this.showCreditCreateForm = showCreditCreateForm
    }

    setShowCreditUpdateForm(showCreditUpdateForm: boolean): void {
        this.showCreditUpdateForm = showCreditUpdateForm
    }

    setCoreDialog(coreDialog: CoreDialogInterface | {}): void {
        this.coreDialog = coreDialog
    }

    get isFetchingCredits(): boolean {
        return this.creditListsStore.fetching 
            || this.creditsStore.fetching 
            || this.contributorsStore.fetching;
    }

    async handleFetchCreditLists() {
        await this.creditListsStore.handleFetchCreditLists()
        this.meta_id && await this.creditListsStore.handleFetchCredits()
    }

    async handleInitialCreditsFetch(): Promise<void> {
        this.creditsStore.setFetching(true)

        await Promise.all([
            this.handleFetchCreditLists(), 
            this.creditsStore.handleFetchRecordCredits()
        ]);

        await Promise.all([
            this.creditsStore.handleFetchCreditData(),
            this.contributorsStore.handleFetchContributors()
        ]);
        
        this.creditsStore.setFetching(false)
        this.creditsStore.getCreditLocales()

        this.activeCreditsStore.handleSetConfig()
        this.inactiveCreditsStore.handleSetConfig()

        this.activeCreditsStore.handleSetCredits()
        this.inactiveCreditsStore.handleSetCredits()
    }

    handleCreditListChange(id: string): void {
        this.creditListsStore.handleSetSelectedCreditListByIdOrCode(id)
        
        this.activeCreditsStore.handleSetCredits()
        this.activeCreditsStore.handleSetConfig()

        this.inactiveCreditsStore.handleSetCredits()
        this.inactiveCreditsStore.handleSetConfig()
    }

    handleCreditListEdit(creditList: CreditListInterface): void {
        this.creditListUpdateStore.setCreditList(creditList)
        this.creditListUpdateStore.setCreditListOriginal(creditList)

        this.activeCreditsStore.handleSetConfig()
        this.inactiveCreditsStore.handleSetConfig()

        this.setShowCreditListUpdateForm(true)
    }

    handleCreateCreditList(): void {
        this.creditListCreateStore.handleSubmit()

        this.activeCreditsStore.handleSetConfig()
        this.inactiveCreditsStore.handleSetConfig()
    }

    handleUpdateCreditList(): void {
        this.creditListUpdateStore.handleSubmit()

        this.activeCreditsStore.handleSetConfig()
        this.inactiveCreditsStore.handleSetConfig()
    }

    async handleLocaleChange(languageCode: string) {
        this.optionsStore.setLanguageCode(languageCode)

        this.creditsStore.setFetching(true)
        await this.creditsStore.handleFetchCreditData()
        this.creditsStore.setFetching(false)

        this.activeCreditsStore.handleSetCredits()  
        this.inactiveCreditsStore.handleSetCredits()
    }

    handleChange(): void {
        const creditListChanges = this.getChangedObjects(this.creditListsStore.originalCreditLists, this.creditListsStore.creditLists)
        const creditChanges = this.getChangedObjects(this.creditsStore.originalCredits, this.creditsStore.credits)

        let changes: RecordChangesCreditsInterface = {}

        if (!isEmpty(creditListChanges)) {
            changes.credit_lists = this.creditListsStore.handleTransformCreditListsForApi(creditListChanges)
        }

        if (!isEmpty(creditChanges)) {
            changes.credits = this.creditsStore.handleTransformCreditsForApi(creditChanges)
        }

        if (!isEmpty(this.contributorsStore.contributors)) {
            changes.contributors = this.contributorsStore.getTransformContributorsForV1Api()
        }

        this.handleFormChange(changes)
    }

    handleDispatchError(error: string): void {
        this.dispatch(
            setSnackbar({ 
                message: error,
                severity: "error" 
            })
        )
    }

    handleTransformItem(fields: string[], item: any): any {
        let result = fields.reduce((result, key) => {
            if (item.hasOwnProperty(key)) {
                result[key] = item[key];
            }
            return result;
        }, {});

        Object.keys(result).forEach(key => {
            if (isString(result[key]) && isEmpty(result[key])) {
                result[key] = null;
            }
        })

        return result
    }

    handleTransformItems(fields: string[], items: any[]): any[] {
        return items.map(item => this.handleTransformItem(fields, item));
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
