import { isString, isEmpty } from "lodash"

export function getPipeDividedPasteData(e: React.ClipboardEvent<HTMLDivElement>): string[] | null {
    if (!window) {
        return null
    }

    let clipboardData: any
    let pastedData: string

    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    if (isString(pastedData) && pastedData) {
        const items = pastedData.split('|')

        const allowed = pastedData.includes('|') || (Array.isArray(items) && items.length == 1)

        if (allowed && !isEmpty(items)) {
            return items
        }
    }

    return null
}