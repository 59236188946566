import { isEmpty } from "lodash"
import { v4 as uuidv4 } from 'uuid';
import { AncillariesInterface } from "../../../models/Record/ancillaries"

export default function transformData(data: Partial<AncillariesInterface[]>): AncillariesInterface[] {
    if (isEmpty(data) || !Array.isArray(data)) {
        return []
    }

    return data?.map((ancillary: AncillariesInterface) => {
        return {
            ...ancillary,
            uuid: ancillary.uuid || uuidv4(),
            children: ancillary?.children?.map((category: AncillariesInterface) => {
                return {
                    ...category,
                    uuid: category.uuid || uuidv4(),
                    children: category.children?.map((subCategory: AncillariesInterface) => {
                        return {
                            ...subCategory,
                            uuid: subCategory.uuid || uuidv4()
                        }
                    })
                }
            })
        }
    })
}