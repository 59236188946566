import React, { useState, useRef, Fragment } from "react";
import _ from "lodash";
import { Divider } from '@mui/material';
import "./styles/_styles.scss";
import Settings from "../Settings";
import OutsideClick from "../OutsideClick";
import { envTest } from "../Environment";
import { CoreTooltip } from "../../core/Tooltip/CoreTooltip";

const CustomSelect = (props) => {
    const {
        items = [],
        groupedItems,
        label,
        styles = {},
        itemsStyles = {},
        itemStyles = {},
        labelStyles = {},
        background = "rgb(98, 98, 98)",
        color = "white",
        disabled,
        handleClick = () => {},
        platformImage = false,
        chevronImage = true,
        opacity = true,
        customImageRight,
        loading,
    } = props;

    const options = groupedItems || items
    const [open, setOpen] = useState(false);
    const ref = useRef();

    OutsideClick(ref, () => {
        setOpen(false);
    });

    let wrapperStyles = {
        ...styles,
        background,
        color,
        opacity: disabled && opacity ? 0.5 : 1,
    };

    const handleOnClick = (e) => {
        e.stopPropagation();

        if (disabled || loading) {
            return;
        }

        setOpen(!open);
    };

    const selectItemsStyles = {
        ...itemsStyles,
    };

    const RenderItems = (props) => {
        const { items } = props;

        return (
            <Fragment>
                {items
                    ?.filter((item) => !item.hidden)
                    ?.map((item, index) => {
                        let onClick = () => {};
                        if (!item.disabled) {
                            onClick = item.onClick
                                ? () => item.onClick()
                                : () => handleClick(item.value || item);
                        }

                        return (
                            <CoreTooltip 
                                title={item.tooltip}
                                key={`meta-custom-select-item-tooltip-${index}`}>

                                <div
                                    className={`meta-custom-select--item ${item.disabled ? 'disabled' : ''}`}
                                    disabled={item.disabled}
                                    style={{
                                        ...itemStyles,
                                    }}
                                    onClick={onClick}
                                >
                                    {item.text || item}
                                </div>
                            </CoreTooltip>
                        );
                    }
                )}
            </Fragment>
        );
    };

    return (
        <div
            className="meta-custom-select"
            data-testid="custom-select"
            style={wrapperStyles}
            ref={ref}
            onClick={handleOnClick.bind(this)}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    visibility: loading ? "hidden" : "visible",
                }}
            >
                {platformImage && (
                    <img
                        src={`${Settings.images.path}/svg/${
                            disabled ? "platform-grey-medium" : "platform-white"
                        }.svg`}
                        className="default-image"
                    />
                )}

                {label && (
                    <span className="label" style={labelStyles}>
                        {label}
                    </span>
                )}

                {chevronImage && (
                    <img
                        src={`${Settings.images.path}/svg/chevron-bottom-white.svg`}
                        className="default-image"
                    />
                )}

                {customImageRight && customImageRight}
            </div>

            {loading && (
                <img
                    src={Settings.images.loadingDotsWhite}
                    className="loading-image"
                    style={{
                        position: "absolute",
                        width: "1rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                />
            )}

            {open && !_.isEmpty(options) && (
                <div
                    className="meta-custom-select--items"
                    style={selectItemsStyles}
                >
                    {!groupedItems && <RenderItems items={items} />}

                    {groupedItems &&
                        groupedItems?.map((item, index) => {
                            const { label } = item;

                            return (
                                <Fragment key={`meta-custom-select-grouped-item-${index}`}>
                                    <div
                                        className="meta-custom-select--item label"
                                        style={itemStyles}
                                    >
                                        {label}
                                    </div>

                                    <RenderItems items={item.items || []} />

                                    {(groupedItems.length !== 1 && groupedItems.length !== (index + 1)) &&
                                         <Divider 
                                            sx={{
                                                margin: '0.25rem 0',
                                                width: '100%',
                                                background: '#E1E1E1'
                                            }}
                                         />
                                    }
                                </Fragment>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
