import React, { useContext, useEffect } from "react";
import _ from "lodash";
import "./css/styles.scss";
import "rc-tooltip/assets/bootstrap_white.css";
import AuditContext from "./context/Context";
import setField from "./context/actions/setField";

const AuditTooltip = (props) => {
    const context = useContext(AuditContext);

    const {
        children,
        wrapperStyles = {},
        fieldName = null,
        fieldKey = null,
    } = props;

    const { state, dispatch } = context;

    const identifier = fieldKey || fieldName;

    useEffect(() => {
        if (state.activeAudits) {
            if (!_.has(state, identifier)) {
                dispatch(setField(identifier, { ...props }));
            }
        }
    }, [state.activeAudits])

    return <div style={wrapperStyles}>{children}</div>;
};

export default AuditTooltip;
