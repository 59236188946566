export default `editable {
    enabled
    inputType
    booleanDisplay {
        type
        trueLabel
        falseLabel
    }
    optionsManagedList {
        enabled
        key
        adornmentKey
        dynamic
        textField
        valueField
        growing
        color
        filters
        option {
            dataType
            textField
            valueField
        }
    }
    dateTime {
        format
    }
}`;
