import { orderBy } from "lodash";
import CategoryTags from "../../../../../components/CategoryTags";

const customSortOrders: { [key: string]: string[] } = {
    child: [
        CategoryTags.FranchiseCollection.short,
        CategoryTags.Collection.short,
        CategoryTags.Show.short,
        CategoryTags.Season.short,
        CategoryTags.Storyline.short,
        CategoryTags.Version.short,
        CategoryTags.Episode.short,
        CategoryTags.Feature.short,
        CategoryTags.Special.short,
        CategoryTags.Short.short,
        CategoryTags.Podcast.short
    ],
    default: [
        CategoryTags.FranchiseCollection.short,
        CategoryTags.Collection.short,
        CategoryTags.Show.short,
        CategoryTags.Season.short,
        CategoryTags.Episode.short,
        CategoryTags.Feature.short,
        CategoryTags.Special.short,
        CategoryTags.Short.short,
        CategoryTags.Podcast.short,
        CategoryTags.Storyline.short,
        CategoryTags.Version.short,
    ]
};

const setCustomSortOrder = (category: string): string[] => {
    switch (category) {
        case CategoryTags.Episode.short:
        case CategoryTags.Feature.short:
        case CategoryTags.Special.short:
        case CategoryTags.Short.short:
        case CategoryTags.Podcast.short:
        case CategoryTags.Storyline.short:
            return customSortOrders.child;
        default:
            return customSortOrders.default;
    }
};

export const sortCategories = (category: string, categories: string[]) => {
    const customOrder = setCustomSortOrder(category);

    if (!customOrder.includes(category)) {
        customOrder.push(category);
    }

    return orderBy(categories, (c) => customOrder.indexOf(c));
};
