import React from "react";
import { SectionInterface } from "./interface";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import Ancillaries from "../../../../features/Ancillaries"

const SectionAncillaries = (props: SectionInterface) => {
    const { 
        data = {},
        disabled,
        handleFormChange = () => {}
    } = props;

    const { 
        ancillaries = [],
        category
    } = data;

    const sectionProps = getSectionCommonProps({ ...props, field: "ancillaries" })

    return (
        <Ancillaries
            {...sectionProps}
            handleFormChange={(data: Array<any>) => handleFormChange("ancillaries", data)}
            data={ancillaries}
            disabled={disabled}
            category={category}
        />
    );
};

export default SectionAncillaries;
