import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { isEmpty } from "lodash"
import { useStores } from "../../store/rootStore";
import CoreDynamicForm from '../../../../../core/Form/dynamic'
import { coreDialogProps } from "../../utils/constants"
import { CoreDialog } from "../../../../../core/Dialog/CoreDialog";
import { CreditInterface } from "../../store/interfaces"
import { fieldCharacterNameLanguage } from "../../config/creditForm.config"

const CreateCreditForm = observer(() => {
    const { 
        creditFormCreateStore,
        creditFormValidationStore,
        rootStore
    } = useStores();

    useEffect(() => {
        creditFormCreateStore.initialize()

        return () => {
            creditFormCreateStore.reset()
            creditFormValidationStore.reset()
        }
    }, [])

    if (isEmpty(creditFormCreateStore.coreFormFields)) {
        return <></>
    }

    return (
        <CoreDialog
            {...coreDialogProps}
            open={true}
            setOpen={(open) => {}}
            title="Add New Credit"
            errorAlerts={creditFormValidationStore.errorAlerts}
            buttons={{
                discard: {
                    label: "Cancel",
                    handleOnDiscard: () => {
                        rootStore.setShowCreditCreateForm(false)
                        creditFormCreateStore.reset()
                    }
                },
                submit: {
                    handleOnSubmit: () => creditFormCreateStore.handleSubmit(),
                    label: "Add Credit",
                    disabled: false
                }
            }}>
                
            <CoreDynamicForm 
                fields={creditFormCreateStore.coreFormFields} 
                data={creditFormCreateStore.credit}
                onChange={(values: CreditInterface, item: any) => {
                    if(item.name !== "text_contributor_id") {
                        creditFormCreateStore.handleChange(values, item)
                    }
                }}
                handleErrors={(errors: any) => creditFormValidationStore.setErrors(errors)}
                key={`credit-form-${creditFormCreateStore.credit.contributor_id}-${creditFormCreateStore.credit?.[fieldCharacterNameLanguage.key]}`}
                fieldJquery={creditFormCreateStore.getFieldJquery()}
                stackStyles={{
                    padding: 0
                }}
            />
        </CoreDialog>
    )
})

export default CreateCreditForm