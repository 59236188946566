import { z } from "zod";
import FieldInterface, { DataType } from '../../interfaces/Field.interface'

export const SelectMultipleInputRule = (field: FieldInterface) => {
    let arraySchema = (field.type.select?.option.dataType == DataType.String || !field.type.select?.growing)
        ? z.array(z.string())
        : z.object({ text: z.string(), value: z.string() }).array()

    if (field.validation?.rangeLimit?.enabled && field.validation.rangeLimit.max !== undefined) {
        arraySchema = arraySchema.max(field.validation.rangeLimit.max, {
            message: `Maximum ${field.validation.rangeLimit.max} selections allowed`
        });
    }

    if (field.validation?.required?.enabled) {
        arraySchema = arraySchema.nonempty({
            message: "Please select at least 1 option"
        });
    }

    if (field.validation?.rangeLimit?.enabled && field.validation.rangeLimit.min !== undefined) {
        arraySchema = arraySchema.refine(
            (arr) => arr.length === 0 || arr.length >= field.validation!.rangeLimit!.min!,
            {
                message: `Minimum ${field.validation.rangeLimit.min} selections required`
            }
        );
    }

    return z.object({
        [field.key]: arraySchema
    });
}