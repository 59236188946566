import React, { Fragment } from "react";
import _, { isObject } from "lodash";
import { useStore } from "../../components/Global/store/store";
import SendRequest from "../../components/SendRequest";
import { SendRequestResponseInterface } from "../../components/SendRequest/interface"
import AuthService from "../AuthService";
import findPath from "../../components/Helpers/findPath";

import { 
    CmsInterface 
} from "../../components/Global/interfaces"

import {
    crudKey,
    accessTypeView,
    accessTypeCreate,
} from "../../features/UserRoleManagement/utils/accessOptions";

import {
    getBinaryValueFromNumber,
    checkBinaryValueWithParents,
} from "../../features/UserRoleManagement/store/actions";

import {
    hasCreatableTabsInterface, 
    hasTabPermissionsInterface, 
    UserHasPermissionInterface, 
    CmsPropsInterface,
    UserHasViewAdminPermissionResponseInterface,
    UserHasViewAdminPermissionInterface,
    GetPermissionInterface,
    PermissionServiceInterface,
    HasPermissionBinaryInterface
} from "./interfaces"

import {
    UserInterface,
    globalStateInterface,
} from "../../components/Global/interfaces";

import {
    AccessTypeInterface,
} from "../../features/UserRoleManagement/interfaces";

export async function getPermissions(cmsData: CmsInterface): Promise<SendRequestResponseInterface> {
    const { apiUrls = {} } = cmsData;

    const { userManagementPermissions = "" } = apiUrls;

    const options = {
        method: "GET",
        url: userManagementPermissions,
        testingData: process.TESTING_DATA_FETCH_PERMISSIONS || [],
        corsHeaders: true,
    };

    const response = await SendRequest(options)
    
    return response
}

export default function PermissionService(
    props: PermissionServiceInterface,
): JSX.element | false {
    const {
        permission,
        permissions,
        policyPermission,
        children,
        legacy = true,
        useTabs,
        allow,
        useGreaterPermission,
        applyDisabledState = true,
    } = props;

    let state: any = {};
    if (useStore()) {
        [state] = useStore();
    }

    const cmsData: CmsInterface = state.cmsData || {};
    const userPermissions: Array<any> = state.userPermissions || [];
    const userBinaryPermissions: UserHasViewAdminPermissionResponseInterface =
        userHasViewAdminPermission({
            policyPermission,
            userPermissions,
            useGreaterPermission,
        });
    const legacyPermissions = hasPermissionWithLegacy({
        cmsData,
        permission,
        permissions,
        policyPermission,
        userPermissions,
    });
    const userRoleManagement: boolean = featureUserRoleManagement({ cmsData });

    if (isUserSuperAdmin(cmsData) || allow) {
        return <Fragment>{children}</Fragment>;
    }

    let tabsPermissions: boolean = false;
    if (useTabs) {
        tabsPermissions = hasTabPermissions({
            path: policyPermission.pathField,
            state,
            access: policyPermission.access,
        });
    }

    const isLegacyPermission = () => {
        let result = true;

        if (
            legacy &&
            _.isEmpty(legacyPermissions) &&
            typeof legacyPermissions !== "boolean"
        ) {
            result = false;
        }

        if (
            legacy &&
            typeof legacyPermissions == "boolean" &&
            !legacyPermissions
        ) {
            result = false;
        }

        return result;
    };

    // Does a legacy permission check
    const legacyPermission: boolean = isLegacyPermission();

    if (!legacyPermission && !tabsPermissions) {
        return false;
    }

    if (!legacy && _.isEmpty(userBinaryPermissions) && !tabsPermissions) {
        return false;
    }

    const getChildrenWithProps = () => {
        return React.Children.map(children, (child) => {
            let props: any = {};

            // disable section if no admin permissions
            if (
                _.isEmpty(userBinaryPermissions.admin) &&
                userRoleManagement &&
                !tabsPermissions &&
                applyDisabledState
            ) {
                props.disabled = true;
            }

            if (React.isValidElement(child)) {
                return React.cloneElement(child, props);
            }

            return child;
        });
    };

    const childrenwithProps = getChildrenWithProps();

    return <Fragment>{childrenwithProps}</Fragment>;
}

export function getCreatableRecordCategories(
    state: globalStateInterface,
    categories: Array<string>,
): Array<string> {
    const userPermissions: Array<any> = state.userPermissions;
    const cmsData: CmsInterface = state.cmsData || {};

    if (isUserSuperAdmin(cmsData) && !_.isEmpty(categories)) {
        return categories.map((item: any) => item.value);
    }

    let result: Array<string> = [];
    if (!_.isEmpty(userPermissions)) {
        userPermissions.forEach((permission: any) => {
            const categoriesPath = findPath(permission, "record.category");
            const categories = _.get(
                permission,
                `${categoriesPath}.record.category`,
            );

            if (!_.isEmpty(categories)) {
                Object.keys(categories).forEach((category: string) => {
                    if (
                        getBinaryValueFromNumber(
                            categories[category][crudKey],
                            accessTypeCreate.index,
                        )
                    ) {
                        result.push(category);
                    }
                });
            }
        });
    }

    return result;
}

export function hasTabPermissions({
    path,
    state,
    access,
}: hasTabPermissionsInterface): boolean {
    const userPermissions: Array<any> = state.userPermissions;
    const cmsData: CmsInterface = state.cmsData || {};
    let result: boolean = false;

    if (isUserSuperAdmin(cmsData)) {
        return true;
    }

    if (!_.isEmpty(userPermissions)) {
        userPermissions.forEach((permission: any) => {
            const tabspath: string = findPath(permission, path);

            const tabs: any = tabspath
                ? _.get(permission, `${tabspath}.${path}.tab`)
                : _.get(permission, `${path}.tab`);

            if (!_.isEmpty(tabs)) {
                Object.keys(tabs).forEach((tab: string) => {
                    if (
                        checkBinaryValueWithParents(tabs[tab][crudKey], access)
                    ) {
                        result = true;
                    }

                    if (!_.isEmpty(tabs[tab].section)) {
                        Object.keys(tabs[tab].section).forEach(
                            (section: string) => {
                                if (
                                    checkBinaryValueWithParents(
                                        tabs[tab].section[section][crudKey],
                                        access,
                                    )
                                ) {
                                    result = true;
                                }
                            },
                        );
                    }
                });
            }
        });
    }

    return result;
}

export function hasCreatableTabs({
    path,
    state,
}: hasCreatableTabsInterface): boolean {
    const userPermissions: Array<any> = state.userPermissions;
    const cmsData: CmsInterface = state.cmsData || {};
    let result: boolean = false;

    if (isUserSuperAdmin(cmsData)) {
        return true;
    }

    if (!_.isEmpty(userPermissions)) {
        userPermissions.forEach((permission: any) => {
            const tabs = _.get(permission, `${path}.tab`);

            if (!_.isEmpty(tabs)) {
                Object.keys(tabs).forEach((section: string) => {
                    if (
                        checkBinaryValueWithParents(
                            tabs[section][crudKey],
                            accessTypeCreate,
                        )
                    ) {
                        result = true;
                    }
                });
            }
        });
    }

    return result;
}

export function featureUserRoleManagement({ cmsData }: CmsPropsInterface) {
    const clientFeatures: any = cmsData?.clientFeatures || {};
    const userRoleManagement: boolean = clientFeatures?.userRoleManagement;

    return userRoleManagement;
}

export function hasPermissionWithLegacy({
    cmsData,
    permission,
    permissions = [],
    policyPermission,
    userPermissions,
}: GetPermissionInterface):
    | UserHasViewAdminPermissionResponseInterface
    | boolean {
    if (!featureUserRoleManagement({ cmsData }) && permission) {
        return AuthService.hasPermission(permission);
    }

    if (!featureUserRoleManagement({ cmsData }) && permissions) {
        const results = permissions.filter((item: string) => {
            return AuthService.hasPermission(item);
        });

        return !_.isEmpty(results);
    }

    return userHasViewAdminPermission({ policyPermission, userPermissions });
}

function userHasViewAdminPermission(
    props: UserHasViewAdminPermissionInterface,
): UserHasViewAdminPermissionResponseInterface {
    const {
        policyPermission,
        userPermissions,
        useGreaterPermission = true,
    } = props;

    if (_.isEmpty(policyPermission) || _.isEmpty(userPermissions)) {
        return {};
    }

    const pathField: string = policyPermission.pathField || "";
    const access: AccessTypeInterface =
        policyPermission.access || accessTypeView;

    let viewPermissions: Array<any> = [];
    let adminPermissions: Array<any> = [];

    userPermissions.forEach((item) => {
        const path = findPath(item, pathField);
        const binaryPath = path ? `${path}.${pathField}` : pathField;
        const userBinary = _.get(item, `${binaryPath}.${crudKey}`);

        if (hasPermission({ userBinary, access })) {
            viewPermissions.push(item);
        }

        if (
            useGreaterPermission &&
            hasGreaterPermission({ userBinary, access })
        ) {
            adminPermissions.push(item);
        }
    });

    let result: UserHasViewAdminPermissionResponseInterface = {};

    if (!_.isEmpty(viewPermissions)) {
        result.view = viewPermissions;
    }

    if (!_.isEmpty(adminPermissions)) {
        result.admin = adminPermissions;
    }

    return result;
}

export function userHasPermission({
    cmsData,
    userPermissions,
    pathField,
    access,
    prefix,
    legacyPermission,
    useHighestChildPermission = false,
}: UserHasPermissionInterface): boolean {
    let result: boolean = false;
    let selectedAccess: AccessTypeInterface = access || accessTypeView;
    const pathfieldWithPrefix: string = prefix ? `${prefix}.${pathField}` : pathField

    if (isUserSuperAdmin(cmsData)) {
        return true;
    }

    if (legacyPermission && !featureUserRoleManagement({ cmsData })) {
        return AuthService.hasPermission(legacyPermission);
    }

    if (!_.isEmpty(userPermissions)) {
        userPermissions.forEach((item) => {
            const path = findPath(item, pathfieldWithPrefix);
            const binaryPath = path ? `${path}.${pathfieldWithPrefix}` : pathfieldWithPrefix;

            let userBinary = _.get(item, `${binaryPath}.${crudKey}`);

            if (useHighestChildPermission) {
                userBinary = getHighestChildPermission(item, binaryPath);
            }

            if (hasPermission({ userBinary, access: selectedAccess })) {
                result = true;
            }
        });
    }

    return result;
}

export function userDoesntHavePermissions({
    cmsData,
    userPermissions,
    pathField,
    access,
}: UserHasPermissionInterface): boolean {
    const userRoleManagement = featureUserRoleManagement({ cmsData });
    const userAccessPermission = userHasPermission({
        cmsData,
        userPermissions,
        pathField,
        access,
    });
    return userRoleManagement && !userAccessPermission;
}

export function isUserSuperAdmin(cmsData: CmsInterface): boolean {
    if (_.isEmpty(cmsData)) {
        return false;
    }

    const user: UserInterface = cmsData.user || {};
    return user.userIsSuperAdmin;
}

export function hasPermission({
    userBinary,
    access,
}: HasPermissionBinaryInterface): boolean {
    if (!userBinary || !access.binary) {
        return false;
    }

    if (access.allowedBinaries) {
        return access.allowedBinaries.includes(userBinary)
    }

    return userBinary >= access.binary;
}

export function hasGreaterPermission({
    userBinary,
    access,
}: HasPermissionBinaryInterface): boolean {
    if (!userBinary || !access.binary) {
        return false;
    }

    return userBinary > access.binary;
}

function getHighestChildPermission(item: any, binaryPath: string): number {
    const parentPermission = _.get(item, binaryPath);

    let highestPermission = 0;

    if (isObject(parentPermission)) {
        Object.keys(parentPermission)?.forEach((key) => {
            if (parentPermission[key][crudKey] > highestPermission) {
                highestPermission = parentPermission[key][crudKey];
            }
        });
    }

    return highestPermission;
}