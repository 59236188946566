import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { StyledTableCell, StyledTableRow, StyledTableFooter } from "./styles";
import "./styles/_styles.scss";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";

export interface CustomBodyRowsInterface {
    className?: string;
    columns: Array<string> | Array<JSX.Element>;
    onRowClick: () => void;
}

export interface materialTabelInterface {
    headers: Array<any>;
    body: Array<any>;
    checkboxes?: boolean;
    placeholder?: string;
    alignRightIndexes?: Array<number>;
    alignContentCenterIndexes?: Array<number>;
    cellWidthIndexes?: Array<number>;
    cellWidth?: string;
    borders?: boolean;
    basicRowsStyles?: boolean;
    customBodyRows?: CustomBodyRowsInterface[];
    cursorRows?: boolean;
}

const label = {
    inputProps: {
        "aria-label": "table-checkbox",
    },
};

const MaterialTable = (props: materialTabelInterface) => {
    const {
        headers = [],
        body = [],
        checkboxes,
        placeholder,
        alignRightIndexes = [],
        alignContentCenterIndexes = [],
        cellWidthIndexes = [],
        cellWidth,
        borders,
        basicRowsStyles,
        cursorRows,
        customBodyRows = [],
    } = props;

    const getTableCellProps = (index: number) => {
        let headerProps: any = {};
        if (
            Array.isArray(alignRightIndexes) &&
            alignRightIndexes.includes(index)
        ) {
            headerProps.align = "right";
        }

        if (
            Array.isArray(alignContentCenterIndexes) &&
            alignContentCenterIndexes.includes(index)
        ) {
            headerProps.className = "align-center";
        }

        if (
            Array.isArray(cellWidthIndexes) &&
            cellWidthIndexes.includes(index) &&
            cellWidth
        ) {
            headerProps.style = {
                width: cellWidth,
            };
        }

        return headerProps;
    };

    const RenderCheckbox = () => {
        return (
            <StyledTableCell>
                <Checkbox {...label} />
            </StyledTableCell>
        );
    };

    return (
        <TableContainer component={Paper} className="material-table">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow className={borders ? "row-borders" : ""}>
                        {checkboxes && <RenderCheckbox />}

                        {headers.map((header: string, index: number) => {
                            return (
                                <StyledTableCell
                                    key={index}
                                    {...getTableCellProps(index)}
                                >
                                    {header}
                                </StyledTableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {body.map((row: any, index: number) => {
                        return (
                            <StyledTableRow
                                key={index}
                                className={borders ? "row-borders" : ""}
                            >
                                {checkboxes && <RenderCheckbox />}

                                {row.map(
                                    (
                                        item: string | JSX.Element,
                                        index: number,
                                    ) => {
                                        return (
                                            <StyledTableCell
                                                key={index}
                                                {...getTableCellProps(index)}
                                            >
                                                {item}
                                            </StyledTableCell>
                                        );
                                    },
                                )}
                            </StyledTableRow>
                        );
                    })}

                    {!_.isEmpty(customBodyRows) &&
                        customBodyRows.map(
                            (
                                bodyRow: CustomBodyRowsInterface,
                                index: number,
                            ) => {
                                const {
                                    onRowClick = () => {},
                                    className,
                                    columns = [],
                                } = bodyRow;

                                return (
                                    <StyledTableRow
                                        key={index}
                                        className={`
										${borders ? "row-borders" : ""} 
										${basicRowsStyles ? "basic-rows" : ""}
										${cursorRows ? "cursor-rows" : ""}
										${className || ""}
									`}
                                        onClick={() => onRowClick()}
                                    >
                                        {checkboxes && <RenderCheckbox />}

                                        {columns.map(
                                            (
                                                item: string | JSX.Element,
                                                index: number,
                                            ) => {
                                                return (
                                                    <StyledTableCell
                                                        key={index}
                                                        {...getTableCellProps(
                                                            index,
                                                        )}
                                                    >
                                                        {item}
                                                    </StyledTableCell>
                                                );
                                            },
                                        )}
                                    </StyledTableRow>
                                );
                            },
                        )}
                </TableBody>

                {(!body.length && placeholder) &&
                    <StyledTableFooter>
                        <StyledTableRow>
                            <StyledTableCell>{placeholder}</StyledTableCell>
                        </StyledTableRow>
                    </StyledTableFooter>
                }
            </Table>
        </TableContainer>
    );
};

export default MaterialTable;
