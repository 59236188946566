import {
    routeDiscovery,
    routeCollections,
    routeLocalisations,
    routeConnectCollections,
    routeReporting,
    routeAdvancedReporting,
    routeAdvancedReportingExplore,
    routeAdvancedReportingExploreTaskManager,
    routeAdvancedReportingExplore1,
    routeAdvancedReportingExplore2,
    routeAdvancedReportingExplore3,
    routeAdvancedReportingExplore4,
    routeAdvancedReportingExplore5,
    routeConnectManager,
    routeLineupManager,
    routeAccount,
    routePlatformManagement,
    routeUserRoleManagement,
    routeRecordBuilder,
    routePreferences,
    routeLogout,
    routeHelp,
    routeConnectManagement,
    routeTaskManager,
    routeRecordsSearch,
} from "../../../components/Global/routes";

export default function GetHref(props) {
    const {
        cmsData = {},
    } = props;

    const {
        apiUrls = {}
    } = cmsData

    const {
        knowledgeBaseAuthenticatedUrl = ''
    } = apiUrls

    let href = {
        recordsSearch: {
            href: routeRecordsSearch,
            link: true,
        },
        discovery: {
            href: routeDiscovery,
            link: true,
        },
        account: {
            href: routeAccount,
            link: true,
        },
        platformManagement: {
            href: routePlatformManagement,
            link: true,
        },
        collections: {
            href: routeCollections,
            link: true,
        },
        connectCollections: {
            href: routeConnectCollections,
            link: true,
        },
        connectManager: {
            href: routeConnectManager,
            link: true,
        },
        logout: {
            href: routeLogout,
        },
        reporting: {
            href: routeReporting,
            link: true,
        },
        localisations: {
            href: routeLocalisations,
            link: true,
        },
        help: {
            href: routeHelp,
            target: "_blank",
            external: true,
        },
        reportingLooker: {
            href: routeAdvancedReporting,
            link: true,
        },
        reportingExplore: {
            href: routeAdvancedReportingExplore,
            link: true,
        },
        reportingExploreTaskManager: {
            href: routeAdvancedReportingExploreTaskManager,
            link: true,
        },
        connectUsergroup: {
            href: routeConnectManagement,
            link: true,
        },
        lineupManager: {
            href: routeLineupManager,
            link: true,
        },
        userRoleManagement: {
            href: routeUserRoleManagement,
            link: true,
        },
        recordBuilder: {
            href: routeRecordBuilder,
            link: true,
        },

        // ready to delete
        fabricStudiosCatalogSnapshot: {
            href: routeAdvancedReportingExplore1,
            link: true,
        },
        titleDuplicateDashboard: {
            href: routeAdvancedReportingExplore2,
            link: true,
        },
        d2CDeliveryDashboard: {
            href: routeAdvancedReportingExplore3,
            link: true,
        },
        q3VODDeliveryDashboard: {
            href: routeAdvancedReportingExplore4,
            link: true,
        },
        localisationStatusDashboard: {
            href: routeAdvancedReportingExplore5,
            link: true,
        },
        preferences: {
            href: routePreferences,
            link: true,
        },
        taskManager: {
            href: routeTaskManager,
            link: true,
        },
        knowledge: {
            href: knowledgeBaseAuthenticatedUrl,
            target: "_blank",
            external: true,
        }
    };

    return href;
}
