import React from "react";
import { SectionInterface } from "./interface";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import IsActiveRecordBuilder from "../../../RecordBuilder/IsActive";
import DynamicSection from "../../views/DynamicSection";

const BuilderDynamicSection = (props: SectionInterface) => {
    const { cmsData = {}, data: recordData = {}, builderSection } = props;

    const { clientFeatures = {} } = cmsData;

    const data = recordData[builderSection.configName] || {}
    const handleFormChange = (editData) => {
        if (props.handleFormChange) {
            props.handleFormChange(builderSection.configName, {...data, ...editData})
        }
    }

    const dynamicSectionProps = {
        ...getSectionCommonProps({ ...props, field: builderSection.configName }),
        data,
        handleFormChange,
        name: builderSection.key,
        className: `meta-record-${builderSection.key}`,
        title: builderSection.displayName,
        category: recordData.category,
        builderSection
    };

    if (IsActiveRecordBuilder({ page: _.camelCase(builderSection.key), clientFeatures })) {
        return <DynamicSection {...dynamicSectionProps} />;
    }

    return (<></>);
};

export default BuilderDynamicSection;
