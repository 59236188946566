import React, { useEffect } from 'react'
import {  useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme/CoreForm.theme";
import { CoreForm, CoreFormProvider } from "../../Form/components/CoreForm";
import { CoreFormPaper } from "../components/layout/CoreForm.paper";
import CoreDynamicFormInterface from './interfaces/Form.interface'
import ValidationSchemaBuilder from './validation/SchemaBuilder'
import CoreDynamicForm from "./build";
import { z } from "zod";
import GenerateDefaultValues from './transformers/DefaultValues.transformer';
import { observer } from "mobx-react";
import { useStore as useGlobalStore } from "../../../components/Global/store/store";
import { useStores as useRootStore } from './store/rootStore';
import { isEmpty } from 'lodash';
import { TransformOutgoingData } from './transformers/OutgoingValues.transformer';
import { runJqueryFields } from "./utils/JqueryEffects";

const DynamicForm = observer((props: CoreDynamicFormInterface) => {
    const {
        fields,
        data = {},
        originalData = {},
        onChange,
        defaultValues = {},
        disabled,
        handleErrors = () => {},
        triggerEnabled,
        useNullValues = true,
        stackStyles,
        fieldJquery,
        injectValidationRules,
        useInitialValues,
        category = ""
    } = props

    const [globalState, ] = useGlobalStore() || [{}];
    const { listsStore, formStore } = useRootStore();

    const validationSchema = ValidationSchemaBuilder({ fields, extraRefine: injectValidationRules })
    type ValidationType = z.infer<typeof validationSchema>;

    const initialValues = GenerateDefaultValues({ 
        fields, 
        useNullValues,
        data: { ...defaultValues, ...data } 
    })

    const formMethods = useForm<ValidationType>({
        resolver: zodResolver(validationSchema),
        defaultValues: initialValues,
        mode: "onChange",
    });

    const { watch, formState, trigger } = formMethods

    useEffect(() => {
        if(!isEmpty(fields)) {
            listsStore.initialize(fields, globalState || {})
            formStore.initialize(fields, disabled, category)
        }

        return () => {
            listsStore.reset()
        }
    }, [fields, disabled])
    
    useEffect(() => {
        if (triggerEnabled) trigger()
    }, [fields, triggerEnabled, data])

    useEffect(() => {
        runJqueryFields(fieldJquery)
    }, [fieldJquery])

    useEffect(() => {
        const subscription = watch((data, field) => {
            onChange(
                TransformOutgoingData({
                    fields,
                    data,
                    originalData,
                    defaultData: initialValues,
                    lists: listsStore.lists,
					useNullValues,
                    useInitialValues
                }),
                field
            )

            if (triggerEnabled) {
                trigger()
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, formState]);

    useEffect(() => {
        handleErrors(formState.errors)       
    }, [formState])
    
    return (
        <ThemeProvider theme={theme}>
            <CoreFormProvider {...formMethods}>
                <CoreForm>
                    <CoreFormPaper maxWidth="100%">
                        <CoreDynamicForm 
                            fields={fields} 
                            stackStyles={stackStyles}
                            disabled={disabled}
                        />
                    </CoreFormPaper>
                </CoreForm>
            </CoreFormProvider>
        </ThemeProvider>
    );
})

export default DynamicForm