import { CreditInterface, CreditListInterface, CreditRankMap } from "../../credits.interface";

export default function copyCreditsToList(
    credits: CreditInterface[],
    sourceList: CreditListInterface,
    targetList: CreditListInterface
): CreditInterface[] {
    const copiableCredits = credits
        ?.filter((credit: CreditInterface) => {
            if (!credit.credit_lists?.length || credit.deleted) {
                return false
            }

            return credit.credit_lists?.some((creditRank: CreditRankMap) => {
                return creditRank.code === sourceList.code && !creditRank.deleted
            })
        })

    let copiedCredits = copiableCredits
        ?.map(credit => {
            let ranking = 0;
            for (let i = 0; i < credit.credit_lists?.length; i++) {
                if (credit.credit_lists[i].code === sourceList.code) {
                    ranking = credit.credit_lists[i].ranking as number;
                }
            }
            credit.credit_lists?.push({
                name: targetList.name,
                code: targetList.code,
                ranking: ranking
            })

            return credit
        })

    return copiedCredits.concat(
        credits?.filter((credit: CreditInterface) =>
            !credit.credit_lists.some(
                (creditList: CreditRankMap) => creditList.code === targetList.code
            )
        )
    )
}