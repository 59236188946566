import { Box, FormHelperText, MenuProps, Skeleton } from "@mui/material";
import { isEmpty, get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { CoreMenuButton } from "../../../../../Menu/CoreMenu.button";
import { CoreMenuStyled } from "../../../../../Menu/CoreMenu.styled";
import { useCoreMenu } from "../../../../../Menu/hooks/useCoreMenu";
import { useAutocomplete } from "../hooks/useAutocomplete";
import { CoreSelectOptionType } from "../types/CoreSelectOptionType";
import { CoreSelectList } from "./CoreSelect.list";
import { CoreSelectSearchBox } from "./CoreSelectSearchBox";
import { CoreSelectSelectAllCheckbox } from "./CoreSelectSelectAllCheckbox";
import { visibleOptionsCut } from '../utils/visibleLabelsCount'
import NoneElement from "../../NoneElement";
import { CoreFormFieldError } from "../../../styled/CoreFormFieldError";

// TODO: move to core
type CoreSelectMultipleMenuProps = {
    name: string;
    path: string;
    options: CoreSelectOptionType[];
    optionsFetched?: boolean;
    isSingleSelect?: boolean;
    hasSearch?: boolean;
    disabled?: boolean;
    noneSelected?: boolean
    anchorProps?: {
        anchorOrigin?: MenuProps["anchorOrigin"];
        transformOrigin?: MenuProps["transformOrigin"];
    };
    customStyles?: {
        menuButton?: any;
    };

    limit?: number;
    limitText?: string;
    showSelectAll?: boolean;
};

export const CoreSelectMultipleMenu = ({
    name,
    path,
    options,
    isSingleSelect,
    hasSearch,
    disabled,
    anchorProps,
    customStyles,
    limit,
    optionsFetched = true,
    noneSelected = false,
    limitText = "Limit Reached",
    showSelectAll = false,
}: CoreSelectMultipleMenuProps) => {
    const { anchorEl, open, handleClick, handleClose, inputSearchRef } =
        useCoreMenu();
    const autocomplete = useAutocomplete(options, inputSearchRef.current);

    const formMethods = useFormContext();
    const errorMessage = get(formMethods?.formState?.errors, path)?.message;

    const MENU_ID = `core-select-menu-${path}`;
    const MENU_BUTTON_ID = `core-select-menu-button-${path}`;

    let suggestions = options;

    if (hasSearch && autocomplete.searchedValue) {
        suggestions = autocomplete?.suggestions;
    }

    return (
        <Controller
            name={path}
            render={({ field: { onBlur, onChange, value: selected, ref } }) => {
                let label = [];
                let limitReached: boolean = false

                if (Array.isArray(selected) && selected.length && optionsFetched) {
                    const selectedOptions = options.filter(option => selected?.includes(option.value))
                    limitReached = limit && selected.length >= limit
                    
                    if (!isEmpty(selectedOptions)) {
                        let visibleOption = selectedOptions[0]
    
                        const visibleOptionLabel = visibleOption?.label
    
                        if (!isEmpty(visibleOptionLabel)) {
                            label = [
                                visibleOptionLabel,
                                ...selectedOptions.filter(option => option.label !== visibleOptionLabel),
                            ];
                        }
                    }
                }

                let selectElement = (
                    <CoreMenuButton
                        MENU_BUTTON_ID={MENU_BUTTON_ID}
                        open={open}
                        MENU_ID={MENU_ID}
                        name={name}
                        handleClick={handleClick}
                        selected={label}
                        disabled={disabled}
                        customStyles={customStyles?.menuButton}
                        error={!!errorMessage}
                    />
                )

                const menuButton = (
                    (noneSelected && disabled && isEmpty(selected)) 
                        ? <NoneElement text="None Selected"/> 
                        : selectElement
                )

                const skeleton = (
                    <Box id={MENU_BUTTON_ID} sx={{ marginTop: "0 !important"}}>
                        <Skeleton height={"2.5rem"}/>
                    </Box>
                )

                const mainElement = ( optionsFetched ? menuButton : skeleton )

                return (
                    <>
                        {mainElement}
                        {errorMessage && (
                                <CoreFormFieldError
                                    message={errorMessage}
                                    path={path}
                                />
                            )}

                        <CoreMenuStyled
                            id={MENU_ID}
                            MenuListProps={{
                                "aria-labelledby": MENU_BUTTON_ID,
                            }}
                            anchorEl={anchorEl}
                            // open
                            open={open}
                            onClose={handleClose}
                            {...anchorProps}
                            sx={
                                anchorProps
                                    ? {
                                          "& .MuiPaper-root": {
                                              width:
                                                  anchorEl &&
                                                  anchorEl.offsetWidth,
                                          },
                                      }
                                    : {
                                          "& .MuiPaper-root": {
                                              minWidth: 308,
                                          },
                                      }
                            }
                        >
                            {hasSearch && (
                                <CoreSelectSearchBox
                                    inputSearchRef={inputSearchRef}
                                    autocomplete={autocomplete}
                                />
                            )}
                            {showSelectAll && !isSingleSelect && 
                                <CoreSelectSelectAllCheckbox 
                                    options={options}
                                    selectedItems={selected}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    ref={ref}
                                    isSingleSelect={isSingleSelect}
                                />
                            }
                            <CoreSelectList
                                options={suggestions}
                                selectedItems={selected}
                                onChange={onChange}
                                onBlur={onBlur}
                                ref={ref}
                                isSingleSelect={isSingleSelect}
                                disabled={disabled || limitReached}
                            />
                            {limitReached && (
                                <FormHelperText
                                    error={true}
                                    sx={{
                                        padding:
                                            "0.3125rem 0.3125rem 0.3125rem 0.4375rem",
                                    }}
                                >
                                    {limitText}
                                </FormHelperText>
                            )}
                        </CoreMenuStyled>
                    </>
                );
            }}
        />
    );
};

CoreSelectMultipleMenu.defaultProps = {
    isSingleSelect: false,
    hasSearch: false,
};
