import _ from 'lodash'
import { v4 as uuidv4 } from "uuid";
import ListInterface from './lisInterface';

export const mapListsToDataManager = (lists: any[] = []): ListInterface[] => {
    let configuration = {
        isDynamic: true,
        fillable: [
            {
                name: 'text',
                label: 'Text',
                unique: true,
            }
        ],
        fillableCoreFormFields: [
            {
                dataType: 'String',
                displayName: 'Text',
                key: 'text',
                options: {
                    validation: {
                        required: {
                            enabled: true,
                        }
                    }
                }
            }
        ]
    }

    return lists.filter(list => !list.proxied).map(list => {
        let config = {...configuration}
        config.text = list.name
        config.value = list.code

        return config
    })
}

export const mapListItems = (items: any[]) => {
    if (!_.isArray(items)) {
        return []
    }

    return items?.map(item => {
        let result = {
            ...item.attributes,
            value: item.value,
            text: item.text,
            order: item.order || 1,
            label: item?.text || '',
        }

        return result
    })
}

export const mapListItemsForUpdate = (items: any[], itemsOriginal: any []) => {
    let updateData = {}

    items?.forEach((item, index) => {
        if (item.deleted && _.isEmpty(item.value)) return
        
        if (_.isEmpty(item.value)) {
            item.value = uuidv4();
        }

        if (item.deleted) {
            updateData[item.value] = null
            return
        }

        updateData[item.value] = {
            value: item.value,
            order: item.order || index + 1,
            text: item.text || ''
        }
    })

    itemsOriginal?.forEach(item => {
        if (!updateData[item.value]) {
            updateData[item.value] = null
        }
    })

    return updateData
}