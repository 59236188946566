import React from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AppsIcon from '@mui/icons-material/Apps';
import { viewTable, viewGrid } from "../index"

interface ViewSwitchIconsInterface {
    view: "table" | "grid",
    handleSetView: Function
}

const ViewSwitchIcons = (props: ViewSwitchIconsInterface) => {
    const {
        view,
        handleSetView = () => {}
    } = props

    let leftIconProps = {}
    if (view == viewTable) {
        leftIconProps = {
            color: "white"
        }
    }

    let rightIconProps = {}
    if (view == viewGrid) {
        rightIconProps = {
            color: "white"
        }
    }

    return (
        <ButtonGroup
            disableElevation
            size="small"
            aria-label="vertical outlined button group"
            className="view-switch-icons"
            variant="contained">

            <Button 
                size="small" 
                color={view == viewTable ? "primary" : "inherit"}
                variant="contained"
                style={{ padding: "0.3rem", minWidth: "initial" }}
                onClick={() => handleSetView(viewTable)}
                endIcon={
                    <FormatListBulletedIcon 
                        fontSize="small"
                        color="primary"
                        {...leftIconProps}
                    />
                }
                sx={{
                    "& .MuiButton-endIcon": {
                        margin: 0
                    },
                }}
            />

            <Button 
                size="small" 
                color={view == viewGrid ? "primary" : "inherit"}
                style={{ padding: "0.3rem", minWidth: "initial" }}
                variant="contained"
                onClick={() => handleSetView(viewGrid)}
                endIcon={
                    <AppsIcon 
                        fontSize="small"
                        color="primary"
                        {...rightIconProps}
                    />
                }
                sx={{
                    "& .MuiButton-endIcon": {
                        margin: 0
                    },
                }}
            />
        </ButtonGroup>
    )
}

export default ViewSwitchIcons