import {
    CreditInterface,
    CreditListInterface,
    CreditRankMap,
    DataTablePayload
} from "../../credits.interface";
import generateTableRows from "./generateTableRows";

export default function getActiveCredits(
    credits: CreditInterface[],
    activeCreditList: CreditListInterface,
    onContributorEdit: (data: DataTablePayload) => void,
    script: string,
    defaultScript: string,
): DataTablePayload[] {
    const activeCredits: CreditInterface[] =
        credits?.filter((credit: CreditInterface) => {
            if (!credit.credit_lists?.length || credit.deleted) {
                return false
            }

            return credit.credit_lists?.some((creditList: CreditRankMap) => {
                return creditList.code === activeCreditList.code && !creditList.deleted
            })
        })
            ?.map((credit: CreditInterface) => {
                const targetIndex: number = credit.credit_lists.findIndex(
                    (creditList: CreditRankMap) => creditList.code === activeCreditList.code
                )

                credit.ranking = credit.credit_lists[targetIndex].ranking as number

                return credit
            })
            ?.sort((a: CreditInterface, b: CreditInterface) => {
                return (a?.role_name as string || '').localeCompare(b?.role_name as string || '');
            });

    return generateTableRows(
        activeCredits,
        (row: DataTablePayload) => onContributorEdit(row),
        script,
        defaultScript
    )
}