import { isEmpty } from "lodash"

type Props = {
    category: string,
    field: any
}

export function isFieldEnabledForCategory({ category, field }: Props): boolean {
    if (field.archived) {
        return false
    }

    if (isEmpty(field?.categories)) {
        return true
    }

    return field?.categories?.includes(category)
}