import React, { PropsWithChildren, useState, forwardRef } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Fade, Grid, Skeleton, Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";
import { SxProps, styled } from "@mui/material/styles";
import _, { isEmpty } from "lodash";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../../../constants";
import { CoreFormLayout, CoreFormPath } from "../../../../types";
import {
    CoreFormLabel,
    CoreFormLabelProps,
} from "../../../styled/CoreForm.label";
import { CoreFormFieldError } from "../../../styled/CoreFormFieldError";
import { useAutocomplete } from "../hooks/useAutocomplete";
import { ChipsArray } from "./ChipsArray";
import {
    CoreSelectOptionType,
    PlaceholderOverflowTooltipType,
} from "../types/CoreSelectOptionType";
import { CoreSelectList } from "./CoreSelect.list";
import { CoreSelectSearchBox } from "./CoreSelectSearchBox";
import { CoreSelectSelectAllCheckbox } from "./CoreSelectSelectAllCheckbox";
import { CoreTooltip } from "../../../../../Tooltip/CoreTooltip";
import ConditionalWrapper from "../../../../../Wrapper/ConditionalWrapper";
import NoneElement from "../../NoneElement";
import { CoreFormCaption } from "../../../styled/CoreFormCaption";

export const CoreFormSelectPlaceholder = styled(Typography)(({ theme }) => ({
    color: "rgba(47, 47, 47, 0.57)",
}));

const StyledStack = styled(Stack)(({ theme }) => ({}));

StyledStack.defaultProps = {
    direction: "row",
    alignItems: "center",
    paddingRight: "1.25rem",
};

export type CoreSelectProps<T> = Omit<SelectProps, "multiple"> &
    PropsWithChildren & {
        config: CoreFormPath<T> &
            CoreFormLayout & {
                labelProps?: CoreFormLabelProps;
                showChipArray?: boolean;
                editSelectedChipsArray?: boolean;
                chipsLabel?: string;
                options: CoreSelectOptionType[];
                optionsFetched?: boolean;
                multiple?: SelectProps["multiple"];
                enableSearchbox?: boolean;
                noneSelected?: boolean;
                showSelectAll?: boolean;
                enableScrollToSelected?: boolean;
                enableNoneSelect?: boolean;
                placeholder?: {
                    noOptionsPlaceholder?: string;
                };
                autocompleteFilterFn?: (
                    value: string,
                ) => (itemData: CoreSelectOptionType) => boolean;
                disabled?: boolean;
                showOnlyLeftAdornment?: boolean;
                placeholderText?: string;
                placeholderOverflowTooltip?: PlaceholderOverflowTooltipType;
                forcedErrorMessage?: string;
                showRightAdornment?: boolean;
                tooltip?: string;
                caption?: string;
                testId?: string
            };
        customStyles?: SxProps;
        wrapperStyles?: SxProps;
        className?: string;
    };

export function CoreSelect<T>({
    children,
    config: {
        labelProps,
        path,
        showChipArray,
        chipsLabel,
        options,
        multiple,
        enableSearchbox,
        enableScrollToSelected,
        enableNoneSelect,
        placeholder,
        autocompleteFilterFn,
        optionsFetched = true,
        showSelectAll = false,
        editSelectedChipsArray = true,
        layout = "vertical",
        disabled = false,
        showOnlyLeftAdornment = false,
        noneSelected = false,
        placeholderText,
        placeholderOverflowTooltip,
        forcedErrorMessage,
        showRightAdornment = true,
        tooltip,
        caption,
        testId
    },
    customStyles,
    wrapperStyles,
    className,
    name,
    ...restProps
}: CoreSelectProps<T>) {
    const inputSearchRef = React.useRef<HTMLInputElement>(null);
    const autocomplete = useAutocomplete(
        options,
        inputSearchRef.current,
        autocompleteFilterFn,
    );

    const formMethods = useFormContext<T>();
    let errorMessage = _.get(formMethods.formState.errors, path)?.message;
    if (!isEmpty(forcedErrorMessage)) {
        errorMessage = forcedErrorMessage;
    }
    const error = !!errorMessage;

    const placeholderProps = placeholderOverflowTooltip
        ? {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: placeholderOverflowTooltip.maxWidth,
          }
        : null;

    const { replace } = useFieldArray({ name: path });
    const coreSelectTestId: string = `core-select-${testId || path}`

    return (
        <Controller
            name={path}
            disabled={disabled}
            defaultValue={CORE_FORM_STRING_DEFAULT_VALUE}
            render={({ field: { onBlur, onChange, value, ref } }) => {
                const selected: string | string[] = value;
                const [selectVisible, setSelectVisible] = useState(false);

                const onValueChanged = (value: string) => {
                    onChange(value);
                    if (!multiple) {
                        setSelectVisible(false);
                    }
                };

                const handleClose = () => {
                    setSelectVisible(false);
                };

                const handleOpen = () => {
                    if (enableSearchbox) {
                        autocomplete?.handleReset();
                    }

                    setSelectVisible(true);
                };

                const labelElement = <CoreFormLabel {...labelProps} />;

                const selectElement = (
                    <>
                        <CoreTooltip title={tooltip} placement="right">
                            <Select
                                aria-label="core-select"
                                className={className}
                                data-testid={coreSelectTestId}
                                open={selectVisible}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                disabled={disabled}
                                defaultValue={""}
                                renderValue={(optionSelected: string) => {
                                    const selectedOptionObject = options?.find(
                                        (option) => option.value === optionSelected,
                                    );

                                    const labelSelected = selectedOptionObject?.label;

                                    if (showOnlyLeftAdornment) {
                                        return (
                                            <StyledStack>
                                                {selectedOptionObject?.leftAdornment}
                                            </StyledStack>
                                        );
                                    }

                                    return (
                                        <StyledStack>
                                            {selectedOptionObject?.leftAdornment &&
                                                !selectedOptionObject?.isFixedFooterNewItemOption && (
                                                    <Box
                                                        display="flex"
                                                        paddingRight="0.5rem"
                                                    >
                                                        {
                                                            selectedOptionObject.leftAdornment
                                                        }
                                                    </Box>
                                                )}
                                            <ConditionalWrapper
                                                condition={
                                                    labelSelected &&
                                                    placeholderOverflowTooltip?.tooltipEnabled
                                                }
                                                wrapper={(children) => (
                                                    <CoreTooltip
                                                        title={labelSelected}
                                                        placement="bottom"
                                                        TransitionComponent={Fade}
                                                        TransitionProps={{
                                                            timeout: 600,
                                                        }}
                                                    >
                                                        {children}
                                                    </CoreTooltip>
                                                )}
                                            >
                                                <CoreFormSelectPlaceholder
                                                    sx={{
                                                        color: labelSelected
                                                            ? "rgba(47, 47, 47, 0.87)"
                                                            : "rgba(47, 47, 47, 0.57)",

                                                        ...placeholderProps,
                                                    }}
                                                >
                                                    {selectedOptionObject?.overrideLabel
                                                        ?? labelSelected
                                                        ?? placeholderText
                                                        ?? "Select an option"}
                                                </CoreFormSelectPlaceholder>
                                            </ConditionalWrapper>
                                            {selectedOptionObject?.rightAdornment && showRightAdornment && (
                                                <Box
                                                    display="flex"
                                                    paddingRight="0.5rem"
                                                    marginLeft="auto"
                                                >
                                                    {
                                                        selectedOptionObject.rightAdornment
                                                    }
                                                </Box>
                                            )}
                                        </StyledStack>
                                    );
                                }}
                                value={multiple ? "Select Values" : selected}
                                displayEmpty
                                size="small"
                                fullWidth
                                error={error}
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            boxShadow:
                                                "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                                            border: "0.0625rem solid #E6E5E5",
                                            padding: "0.3125rem",
                                            overflow: "scroll",
                                        },
                                    },
                                    MenuListProps: {
                                        sx: {
                                            maxHeight: 315,
                                            paddingY: 0,
                                            "& .MuiPaper-root + div": {
                                                overflow: "scroll",
                                                maxHeight: "17.5rem",
                                            },
                                        },
                                    },
                                }}
                                sx={customStyles}
                                {...restProps}
                            >
                                {enableSearchbox && (
                                    <CoreSelectSearchBox
                                        inputSearchRef={inputSearchRef}
                                        autocomplete={autocomplete}
                                    />
                                )}

                                {showSelectAll && multiple && (
                                    <CoreSelectSelectAllCheckbox
                                        options={options}
                                        selectedItems={selected}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                        isSingleSelect={!multiple}
                                    />
                                )}

                                <CoreSelectList
                                    options={
                                        (enableSearchbox &&
                                            !_.isEmpty(autocomplete?.suggestions) &&
                                            autocomplete?.suggestions) ||
                                        options
                                    }
                                    selectedItems={selected}
                                    onChange={onValueChanged}
                                    onBlur={onBlur}
                                    ref={ref}
                                    isSingleSelect={!multiple}
                                    enableScrollToSelected={enableScrollToSelected}
                                    enableNoneSelect={enableNoneSelect}
                                    showRightAdornment={showRightAdornment}
                                />
                            </Select>
                        </CoreTooltip>
                        
                        {caption && !errorMessage && (
                            <CoreFormCaption data-testid="core-select-caption">{caption}</CoreFormCaption>
                        )}
                    </>
                );

                const noneSelectedElement = (
                    <NoneElement
                        text="None Selected"
                        data-testid={coreSelectTestId}
                        customStyles={{
                            fontSize: "1rem;",
                            color: "rgba(47, 47, 47, 0.37);",
                            fontFamily: "'Roboto';",
                        }}
                    />
                );

                const inputElement = (
                    <>
                        {disabled && noneSelected && isEmpty(selected)
                            ? noneSelectedElement
                            : selectElement}
                    </>
                );

                const mainElement = optionsFetched ? (
                    inputElement
                ) : (
                    <Skeleton 
                        height={"2.5rem"} 
                        data-testid={coreSelectTestId}
                    />
                );

                const formElements =
                    layout === "horizontal" ? (
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                {labelProps && labelElement}
                            </Grid>
                            <Grid item xs={8}>
                                {selectElement}
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!showOnlyLeftAdornment && labelElement}
                            {mainElement}
                        </>
                    );

                return (
                    <FormControl
                        sx={{
                            ...wrapperStyles,
                            minWidth: !showOnlyLeftAdornment && 120,
                            width: showOnlyLeftAdornment ? "4.8rem" : "100%",
                        }}
                        size="small"
                    >
                        {formElements}

                        {errorMessage && (
                            <CoreFormFieldError
                                message={errorMessage}
                                path={path}
                            />
                        )}
                        {multiple && showChipArray && (
                            <ChipsArray
                                selected={selected}
                                options={options}
                                replace={replace}
                                formMethods={formMethods}
                                path={path}
                                chipsLabel={chipsLabel}
                                activeDelete={editSelectedChipsArray}
                            />
                        )}
                    </FormControl>
                );
            }}
        />
    );
}
