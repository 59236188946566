import filterCategoryBuilder from "../filterCategory";
import { get, indexOf, isEmpty } from "lodash"
import CoreFormFieldInterface, { 
    InputType as CoreFieldInputType, 
    Layer, ListSource, 
    DataType as CoreFieldDataType,
    Option as CoreOptionPayload
} from '../../../../core/Form/dynamic/interfaces/Field.interface'

import RecordBuilderField, { 
    InputType as RecordBuilderInputType, 
    BooleanInputType as RecordBuilderBooleanInputType, 
    SectionInterface,
    TileConfigInterface
} from '../../../../models/RecordBuilder/Field.interface'
import OriginationViewType from '../../../../features/Preferences/RecordBuilder/interfaces/OriginationView.type'

type Props = {
    configuration: RecordBuilderField[],
    category?: string,
    disabled?: boolean,
    superKeys?: string[],
    checkedKeys?: any,
    loading?: boolean,
    formState?: string
    builderSection?: SectionInterface
}

type DisabledFieldProps = {
    field: CoreFormFieldInterface,
    disabled: boolean,
    formState?: string,
    superKeys?: string[],
}

const defaultOptionView: CoreOptionPayload = { 
    dataType: CoreFieldDataType.Object, 
    textField: 'text', 
    valueField: 'value' 
}

const mapInputType = (field: RecordBuilderField): CoreFieldInputType => {
    let inputType = get(field, 'options.editable.inputType')

    switch(inputType) {
        // Core Components
        case RecordBuilderInputType.Boolean:
            let displayBoolType = get(field, 'options.editable.booleanDisplay.type', RecordBuilderBooleanInputType.RadioButton)

            switch(displayBoolType) {
                case RecordBuilderBooleanInputType.RadioButton:
                    return CoreFieldInputType.BooleanRadio
                case RecordBuilderBooleanInputType.Checkbox:
                    return CoreFieldInputType.BooleanCheckbox
                default:
                    return CoreFieldInputType.BooleanToggle
            }

        case RecordBuilderInputType.Select:
            return CoreFieldInputType.Select
        case RecordBuilderInputType.SelectFramed:
            return CoreFieldInputType.SelectFramed
        case RecordBuilderInputType.SelectMultiple:
            return CoreFieldInputType.SelectMultiple
        case RecordBuilderInputType.SelectMultipleChips:
            return CoreFieldInputType.SelectMultipleChips
        case RecordBuilderInputType.Autocomplete:
            return CoreFieldInputType.Autocomplete


        case RecordBuilderInputType.Date:
            return CoreFieldInputType.Date
        case RecordBuilderInputType.Time:
            return CoreFieldInputType.Time
        case RecordBuilderInputType.DateTime:
            return CoreFieldInputType.DateTime
            
        case RecordBuilderInputType.Year:
            return CoreFieldInputType.Year
        case RecordBuilderInputType.Duration:
            return CoreFieldInputType.Duration
        case RecordBuilderInputType.TextArea:
            return CoreFieldInputType.TextArea
        case RecordBuilderInputType.TextFramed:
            return CoreFieldInputType.TextFramed
        case RecordBuilderInputType.PhoneNumber:
            return CoreFieldInputType.PhoneNumber
        case RecordBuilderInputType.ColorPalette:
            return CoreFieldInputType.ColorPalette
        
        case RecordBuilderInputType.Alphanumeric:
            return CoreFieldInputType.Alphanumeric
        case RecordBuilderInputType.Numeric:
            return CoreFieldInputType.Numeric
            

        // Special Components
        case RecordBuilderInputType.AutocompleteContributors:
            return CoreFieldInputType.AutocompleteContributors

        default:
            return CoreFieldInputType.Text
    }
}

const mapLayer = (field: RecordBuilderField, tileConfig: TileConfigInterface[] = []): Layer => {
    if (field.view) {
        if (tileConfig) {
            const tile = tileConfig.find(tile => tile.key === field.view)

            if (tile) {
                return {
                    level: indexOf(tileConfig, tile) + 1,
                    columns: tile.columns
                }
            }
        }

        return {
            level: field.view == OriginationViewType.ThreeWay ? 1 : 2,
            columns: field.view == OriginationViewType.ThreeWay ? 3 : 1,
        }
    }

    return get(field, 'options.layer', {})
}

const getListSource = (field: RecordBuilderField): string | null => {
    const listSource: string | null = get(field, 'options.editable.optionsManagedList.listSource', null)

    if (listSource) {
        return listSource
    }

    return get(field, 'options.editable.optionsManagedList.dynamic', false) 
        ? ListSource.Dynamic 
        : ListSource.Legacy
}

const getDisabledField = ({ field, superKeys, disabled, formState }: DisabledFieldProps): boolean => {
    let result: boolean = disabled

    if (!isEmpty(superKeys) && !superKeys.includes(field.key) && disabled) {
        result = true
    }

    if (formState === 'edit' && get(field, 'options.editable.disabledOnEdit') && !disabled) {
        result = true
    }

    return result
}

const CoreDynamicFormFieldsMapper = ( props: Props ): CoreFormFieldInterface[] => {
    const {
        disabled,
        category,
        configuration = [],
        checkedKeys = {},
        superKeys = [],
        loading = false,
        formState = "",
        builderSection = {}
    } = props

    let coreFormFields: CoreFormFieldInterface[] = filterCategoryBuilder({ category, models: configuration })
        .filter(field => !field.archived)
        .map((field) => {
            const fieldValidation: any = get(field, 'options.validation', {})
            const fieldDisabled: boolean = getDisabledField({ field, superKeys, disabled, formState })
            
            return {
                loading,
                type: {
                    dataType: field.dataType,
                    input: mapInputType(field),
                    boolean: {
                        trueLabel: get(field, 'options.editable.booleanDisplay.trueLabel', 'Yes'),
                        falseLabel: get(field, 'options.editable.booleanDisplay.falseLabel', 'No'),
                    },
                    select: {
                        listSource: getListSource(field),
                        listKey: get(field, 'options.editable.optionsManagedList.key', null),
                        list: get(field, 'options.editable.optionsManagedList.list', []),
                        growing: get(field, 'options.editable.optionsManagedList.growing', false),
                        adornmentKey: get(field, 'options.editable.optionsManagedList.adornmentKey', null),
                        filters: get(field, 'options.editable.optionsManagedList.filters', []),
                        color: get(field, 'options.editable.optionsManagedList.color', null),
                        textColor: get(field, 'options.editable.optionsManagedList.textColor', null),
                        option: get(field, 'options.editable.optionsManagedList.option', defaultOptionView),
                        listTextField: get(field, 'options.editable.optionsManagedList.textField', 'text'),
                        listValueField: get(field, 'options.editable.optionsManagedList.valueField', 'value'),
                    },
                    datetTime: {
                        format: get(field, 'options.editable.dateTime.format', null)
                    }
                },
                label: field.displayName,
                labelMessage: field.label,
                key: field.key,
                order: field.order,
                groupName: field.groupName,
                layer: mapLayer(field, builderSection.tileConfig),
                icon: get(field, 'options.icon', {}),
                testingData: get(field, 'testingData', null),
                disabled: fieldDisabled,
                validation: {
                    ...fieldValidation,
                    readOnly: {
                        enabled: fieldDisabled 
                            ? fieldDisabled 
                            : fieldValidation?.readOnly?.enabled
                    }
                },
                checkedKeys: get(field, 'options.editable.optionsManagedList.adornmentKey', false)
                    ? checkedKeys[field.key] 
                    : null,
                characterCounter: {
                    enabled: get(field, 'options.editable.characterCounter.enabled', true)
                },
                tooltip: get(field, 'options.tooltip', ''),
                labelTooltipText: get(field, 'options.labelTooltipText', ''),
            }
        })

    return coreFormFields
}

export default CoreDynamicFormFieldsMapper