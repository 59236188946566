import { RoleInterface } from "../../../interfaces";

export default function getRolesTransformed(
    roles: RoleInterface[],
): RoleInterface[] {
    return roles?.map((role: RoleInterface) => {
        return {
            ...role,
            text: role.name,
            value: role.role,
        };
    });
}
