import { extendObservable, makeObservable, action } from "mobx";
import { isEmpty } from "lodash";
import { RootStore } from "./rootStore"

interface StoreInterface {
    validationMessages: string[];
}

const initialState: StoreInterface = {
    validationMessages: []
}

export class ValidationStore {
    rootStore: RootStore;
    validationMessages: string[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, initialState);

        makeObservable(this, {
            setValidationMessages: action,
        });
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }

    get isValidated(): boolean {
        let filled: boolean = true
        
        this.rootStore.optionsStore.requiredFieldKeys?.forEach((key: string) => {
            if (isEmpty(this.rootStore.ancillariesStore.allFormData[key])) {
                filled = false
            }
        })

        return filled
    }

    setValidationMessages(validationMessages: string[]): void {
        this.validationMessages = validationMessages
    }
}
