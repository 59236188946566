import { extendObservable, makeObservable, action } from "mobx";
import _ from "lodash";
import { RootStore } from "./rootStore"
import { PermissionInterface } from "../interfaces"
import { policyTypeDiscovery, policyTypeRecord } from "../../UserRoleManagement/utils/accessOptions"
import { userHasPermission } from "../../../services/PermissionService"; 
import { accessTypeStrictCreate, accessTypeStrictUpdate, accessTypeStrictDelete } from "../../UserRoleManagement/utils/accessOptions";

interface StoreInterface {
    userPermissions: any;
    permissions: PermissionInterface
}

const initialState: StoreInterface = {
    userPermissions: [],
    permissions: {
        create: false,
        update: false,
        delete: false
    }
}

export class PermissionsStore {
    rootStore: RootStore;

    userPermissions: any;
    permissions: PermissionInterface

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, initialState);

        makeObservable(this, {
            setUserPermissions: action,
            setPermissions: action,
            handleSetUserPermissions: action,
        });
    }

    initialize(userPermissions: any, policyType: string, pathField: string): void {
        this.setUserPermissions(userPermissions)
        this.handleSetUserPermissions(policyType, pathField)
    }

    setUserPermissions(userPermissions:  any): void {
        this.userPermissions = userPermissions
    }

    setPermissions(permissions: PermissionInterface): void {
        this.permissions = permissions
    }

    handleSetUserPermissions(policyType: string, pathField: string): void {
        const path: string = policyType == policyTypeDiscovery 
            ? `${policyTypeRecord}.category.${this.rootStore.category}.${pathField}`
            : pathField 

        const create = userHasPermission({
            cmsData: this.rootStore?.cmsData,
            userPermissions: this.userPermissions,
            pathField: path,
            access: accessTypeStrictCreate,
            legacyPermission: "discovery.edit"
        });

        const update = userHasPermission({
            cmsData: this.rootStore?.cmsData,
            userPermissions: this.userPermissions,
            pathField: path,
            access: accessTypeStrictUpdate,
            legacyPermission: "discovery.edit"
        });

        const deletePermission = userHasPermission({
            cmsData: this.rootStore?.cmsData,
            userPermissions: this.userPermissions,
            pathField: path,
            access: accessTypeStrictDelete,
            legacyPermission: "discovery.edit"
        });

        this.setPermissions({
            create,
            update,
            delete: deletePermission
        })
    }
}
