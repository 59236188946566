import React from "react"
import { isEmpty, get } from "lodash"
import Boilerplate from "./Boilerplate"
import { useStore as useGlobalStore } from '../../Global/store/store'
import { RECORD_BUILDER } from '../../Global/Options'
import { NUMBERING, ORIGINATION } from "../../../models/RecordBuilder"
import BuilderError from "../../RecordBuilder/FetchError";
import CoreFormFieldInterface from '../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import CoreDynamicForm from '../../../core/Form/dynamic'
import { SectionInterface as BuilderSectionInterface } from "../../../models/RecordBuilder/Field.interface"

type Props = {
    disabled: boolean
    handleFormChange: Function
    category: string
    data: any
    builderSection: BuilderSectionInterface
}

const DynamicSection = (props: Props) => {
    const {
        disabled,
        category,
        data,
        handleFormChange,
        builderSection
    } = props

    const [globalState] = useGlobalStore() || [{}];

    const handleChange = (section) => {
        handleFormChange(section);
    }

    let recordBuilderData = globalState[RECORD_BUILDER]
    let recordBuilderError = globalState.recordBuilderError
    
    let configuration = get(recordBuilderData, builderSection.configName, [])

    if (builderSection.configName === ORIGINATION) {
        configuration = configuration.filter((config: any) => config.section == builderSection.key)
    }

    let coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ disabled, category, configuration, builderSection })
    const isEmptyConfiguration = isEmpty(coreFormFields) && !recordBuilderError

    if (isEmptyConfiguration) {
        return <></>
    }

    return (
        <div className={`${builderSection.key}-builder`}>
            <Boilerplate
                title={builderSection.displayName}
                className={`meta-record-${builderSection.key}-builder`}
                displayTitle={true}
            >
                {recordBuilderError && <BuilderError />}

                <CoreDynamicForm 
                    fields={coreFormFields} 
                    data={data}
                    originalData={data}
                    disabled={disabled}
                    onChange={(value) => {
                        handleChange(value)
                    }}
                    stackStyles={{ paddingTop: "0.4rem" }}
                    handleErrors={(errors) => console.log("errors", errors)}
                    category={category}
                />
            </Boilerplate>
        </div>
    )
}

export default DynamicSection