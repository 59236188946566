import update from "react-addons-update";
import _ from "lodash";
import HandleChangeFunction from "./HandleChange"
import GetRecordData from "./GetRecordData";

const HandleRegionalTitleSwitchChange = (props) => {
    const { checked, title, key, state, handleChange, section, subSection } =
        props;

    const { locale, type } = title;

    const record = props.record 
        ? props.record 
        : GetRecordData({
            state,
        });

    let titles = record[section] || [];
    if (subSection) {
        titles = record[section][subSection] || [];
    }

    let updatedState = [...titles];

    // Finds title index
    const titleIndex = _.findIndex(titles, function (tl) {
        return tl.locale == locale && tl.type == type;
    });

    const UpdateTitleType = (data) => {
        if (titleIndex == -1) {
            return data;
        }

        return update(data, {
            [titleIndex]: {
                [key]: {
                    $set: checked,
                },
            },
        });
    };

    switch (key) {
        case "presentation":
            updatedState = titles.map((tl) => {
                if (tl && tl.locale == locale) {
                    delete tl[key];
                }

                return tl;
            });

            updatedState = UpdateTitleType(updatedState);

            break;

        default:
            // Remove display title tag from all titles
            let updatedTitles = titles?.map((tl) => {
                let item = tl;
                delete item[key];

                return item;
            });

            updatedState = updatedTitles;

            if (titleIndex !== -1 && checked) {
                updatedState = UpdateTitleType(updatedTitles);
            }
    }

    if (subSection) {
        let recordSection = record[section];
        switch (section) {
            case "additional_information":
                if (checked && !_.isEmpty(record[section])) {
                    Object.keys(record[section]).map(function (subKey, index) {
                        if (!_.isEmpty(record[section][subKey])) {
                            const item = record[section][subKey].map(
                                (recordPropertySubsection) => {
                                    if (_.has(recordPropertySubsection, key)) {
                                        recordPropertySubsection[key] = false;
                                    }
                                    return recordPropertySubsection;
                                },
                            );
                            recordSection[subKey] = item;
                        }
                    });
                }
                break;
            default:
                break;
        }
        updatedState = update(recordSection, {
            [subSection]: {
                $set: updatedState,
            },
        });
    }

    if (typeof handleChange == "function") {
        handleChange({
            key: section,
            data: updatedState,
        });

        return;
    }

    HandleChangeFunction({
        key: section,
        data: updatedState,
        state,
    });
};

export default HandleRegionalTitleSwitchChange;
