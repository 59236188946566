import React, { useMemo } from "react";
import { Autocomplete, AutocompleteProps, Box, Paper } from "@mui/material";
import { get, isEmpty } from "lodash";
import { useFormContext } from "react-hook-form";
import ObservableTextInputWithChip from "../../features/TaskManager/components/ObservableTextInputWithChip";
import { AutocompleteRecordCardTvShowEmpty } from "../../features/TaskManager/containers/record/tv-series/components/AutocompleteRecordCard";
import { CoreInputText } from "../Form/components/inputs/CoreInput.text";
import {
    CoreFormLabel,
    CoreFormLabelProps,
} from "../Form/components/styled/CoreForm.label";

// TODO: fix types
export const CoreAutocomplete = (
    props: AutocompleteProps & {
        isEmptySuggestionsArray?: boolean;
        labelProps: CoreFormLabelProps;
        textInputStyles?: React.CSSProperties;
    },
) => {
    const path = props?.path;
    const formMethods = useFormContext();
    const value = formMethods.watch(path);
    const errorMessage = get(formMethods.formState.errors, path)?.message || get(formMethods.formState.errors, props?.arrayPath)?.message;

    const autocompleteProps = useMemo(() => {
        let result = { ...props };

        delete result.arrayPath;
        delete result.isEmptyMaxLength;
        delete result.labelProps;
        delete result.textInputStyles;

        return result
    }, [props]);

    return (
        <Box width="100%">
            {props?.isEmptyMax ? (
                <CoreFormLabel {...props?.labelProps} />
            ) : null}

            <Autocomplete
                autoComplete
                forcePopupIcon={false}
                autoHighlight
                disableClearable
                disabled={props?.disabled}
                value={value || []}
                renderInput={(params) => {
                    if (props?.isEmptyMaxLength) {
                        return (
                            <CoreInputText
                                path={path || ''}
                                placeholder={props?.placeholder}
                                labelProps={props?.labelProps}
                                error={errorMessage}
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                }}
                                sx={{ 
                                    "& .MuiInputBase-root": {
                                        padding: "0",
                                    },
                                    ...props.textInputStyles || {},
                                    p: 0
                                }}
                            />
                        );
                    }

                    return (
                        <ObservableTextInputWithChip
                            sx={{ p: 0 }}
                            error={!!errorMessage}
                            {...params}
                            path={path || ''}
                            placeholder={props?.placeholder}
                            labelProps={props?.labelProps}
                            maxLength={props?.maxLength}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                    );
                }}
                getOptionLabel={() => {
                    return value || ''
                }}
                PaperComponent={(paperProps) => (
                    <Paper
                        sx={{
                            boxShadow:
                                "0.125rem 0.1875rem 0.3125rem rgba(79, 94, 116, 0.15)",
                            "& .MuiAutocomplete-listbox": {
                                padding: "0.625rem",
                                border: "0.0625rem solid #E6E5E5",
                                borderRadius: "0.25rem",
                            },
                            "& .MuiAutocomplete-groupUl": {
                                padding: "0.625rem",
                            },
                            "& .MuiListSubheader-root": {
                                background: " #fff",
                                margin: 0,
                                padding: "0 10px",
                                lineHeight: "2rem",
                                top: 0,
                            },
                        }}
                        {...paperProps}
                    />
                )}
                sx={{
                    "& .MuiInputBase-root": {
                        padding: 0,
                    },
                }}
                {...autocompleteProps}
            />

            {props?.isEmptySuggestionsArray &&
                !isEmpty(value) &&
                !props?.disableEmptyCard && (
                    <AutocompleteRecordCardTvShowEmpty
                        infoMessage={props?.notFoundMessage}
                        term={value}
                    />
                )}
        </Box>
    );
};
