export function recordServiceV2Headers(user: any = {}) {
    const {
        user_token = ""
    } = user

    return {
        headers: {
            'token': process.env.USER_TOKEN_OVERRIDE || user_token,
            'Source': 'Fabric'
        }
    }
}