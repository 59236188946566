import { extendObservable, makeObservable, action } from "mobx";
import _ from "lodash";
import { RootStore } from "./rootStore"
import CoreFormFieldInterface from '../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../../components/RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import { getRecordBuilderFields, ancillaryRecordBuilderFields } from "../config/fields"

interface StoreInterface {
    coreFormFields: CoreFormFieldInterface[];
}

const initialState: StoreInterface = {
    coreFormFields: [],
}

export class OptionsStore {
    rootStore: RootStore;
    coreFormFields: CoreFormFieldInterface[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, initialState);

        makeObservable(this, {
            setCoreFormFields: action,

            handleSetCoreFormFields: action
        });
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }

    get requiredFieldKeys(): string[] {
        return this.coreFormFields
            .filter((field) => field.validation?.required?.enabled)
            .map((field) => field.key)
    }

    setCoreFormFields(coreFormFields: CoreFormFieldInterface[]): void {
        this.coreFormFields = coreFormFields;
    }

    handleSetCoreFormFields(type: string, create: boolean): void {
        const dateFormat = this.rootStore.cmsData?.clientDataStructure?.date_format

        const items = ancillaryRecordBuilderFields[type] || [];
        const fields = getRecordBuilderFields(dateFormat, create)
            ?.filter((field) => items.includes(field.filterKey || field.key))

        const coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ 
            category: '', 
            configuration: fields || []
        })

        this.setCoreFormFields(coreFormFields)
    }
}
