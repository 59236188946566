import { GridRenderCellParams } from "@mui/x-data-grid";
import { CoreTableGridColDef } from "../../types";
import Tag from "../../../../components/Tag";

export const TypeCol: CoreTableGridColDef = {
    field: "type",
    headerName: "Type",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string>) => {
        const { row } = params;

        if (!row.type) {
            return <></>
        }
        
        return (
            <Tag
                text={row.type}
                styles={{
                    fontSize: "0.78125rem",
                    fontWeight: 500,
                    padding: "0.25rem 0.3125rem",
                }}
            />
        )
    },
};
