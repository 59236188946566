import React, { ReactElement, useState } from "react";
import _ from "lodash";
import ReactSVG from "react-svg";
import Select from "@mui/material/Select";
import { envTest } from "../../Environment";
import Settings from "../../Settings";
import { FormHelperText, ListItemIcon, Skeleton } from "@mui/material";
import {
    ValidationResultInterface,
    ValidationOptionsInterface,
} from "./interfaces";
import MenuItem from "./MenuItem";
import "./styles/_styles.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface SelectV2Props {
    disabledProp: boolean;
    wrapperStyles: object;
    dataList: {}[] | string[];
    data?: {}[] | string[];
    addNewText?: string;
    defaultValue?: string;
    forceValue?: string | string[];
    setSelectedValue: Function;
    handleAddNew?: Function;
    handleEdit?: Function;
    handleDelete?: Function;
    className?: string;
    addNew?: boolean;
    selectType?: string;
    multiple?: boolean;
    headerText?: string;
    headerIcon?: string;
    renderValue?: (value: any) => ReactElement | string;
    validationOptions?: ValidationOptionsInterface;
    menuStyles?: {};
    displayEmpty?: boolean;
    enableTooltip?: boolean;
    openOnHover?: boolean;
    hideIcon?: boolean;
    actions?: boolean;
    disableClick?: boolean;
    loading?: boolean;
    rightItem?: any;
    enableDelete?: boolean;
    enableUpdate?: boolean;
    dataTestId?: string,
    renderRightItem?: boolean | ((value: any) => boolean);
    iconComponent?: React.FC<IconComponentProps>;
    showLoadingIcon?: boolean
}

interface IconComponentProps {
    className: string;
}

const SelectV2: React.FC<SelectV2Props> = (props) => {
    const {
        disabledProp = false,
        wrapperStyles = {},
        dataList = [],
        data,
        addNewText = "",
        defaultValue = "",
        forceValue = "",
        setSelectedValue = () => { },
        handleAddNew = () => { },
        handleEdit = () => { },
        handleDelete = () => { },
        renderValue,
        addNew = false,
        headerText,
        headerIcon,
        selectType = "single",
        multiple = false,
        className = "",
        menuStyles = {},
        validationOptions = {},
        displayEmpty = false,
        hideIcon = false,
        actions = false,
        enableTooltip = false,
        openOnHover = false,
        disableClick = false,
        loading = false,
        showLoadingIcon = false,
        rightItem = false,
        renderRightItem = false,
        enableDelete = true,
        enableUpdate = true,
        dataTestId = "",
        iconComponent = () => <KeyboardArrowDownIcon className="select-arrow" />
    } = props;

    const { validationCheck, requirementText } = validationOptions;

    const [selectedOption, setSelectedOption] = useState<any>(data || null);
    const [menuStatus, setMenuStatus] = useState<boolean>(false);
    const [validation, setValidation] = useState<ValidationResultInterface>({
        status: false,
    });

    const icon = (loading && showLoadingIcon)
        ? () => <img src={`${Settings.images.loadingDotsGrey}`} className="loading-dots" />
        : iconComponent;
    
    const handleOpenMenu = () => {
        setMenuStatus(true);

        if (validation.status) {
            setValidation({ status: false });
        }
    };

    const handleOpenMenuWithHover = _.debounce(() => {
        handleOpenMenu();
    }, 700);

    const handleCloseMenu = () => {
        handleOpenMenuWithHover.cancel();

        if (menuStatus) {
            setMenuStatus(false);
        }
    };

    const handleOnChange = (event: any) => {
        if (!disableClick) {
            if (validationCheck && validationCheck(event.target.value)) {
                return setValidation({
                    status: true,
                    text: requirementText || "Error in selection",
                });
            } else {
                setValidation({ status: false });
            }

            setSelectedValue(event.target.value);
            setSelectedOption(event.target.value);
        }
    };

    const handleRenderValue = (value: any) => {
        if (typeof renderValue === "function") {
            return renderValue(value);
        }

        if (typeof value === "object") {
            return value.text;
        }

        return value;
    };

    const MenuProps = {
        disablePortal: true,
        PaperProps: {
            onMouseLeave: () => openOnHover && handleCloseMenu(),
            style: {
                background: "#FFFFFF",
                borderRadius: "0.25rem",
                border: "0.0625rem solid",
                marginTop: "7px",
                padding: "0.3125rem",
                borderColor: validation.status ? "#E92751" : "rgba(77, 77, 77, 0.10)",
                boxShadow: "0.125rem 0.1875rem 0.3125rem 0 rgba(79, 94, 116, 0.15)",
                ...menuStyles,
            },
        },
        MenuListProps: {
            style: {
                padding: 0
            }
        },
    };

    const dataListToUse = disabledProp && data ? data : dataList;

    return (
        <div
            className={
                !disabledProp
                    ? `meta-select-mui active ${className}`
                    : `meta-select-mui ${className}`
            }
            style={wrapperStyles}
        >
            <Select
                value={forceValue ? forceValue : selectedOption || defaultValue}
                onChange={(event) => handleOnChange(event)}
                multiple={multiple}
                open={menuStatus}
                onClose={() => handleCloseMenu()}
                onOpen={() => handleOpenMenu()}
                onMouseEnter={() => {
                    if (openOnHover) {
                        handleOpenMenuWithHover();
                    }
                }}
                onMouseLeave={() => {
                    if (openOnHover) {
                        handleCloseMenu();
                    }
                }}
                MenuProps={MenuProps}
                IconComponent={!hideIcon ? icon : ""}
                renderValue={(value) => handleRenderValue(value)}
                displayEmpty={displayEmpty}
                className={`credit-list-select ${className}`}
                data-testid={dataTestId}
            >
                {headerText && (
                    <div className="meta-menu-header">
                        <ListItemIcon>
                            <img src={headerIcon} />
                            <div>{headerText}</div>
                        </ListItemIcon>
                    </div>
                )}

                {loading && (
                    <div>
                        <Skeleton animation="wave" />
                        <Skeleton 
                            animation="wave" 
                            sx={{
                                margin: "0.3125rem 0;"
                            }}
                        />
                        <Skeleton animation="wave" />
                    </div>
                )}

                {!loading &&
                    dataListToUse.map((item: any, index) => (
                        <MenuItem
                            item={item}
                            value={item.value || item.id || item}
                            index={index}
                            disableClick={disableClick}
                            selectType={selectType}
                            disabled={disabledProp}
                            data={data}
                            enableTooltip={enableTooltip}
                            actions={actions}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            key={index}
                            rightItem={rightItem}
                            enableDelete={enableDelete}
                            enableUpdate={enableUpdate}
                            renderRightItem={_.isFunction(renderRightItem) ? renderRightItem(item) : renderRightItem}
                        />
                    ))}

                {!loading && _.isEmpty(dataListToUse) && (
                    <FormHelperText className="meta-select-error">
                        {"No Options Available"}
                    </FormHelperText>
                )}

                {!disabledProp && addNew && (
                    <li 
                        className="add-new" 
                        data-testid="selectv2-add-new">
                            
                        <div className="meta-select-options--add-new">
                            <div
                                onClick={() => {
                                    handleCloseMenu();
                                    handleAddNew();
                                }}
                                role="button"
                            >
                                {!envTest && (
                                    <ReactSVG
                                        path={`${Settings.images.path}/svg/add-credit-black.svg`}
                                    />
                                )}

                                <span className="text">
                                    {addNewText || "Add New..."}
                                </span>
                            </div>
                        </div>
                    </li>
                )}

                {validation.status && (
                    <FormHelperText className="meta-select-error">
                        {validation.text}
                    </FormHelperText>
                )}
            </Select>
        </div>
    );
};

export default SelectV2;
