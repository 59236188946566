/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { GridRowParams } from "@mui/x-data-grid";
import Settings from "../../../components/Settings";
import { StyledIconButton } from "./StyledIconButton";

export type HandleOnClickType = {
    handleOnClick: (params: GridRowParams) => void;
    disabled?: () => boolean | boolean;
};

export type HandleOnClickTypeWithEvent = {
    handleOnClick: (event: React.SyntheticEvent, params: GridRowParams) => void;
};

interface OnlyShowInDefaultStateInterface {
    [key: string]: boolean;
}

export type RenderActionsColProps = {
    edit?: HandleOnClickType;
    delete?: HandleOnClickType;
    add?: HandleOnClickType;
    download?: HandleOnClickTypeWithEvent;
    preview?: HandleOnClickType;
    onlyShowInDefaultState?: OnlyShowInDefaultStateInterface;
    state?: "edit" | "default";
    width?: number;
};

interface IconInterface {
    actionProps: RenderActionsColProps;
    params: GridRowParams;
}

const RenderDownloadIcon = ({ actionProps, params }: IconInterface) => {
    if (actionProps.download) {
        return (
            <StyledIconButton
                aria-label={`download-row-${params.id}`}
                onClick={(event: React.SyntheticEvent) =>
                    actionProps?.download?.handleOnClick(event, params)
                }
            >
                <img
                    src={`${Settings.images.path}/svg/download-grey.svg`}
                    alt="Download"
                    className="table-download-icon"
                />
            </StyledIconButton>
        );
    }

    return null;
};

const RenderPreviewIcon = ({ actionProps, params }: IconInterface) => {
    if (actionProps.preview) {
        const noPreview = actionProps?.preview?.disabled(params);

        return (
            <StyledIconButton
                aria-label={`preview-row-${params.id}`}
                style={{ opacity: noPreview ? 0.5 : 1 }}
                onClick={() =>
                    !noPreview
                        ? actionProps?.preview?.handleOnClick(params)
                        : () => {}
                }
            >
                <img
                    src={`${Settings.images.path}/svg/preview-grey.svg`}
                    alt="Preview"
                    className="table-preview-icon"
                />
            </StyledIconButton>
        );
    }

    return null;
};

export const RenderEditIcon = ({ actionProps, params }: IconInterface) => {
    if (actionProps.edit) {
        return (
            <StyledIconButton
                aria-label={`edit-row-${params.id}`}
                style={{ opacity: actionProps.edit?.disabled ? 0.5 : 1 }}
                onClick={() =>
                    !actionProps.edit?.disabled
                        ? actionProps?.edit?.handleOnClick(params)
                        : () => {}
                }
            >
                <img
                    src={`${Settings.images.path}/svg/edit-icon.svg`}
                    alt="Edit"
                    className="table-edit-icon"
                />
            </StyledIconButton>
        );
    }

    return null;
};

export const RenderDeleteIcon = ({ actionProps, params }: IconInterface) => {
    if (actionProps?.delete) {
        return (
            <StyledIconButton
                aria-label={`delete-row-${params.id}`}
                style={{ opacity: actionProps.delete?.disabled ? 0.5 : 1 }}
                onClick={() =>
                    !actionProps.delete?.disabled
                        ? actionProps?.delete?.handleOnClick(params)
                        : () => {}
                }
            >
                <img
                    src={`${Settings.images.path}/svg/trash-icon.svg`}
                    alt="Delete"
                    className="table-delete-icon"
                />
            </StyledIconButton>
        );
    }

    return null;
};

export const RenderAddIcon = ({ actionProps, params }: IconInterface) => {
    if (actionProps.add) {
        return (
            <StyledIconButton
                aria-label={`add-row-${params.id}`}
                style={{ opacity: actionProps.add?.disabled ? 0.5 : 1 }}
                onClick={() =>
                    !actionProps.add?.disabled
                        ? actionProps?.add?.handleOnClick(params)
                        : () => {}
                }
            >
                <img
                    src={`${Settings.images.path}/svg/add-icon.svg`}
                    alt="Add"
                    className="table-add-icon"
                />
            </StyledIconButton>
        );
    }

    return null;
};

export const RenderActionsCol = (props: RenderActionsColProps) => {
    let width = 100;
    // FIXME: do this better to support multiple action layouts
    // for the current use cases it's fine
    if (props.width !== undefined) {
        width = props.width;
    } else if (
        (props?.edit && !props?.delete) ||
        (!props?.edit && props.delete) ||
        (props?.edit && props?.delete && props?.delete.disabled)
    ) {
        width = 60;
    }

    if (props.onlyShowInDefaultState && props.state == "default") {
        return [
            {
                field: "actions",
                type: "actions",
                width: props.onlyShowInDefaultState?.width || 100,
                getActions: (params: GridRowParams) => {
                    const itemProps = {
                        actionProps: props,
                        params,
                    };

                    const downloadButton = props.onlyShowInDefaultState
                        ?.download ? (
                        <RenderDownloadIcon {...itemProps} />
                    ) : (
                        <div />
                    );

                    const previewButton = props.onlyShowInDefaultState
                        ?.preview ? (
                        <RenderPreviewIcon {...itemProps} />
                    ) : (
                        <div />
                    );

                    return [downloadButton, previewButton];
                },
            },
        ];
    }

    return [
        {
            onlyShowInEditState: true,
            field: "actions",
            type: "actions",
            width: props.width || width,
            getActions: (params: GridRowParams) => {
                const itemProps = {
                    actionProps: props,
                    params,
                };

                const editButton = <RenderEditIcon {...itemProps} />;
                const deleteButton = <RenderDeleteIcon {...itemProps} />;
                const addButton = <RenderAddIcon {...itemProps} />;

                const downloadButton = <RenderDownloadIcon {...itemProps} />;
                const previewButton = <RenderPreviewIcon {...itemProps} />;

                return [
                    editButton,
                    addButton,
                    deleteButton,
                    downloadButton,
                    previewButton,
                ];
            },
        },
    ];
};
