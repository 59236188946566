import React, { Component } from "react";
import styles from "../styles";
import { disabledSection } from "../config";

export default class Boilerplate extends Component {
    constructor(props) {
        super(props);

        /**
         * @var object
         */
        this.styles = styles(this.props);
    }

    render() {
        const {
            disabled,
            className,
            noBorder,
            firstSection,
            lastSection,
            joint,
            boilerplateStyles,
            title,
            titleStyles,
            titleRightContent,
            children,
            displayTitle = true,
        } = this.props;

        return (
            <div
                className={`${className || "meta-record-section"} ${
                    disabled ? disabledSection : ""
                }`}
                style={Object.assign(
                    {},
                    styles().section.default,
                    noBorder && this.styles.section.noBorder,
                    firstSection && this.styles.section.first,
                    lastSection && this.styles.section.last,
                    joint && this.styles.section.joint,
                    boilerplateStyles && boilerplateStyles,
                )}
            >
                {displayTitle && title && (
                    <div
                        className="container-flex"
                        style={Object.assign({}, titleStyles || {})}
                    >
                        <h2 style={this.styles.section.title}>{title}</h2>

                        {titleRightContent && titleRightContent}
                    </div>
                )}

                {children}
            </div>
        );
    }
}
