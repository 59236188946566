import { isEmpty } from "lodash"
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { ItemDataInterface as MultiLevelViewInterface } from "../../../components/RecordBuilder/views/MultiLevelExpandable/interfaces"
import { fields, ancillaryCategoryFields, typeCategory, typeSubCategory, typeAncillary } from "../config/fields"
import { FieldInterface, PermissionInterface } from "../interfaces"

function getAncillariesFields(item: AncillariesInterface): FieldInterface[] {
    const type: string = transformType(item?.type)
    const itemFields: Array<string> = ancillaryCategoryFields[type]

    if (itemFields) {
        return itemFields.map((fieldKey: string) => {
            const field: FieldInterface | {} = fields.find((it: any) => it.key == fieldKey) || {}

            return {
                ...field,
                name: field.name?.replace('{type}', type),
                value: item[fieldKey]
            }
        })
    }

    return []
}

function transformType(type: string): string {
    return type?.replace('_', '-')
}

export default function multiLevelViewTransformer(ancillaries: AncillariesInterface[], handleCreateNewClick: Function, handleUpdateClick: Function, handleDeleteClick: Function, permissions: PermissionInterface): MultiLevelViewInterface[] {
    if (isEmpty(ancillaries) || !Array.isArray(ancillaries)) {
        return []
    }

    const createPermission = {
        disabled: !permissions.create
    }

    const updatePermission = {
        disabled: !permissions.update
    }
    
    const deletePermission = {
        disabled: !permissions.delete
    }

    return ancillaries
        ?.filter((ancillary: AncillariesInterface) => !ancillary.deleted)
        ?.map((ancillary: AncillariesInterface) => {
            return {
                uuid: ancillary.uuid,
                name: ancillary.name,
                tags: [transformType(ancillary.type)],
                fields: getAncillariesFields(ancillary),
                options: [
                    {
                        label: ancillary.name,
                        items: [
                            {
                                text: `Edit ${ancillary.name}`,
                                onClick: () => handleUpdateClick(typeAncillary, {
                                    ancillaryUuuid: ancillary.uuid
                                }),
                                ...updatePermission
                            },
                            {
                                text: `Delete ${ancillary.name}`,
                                onClick: () => handleDeleteClick(typeAncillary, {
                                    ancillaryUuuid: ancillary.uuid
                                }),
                                ...deletePermission
                            },
                        ]
                    },
                    {
                        label: 'Category',
                        items: [
                            {
                                text: 'Add Category',
                                onClick: () => handleCreateNewClick(typeCategory, {
                                    ancillaryUuuid: ancillary.uuid
                                }),
                                ...createPermission
                            }
                        ]
                    }
                ],
                children: ancillary.children
                    ?.filter((category: AncillariesInterface) => !category.deleted)
                    ?.map((category: AncillariesInterface) => {
                        return {
                            uuid: category.uuid,
                            name: category.name,
                            tags: [transformType(category.type)],
                            fields: getAncillariesFields(category),
                            options: [
                                {
                                    label: category.name,
                                    items: [
                                        {
                                            text: `Edit ${category.name}`,
                                            onClick: () => handleUpdateClick(typeCategory, {
                                                ancillaryUuuid: ancillary.uuid,
                                                categoryUuuid: category.uuid
                                            }),
                                            ...updatePermission
                                        },
                                        {
                                            text: `Delete ${category.name}`,
                                            onClick: () => handleDeleteClick(typeCategory, {
                                                ancillaryUuuid: ancillary.uuid,
                                                categoryUuuid: category.uuid
                                            }),
                                            ...deletePermission
                                        },
                                    ]
                                },
                                {
                                    label: category.name,
                                    items: [
                                        {
                                            text: 'Add Sub Category',
                                            onClick: () => handleCreateNewClick(typeSubCategory, {
                                                ancillaryUuuid: ancillary.uuid,
                                                categoryUuuid: category.uuid
                                            }),
                                            ...createPermission
                                        }
                                    ]
                                }
                            ],
                            children: category.children
                                ?.filter((subCategory: AncillariesInterface) => !subCategory.deleted)
                                ?.map((subCategory: AncillariesInterface) => {
                                    return {
                                        uuid: subCategory.uuid,
                                        name: subCategory.name,
                                        tags: [transformType(subCategory.type)],
                                        fields: getAncillariesFields(subCategory),
                                        options: [
                                            {
                                                label: subCategory.name,
                                                items: [
                                                    {
                                                        text: `Edit ${subCategory.name}`,
                                                        onClick: () => handleUpdateClick(typeSubCategory, {
                                                            ancillaryUuuid: ancillary.uuid,
                                                            categoryUuuid: category.uuid,
                                                            subCategoryUuuid: subCategory.uuid
                                                        }),
                                                        ...updatePermission
                                                    },
                                                    {
                                                        text: `Delete ${subCategory.name}`,
                                                        onClick: () => handleDeleteClick(typeSubCategory, {
                                                            ancillaryUuuid: ancillary.uuid,
                                                            categoryUuuid: category.uuid,
                                                            subCategoryUuuid: subCategory.uuid
                                                        }),
                                                        ...deletePermission
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                })
                        }
                })
            }
    })
}