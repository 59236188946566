import { extendObservable, makeObservable, action } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import update from "react-addons-update";
import { RootStore } from "./rootStore"
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { ParentOptions } from "../interfaces"
import { typeCategory, typeSubCategory } from "../config/fields"
import { typeAncillary } from "../config/fields";

const initialState = {
    type: null
}

export class AncillariesCreateStore {
    rootStore: RootStore;

    type: string | null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, {
            type: null
        });

        makeObservable(this, {
            reset: action,

            setType: action,
            handleCreateNewClick: action,
            handleAddNewAncillaryType: action,
            handleAddNewAncillary: action,
            handleAddNewCategory: action,
            handleAddNewSubCategory: action,
        });
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }

    get createNewItem(): any {
        return {
            type: this.type,
            children: [],
            uuid: this.rootStore.ancillariesStore.formData.uuid || uuidv4(),
            ...this.rootStore.ancillariesStore.formData
        }
    }

    setType(type: string | null) {
        this.type = type
    }

    handleCreateNewClick(type: string, parentOptions: ParentOptions = null): void {
        this.setType(type)

        this.rootStore.optionsStore.handleSetCoreFormFields(type, true)
        this.rootStore.ancillariesStore.setParentOptions(parentOptions)
    }

    handleAddNewAncillaryType(): void {
        switch (this.type) {
            case typeAncillary:
                this.handleAddNewAncillary()
                break;

            case typeCategory:
                this.handleAddNewCategory()
                break;

            case typeSubCategory:
                this.handleAddNewSubCategory()
                break;

            default:
                break;
        }

        this.rootStore.handleResetForm()
    }

    handleAddNewAncillary(): void {
        const data = [...this.rootStore.ancillariesStore.data, this.createNewItem]

        this.rootStore.ancillariesStore.handleDataChange(data)
    }

    handleAddNewCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        if (ancillaryIndex !== -1) {
            const hasChildren: boolean = Array.isArray(
                this.rootStore.ancillariesStore.data[ancillaryIndex]?.children
            )

            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        $apply: (children: AncillariesInterface[]) => (hasChildren 
                            ? [...children, this.createNewItem] 
                            : [this.createNewItem]
                        )
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }

    handleAddNewSubCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(
            ancillaryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.categoryUuuid
        )

        if (ancillaryIndex !== -1 && categoryIndex !== -1) {
            const hasChildren: boolean = Array.isArray(
                this.rootStore.ancillariesStore.data[ancillaryIndex]?.children[categoryIndex]?.children
            )

            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        [categoryIndex]: {
                            children: {
                                $apply: (children: AncillariesInterface[]) => (hasChildren 
                                    ? [...children, this.createNewItem] 
                                    : [this.createNewItem]
                                )
                            }
                        }
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }
}