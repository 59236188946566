import React from 'react'
import { isEmpty } from 'lodash'
import { makeObservable, action, extendObservable } from 'mobx'
import { RootStore } from '../rootStore'
import { creditFormCreateStoreInitialState } from "../state"
import { CreditFormStore } from "./creditFormStore"
import { configurationCreate, apiCreateFields } from "../../config/creditForm.config"

export class CreditFormCreateStore extends CreditFormStore {
    constructor(rootStore: RootStore) {
        super(rootStore)

        makeObservable(this, {
            handleSetConfiguration: action,
            handleSubmit: action,
            handleSetCredit: action
        });

        extendObservable(this, creditFormCreateStoreInitialState);
    }

    initialize() {
        this.reset()
        this.handleSetDefaultLocale()
        this.handleSetConfiguration()
        this.handleSetCoreFormFields()

        this.handleSetCredit()
        this.handleSetOriginalData()
    }
    
    handleSetConfiguration(): void {
        this.setConfiguration(configurationCreate)
    }

    handleSetCredit(): void {
        this.setCredit(this.getDefaultCreditValues())
    }

    handleSubmit(): void {
        this.rootStore.creditFormValidationStore.handleValidationCreate(this.configuration, this.credit, this.creditOriginal)

        if (isEmpty(this.rootStore.creditFormValidationStore.errorAlerts)) {
            const transformedCredit = this.rootStore.handleTransformItem(apiCreateFields, this.credit)
            
            this.rootStore.creditsStore.handleAddCredit(transformedCredit)
            this.rootStore.creditListsStore.handleAddCreditToCreditList(transformedCredit)
            
            this.rootStore.activeCreditsStore.handleSetCredits()
            this.rootStore.optionsStore.handleSetLanguageOptionsWithLocales()

            this.rootStore.handleChange()
            this.rootStore.setShowCreditCreateForm(false)
        }
    }
}
