import "./sass/_main.scss";
import React from "react";
import ReactDOM from "react-dom";
import "babel-polyfill";
import { createMuiTheme } from "@material-ui/core/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import ErrorBoundary from "./js/components/ErrorBoundary";
import FlashMessage from "./js/components/FlashMessage";
import LoadScript from "./js/components/LoadScript";
import Script from "./js/components/Script";
import { StoreProvider } from "./js/components/Global/store/store";
import {
    initialState,
    globalPageReducer,
} from "./js/components/Global/store/reducer";
import initialiseStore from "./js/store";
import AuthService from "./js/services/AuthService";
import { createAppRoutes } from './routes';

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY);
AuthService.init("#nav");

const store = initialiseStore();
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
});
const { Routes } = createAppRoutes({
    theme,
    store,
});

if (typeof window !== "undefined" && document && document.body) {
    if (document.body.contains(document.getElementById("meta-app"))) {
        ReactDOM.render(
            <ErrorBoundary>
                <StoreProvider
                    initialState={initialState}
                    reducer={globalPageReducer}
                >
                    <Routes/>
                </StoreProvider>
                <FlashMessage />
                <LoadScript />

                {/* Dynatrace */}
                {process.env.DYNATRACE_ENABLED === "true" &&
                    <Script 
                        crossOrigin="anonymous"
                        src="https://js-cdn.dynatrace.com/jstag/178598305ee/bf10476kjz/384276fdcf3ad711_complete.js"   
                    />
                }
            </ErrorBoundary>,
            document.getElementById("meta-app"),
        );
    }
}