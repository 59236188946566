import { extendObservable, makeObservable, action } from "mobx";
import { findIndex, isEmpty } from "lodash";
import { RootStore } from "./rootStore"
import { ItemDataInterface as MultiLevelViewInterface } from "../../../components/RecordBuilder/views/MultiLevelExpandable/interfaces"
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { AncillariesInitializeInterface, ParentOptions } from "../interfaces"
import { ancillariesStoreInitialState } from "./initialState"
import { typeNames } from "../config/fields"
import multiLevelViewTransformer from "../utils/multiLevelViewTransformer"
import dataTransformer from "../utils/dataTransformer"

export class AncillariesStore {
    rootStore: RootStore;
    
    handleFormChange: Function;
    data: AncillariesInterface[];
    formData: AncillariesInterface | {};
    defaultFormData: AncillariesInterface | {};
    multiLevelViewData: MultiLevelViewInterface[];
    parentOptions: ParentOptions | null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, ancillariesStoreInitialState);

        makeObservable(this, {
            initialize: action,

            setFormData: action,
            setDefaultFormData: action,
            setParentOptions: action,

            handleChange: action,
        });
    }

    reset() {
        Object.keys(ancillariesStoreInitialState).forEach(
            (key) => (this[key] = ancillariesStoreInitialState[key]),
        );
    }

    initialize({
        handleFormChange,
        data
    }: AncillariesInitializeInterface): void {
        this.handleFormChange = handleFormChange || (() => {});

        this.setData(
            dataTransformer(data)
        )

        this.setMultiLevelViewData()
    }

    get typeTitle(): string {
        return typeNames[this.rootStore.ancillariesCreateStore.type || this.rootStore.ancillariesUpdateStore.type] || ""
    }

    get allFormData(): any {
        return {
            ...this.defaultFormData,
            ...this.formData
        }
    }

    get isEmptyData(): boolean {
        return isEmpty(this.data)
    }

    getAncillaryIndex(uuid: string): number {
        return findIndex(this.data, (it) => it.uuid === uuid)
    }

    getCategoryIndex(ancillaryIndex: number, uuid: string): number {
        if (ancillaryIndex == -1) {
            return -1
        }

        return findIndex(this.data[ancillaryIndex]?.children, (obj) => obj?.uuid === uuid)
    }

    getSubCategoryIndex(ancillaryIndex: number, categoryIndex: number, uuid: string): number {
        if (ancillaryIndex == -1 || categoryIndex == -1) {
            return -1
        }

        return findIndex(this.data[ancillaryIndex]?.children[categoryIndex]?.children, (obj) => obj.uuid === uuid)
    }

    setData(data: AncillariesInterface[]): void {
        this.data = data
    }

    setMultiLevelViewData(): void {
        this.multiLevelViewData = multiLevelViewTransformer(
            this.data, 
            this.rootStore.ancillariesCreateStore.handleCreateNewClick.bind(this.rootStore.ancillariesCreateStore),
            this.rootStore.ancillariesUpdateStore.handleEditClick.bind(this.rootStore.ancillariesUpdateStore),
            this.rootStore.ancillariesUpdateStore.handleDeleteClick.bind(this.rootStore.ancillariesUpdateStore),
            this.rootStore.permissionsStore.permissions
        )
    }

    setFormData(formData: AncillariesInterface | {}): void {
        this.formData = formData
    }

    setDefaultFormData(defaultFormData: AncillariesInterface | {}): void {
        this.defaultFormData = defaultFormData
    }

    setParentOptions(parentOptions: ParentOptions | null): void {
        this.parentOptions = parentOptions
    }

    handleChange(values: any): void {
        this.setFormData({
            ...this.formData,
            ...values
        })
    }

    handleDataChange(data: AncillariesInterface[]): void {
        this.setData(data)
        this.setMultiLevelViewData()

        this.handleFormChange(this.data)
    }
}
