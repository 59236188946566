import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useStore as globalStore } from "../../../components/Global/store/store";
import { useStores } from "../store/rootStore";
import { InitializeContainerInterface } from "../interfaces"

const InitializeContainer = observer((props: InitializeContainerInterface) => {
    const { rootStore, ancillariesStore } = useStores() || {};
    const [globalState] = globalStore() || [{}]

    const { 
        disabled, 
        data,
        handleFormChange = () => {} ,
        children,
        policyType,
        pathField,
        category
    } = props;

    useEffect(() => {
        rootStore.initialize({
            globalState,
            policyType,
            pathField,
            category
        });

        ancillariesStore.initialize({
            data,
            handleFormChange
        });
    }, []);

    useEffect(() => {
        rootStore.setDisabled(disabled);
    }, [disabled]);

    return (
        <>
            {children}
        </>
    )
});

export default InitializeContainer;
