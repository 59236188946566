import React from 'react';
import { Box, Skeleton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
    DataGridPro,
    GridSlotsComponent,
    GridSlotsComponentsProps,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_REORDER_COL_DEF,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import {
    CorePagination,
    CorePaginationProps,
} from "../Pagination/CorePagination";
import { theme } from "../theme/CoreTable.theme";
import { CustomColumnMenu } from "./components/CustomColumnMenu";
import { CustomReorderButton } from "./components/CustomReorderButton";
import { Footer } from "./components/Footer";
import { NoRowsOverlay } from "./components/NoRowsOverlay";
import { StyledTableActions } from "./components/StyledTableActions";
import {
    COLOR_COLUMN_HEADER_ACTIVE,
    COLOR_COLUMN_HEADER_INACTIVE,
    LOADING_ROW_COUNT,
} from "./constants";
import "./styles/_core.scss";
import { CoreTableGridColDef, CoreTableProps } from "./types";

export const CORE_TABLE_CELL_ERROR_CLASSNAME = "core-table-cell-error";
export const CORE_TABLE_CELL_ERROR_BACKGROUND_COLOR = "rgba(214, 70, 94, 0.1)";

/**
 * - It's a wrapper over the MUI DataGridPro
 * - It supports row type thorugh Generic TRow type
 * - Contains the shared/common features for tables within the app. One such feature is to enable/disable row alternating colors.
 * - It will support multiple visual variants thought 'theme' property
 * TODO: documentation and tests through StoryBook
 */

export function CoreTable<TRow extends { id: number | string }>({
    config,
    columns,
    sxExtra = {},
    styles = {},
    shouldControlHeight = true,
    ...restProps
}: CoreTableProps<TRow>) {
    const {
        variant = "default",
        searchBar,
        pagination,
        state = "default",
        exportCSV,
        emptyFallback,
        leftFooterText,
        tableName,
        resetCounter,
        loading = false,
        loadingRowCount = LOADING_ROW_COUNT,
        disableColumnMenu = true,
        isCellClickable = true,
    } = config;

    const apiRef = useRef({});

    // FIXME: move this logic outside
    const [savedState, setSavedState] = useState<{
        count: number;
        initialState?: GridInitialStatePro;
    }>({ count: 0 });

    const handleReset = () => {
        if (savedState?.initialState) {
            apiRef.current.restoreState(savedState.initialState);
        }
    };

    // console.log('hideFooter', hideFooter)

    useEffect(() => {
        handleReset();
    }, [resetCounter]);

    useEffect(() => {
        setSavedState((prev) => ({
            ...prev,
            count: prev.count + 1,
            initialState: apiRef.current.exportState(),
        }));
    }, []);

    const isEditState = state === "edit";
    const isDefaultState = state === "default";
    const isPaginationEnabled = !_.isEmpty(pagination);
    const isUsingRowReordering = columns?.some(
        (col: CoreTableGridColDef) => col?.field === GRID_REORDER_COL_DEF?.field,
    );
    const isUsingSelection = columns?.some(
        (col: CoreTableGridColDef) =>
            col.field === GRID_CHECKBOX_SELECTION_COL_DEF.field,
    );

    let preprocessedColumns = useMemo(() => [...columns], [columns]);

    // TODO: implement theming
    let columnHeaderBG = COLOR_COLUMN_HEADER_ACTIVE;
    if (variant === "default") {
        columnHeaderBG = COLOR_COLUMN_HEADER_ACTIVE;
    } else if (variant === "inactive") {
        columnHeaderBG = COLOR_COLUMN_HEADER_INACTIVE;
    }

    // TODO: move to a new file
    let ToolbarElem = (
        <GridToolbarContainer>
            {searchBar && <GridToolbarQuickFilter />}
            {exportCSV && isEditState && (
                <StyledTableActions>
                    <GridToolbarExport />
                </StyledTableActions>
            )}
        </GridToolbarContainer>
    );

    if (!searchBar || !exportCSV) {
        ToolbarElem = <div />;
    }

    if (isDefaultState) {
        preprocessedColumns = columns?.filter(
            (col: CoreTableGridColDef) =>
                col.onlyShowInEditState == null || !col.onlyShowInEditState,
        );
    }

    let paginationProps: CorePaginationProps;
    if (isPaginationEnabled) {
        paginationProps = {
            count: Math.ceil(pagination.totalRowCount / pagination.pageSize),
            showFirstButton: true,
            showLastButton: true,
            shape: "rounded",
            onChange: config?.pagination?.handlePageChange,
            page: pagination?.currentPage,
        };
    }

    function CustomDragOverlay() {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                width="100%"
                height="100%"
                bgcolor="rgba(255, 0, 0, 0.5)"
                zIndex={1000}
            >
                <span>Custom drag overlay</span>
            </Box>
        );
    }

    function LoadingSkeleton() {
        return (
            <Box
                sx={{
                    height: "max-content",
                }}
            >
                {[...Array(loadingRowCount)].map((_, index) => (
                    <Box
                        key={index}
                        sx={{
                            borderBottom: "1px solid #E0E0E0",
                            height: "2.25rem",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 0.5rem",
                            "&:last-child": {
                                border: 0,
                            },
                        }}
                    >
                        <Skeleton sx={{ margin: "0.5rem 0" }} />
                    </Box>
                ))}
            </Box>
        );
    }

    const components: Partial<GridSlotsComponent> | undefined = {
        Pagination: CorePagination,
        Toolbar: () => ToolbarElem,
        NoRowsOverlay,
        Footer: isPaginationEnabled ? undefined : Footer, // Can't have both pagination and footer
        ColumnMenu: CustomColumnMenu,
        RowReorderIcon: CustomReorderButton,
        rowDragOverlay: CustomDragOverlay,
        LoadingOverlay: LoadingSkeleton,
    };

    const componentsProps: GridSlotsComponentsProps | undefined = {
        pagination: paginationProps,
        toolbar: {
            showQuickFilter: true,
            quickFilterProps: true,
        },
        noRowsOverlay: {
            message: emptyFallback,
        },
        footer: {
            message: leftFooterText,
            showTotal: false,
        },
    };

    const viewRowBgStyle = {
        ".MuiDataGrid-row:nth-of-type(even)": {},
    };
    if (!isEditState) {
        viewRowBgStyle[".MuiDataGrid-row:nth-of-type(even)"] = {
            backgroundColor: "#F5F5F5",
        };
    }

    const rowHeight = 2.25; // 36px

    const tableDynamicHeight = restProps?.rows?.length
        ? rowHeight * restProps?.rows?.length + 2.25
        : loading
        ? rowHeight * loadingRowCount
        : 6.75;
        
    const scrollerHeight = restProps.rows.length
        ? rowHeight * restProps?.rows?.length + 2.25
        : loading
        ? rowHeight * loadingRowCount
        : 6.75;

    let heightControlStyles: any = {}
    if (shouldControlHeight) {
        heightControlStyles = {
            ".MuiDataGrid-main": {
                height: `${tableDynamicHeight}rem !important`
            },
            ".MuiDataGrid-virtualScrollerContent": {
                height: `${scrollerHeight}rem !important`
            },
            ".MuiDataGrid-virtualScroller": {
                height: `${scrollerHeight}rem !important`,
                zIndex: 2
            },
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <DataGridPro
                apiRef={apiRef}
                columns={preprocessedColumns}
                key={tableName + savedState.count}
                density="compact"
                pagination={isPaginationEnabled}
                components={components}
                disableSelectionOnClick
                disableColumnMenu={disableColumnMenu}
                checkboxSelection={isEditState && isUsingSelection}
                rowReordering={isEditState && isUsingRowReordering}
                componentsProps={componentsProps}
                headerHeight={52}
                autoHeight
                loading={loading}
                sortingOrder={["asc", "desc"]}
                sx={{
                    ".MuiDataGrid-row": {
                        ":hover": {
                            backgroundColor: !isEditState
                                ? "transparent"
                                : "#F5F5F5",
                        },
                        "&:last-child": {
                            border: "0",
                        },
                        ...styles?.rowStyles || {},
                    },
                    ...viewRowBgStyle,
                    ".MuiDataGrid-columnHeaders": {
                        backgroundColor: columnHeaderBG,
                    },
                    ".MuiDataGrid-cell": {
                        borderBottom: `1px solid ${
                            !isEditState ? "transparent" : "#E0E0E0"
                        }`,
                        cursor:
                            isEditState && isUsingSelection && isCellClickable
                                ? "pointer"
                                : "default",
                    ...styles?.cellStyles || {},
                    },
                    ".MuiTooltip-tooltip": {
                        backgroundColor: "#3B4462",
                        color: "#FFFFFF",
                        "& .MuiTooltip-arrow": {
                            color: "#3B4462",
                        },
                    },
                    ".MuiDataGrid-cellContent": {
                        color: "rgba(149, 149, 149, 1)",
                    },
                    ".MuiDataGrid-checkboxInput": {
                        padding: "0.5625em !important",
                    },
                    ".MuiDataGrid-pinnedColumns--right .MuiDataGrid-cell[data-field=actions]":
                        {
                            paddingX: "0.625rem",
                        },
                    ...heightControlStyles,
                    ...sxExtra,
                }}
                {...restProps}
            />
        </ThemeProvider>
    );
}
