import React from "react";

import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SetGlobalOptions from "../js/components/Global/SetGlobalOptions";

import {
    TYPE_DISCOVERY,
    TYPE_COLLECTIONS,
    TYPE_LOCALISATIONS,
    TYPE_CONNECT_COLLECTIONS,
    TYPE_REPORTING,
    TYPE_REPORTING_LOOKER,
    TYPE_REPORTING_EXPLORE,
    TYPE_REPORTING_EXPLORE_TASK_MANAGER,
    TYPE_CATALOG_SNAPSHORT,
    TYPE_TITLE_DUPLICATE_DASHBOARD,
    TYPE_D2C_DELIVERY_DASHBOARD,
    TYPE_QVOD_DELIVERY_DASHBOARD,
    TYPE_LOCALISATION_STATUS_DASHBOARD,
    TYPE_CONNECT_MANAGER,
    TYPE_LINEUP_MANAGER,
    TYPE_ACCOUNT,
    TYPE_PLATFORM_MANAGEMENT,
    TYPE_PREFERENCES,
    TYPE_USER_ROLE_MANAGEMENT,
    TYPE_RECORD_BUILDER,
    TYPE_TASK_MANAGER,
    TYPE_RECORDS_SEARCH,
} from "../js/pages/config";

import PageSuspense from "../js/components/PageSuspense";
import CategoryTags from "../js/components/CategoryTags";

import CollectionsContextState from "../js/context/Collections/ContextState";
import ConnectManagerContextState from "../js/context/ConnectManager/ContextState";
import LineupManagerContextState from "../js/context/LineupManager/ContextState";

import Boilerplate from "../js/containers/Boilerplate";
import PageLoading from "../js/components/PageLoading";
import PermissionService from "../js/services/PermissionService";
import PreFrameworkBoilerplate from "../js/containers/PreFrameworkBoilerplate";
import LoadingTaskManagerScreen from "../js/features/TaskManager/views/LoadingTaskManagerScreen";

import {
    TITLE,
    REPORT,
    LOCALISATION,
    CONNECT,
    COLLECTIONS,
} from "../js/pages/Collections/src/CollectionType";

import {
    CMS_DATA,
    OPTIONS,
    CLIENT_MANAGED_OPTIONS_VERSIONS,
    USER_ROLES,
    USER_PERMISSIONS,
    ACCESS_CATALOGS,
    ACCESS_POLICY_CATALOGS,
    CLIENT_MANAGED_OPTIONS_LANGUAGES,
    RECORD_BUILDER,
    CLIENT_MANAGED_LISTS,
} from "../js/components/Global/Options";
import {
    policyTypeRecord,
    policyTypeCollections,
    policyTypeExports,
    policyTypeLocalisations,
    policyTypePlatformManagement,
    policyTypeReporting,
    policyTypeSwimlanes,
    policyTypeLineups,
    policyTypeUserGroups,
    policyTypeUserRolesAndPolicies,
    policyTypeRecordBuilder,
    policyTypeTaskManager,
} from "../js/features/UserRoleManagement/utils/accessOptions";

import {
    preLoginRoutes,
    generalRoutes,
    managementRoutes,
    routeLogin,
    routeWelcome,
    routePasswordResetWithToken,
    routeClientSelect,
    routeDiscovery,
    routeCollections,
    routeLocalisations,
    routeConnectCollections,
    routeReporting,
    routeAdvancedReporting,
    routeAdvancedReportingExplore,
    routeAdvancedReportingExploreTaskManager,
    routeAdvancedReportingExplore1,
    routeAdvancedReportingExplore2,
    routeAdvancedReportingExplore3,
    routeAdvancedReportingExplore4,
    routeAdvancedReportingExplore5,
    routeConnectManager,
    routeLineupManager,
    routeAccount,
    routePlatformManagement,
    routeUserRoleManagement,
    routePreferences,
    routeRecordBuilder,
    routeTaskManager,
    routeTaskManagerTicket,
    routeTaskManagerNewTicket,
    taskManagerRoutes,
    routeRecordsSearch,
    routeBackdoor,
} from "../js/components/Global/routes";

const Login = React.lazy(() => import("../js/pages/Login"));
const Account = React.lazy(() => import("../js/pages/Account"));
const Welcome = React.lazy(() => import("../js/pages/Welcome"));
const PasswordReset = React.lazy(() => import("../js/pages/PasswordReset"));
const Discovery = React.lazy(() => import("../js/pages/Discovery"));
const RecordsSearch = React.lazy(() => import("../js/pages/RecordsSearch"));
const Collections = React.lazy(() => import("../js/pages/Collections"));
const ReportingLooker = React.lazy(() => import("../js/pages/ReportingLooker"));
const ReportingLookerExplore = React.lazy(() =>
    import("../js/pages/ReportingLooker/Explore"),
);
const ConnectManager = React.lazy(() => import("../js/pages/ConnectManager"));
const PlatformManagement = React.lazy(() =>
    import("../js/pages/PlatformManagement"),
);
const ClientSelect = React.lazy(() => import("../js/pages/ClientSelect"));
const LineupManager = React.lazy(() => import("../js/pages/LineupManager"));
const UserRoleManagement = React.lazy(() =>
    import("../js/features/UserRoleManagement"),
);
const RecordBuilder = React.lazy(() =>
    import("../js/components/RecordBuilder/entry"),
);
const Preferences = React.lazy(() => import("../js/pages/Preferences"));
const TaskManager = React.lazy(() => import("../js/pages/TaskManager"));

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();

if (process.env.SENTRY_ENABLED) {
    if (process.env.SENTRY_FRONTEND_DSN) {
      Sentry.init({
          environment: process.env.CLIENT && process.env.APP_ENV ?
      (process.env.CLIENT + "-" + process.env.APP_ENV) : 'unknown',
          dsn: process.env.SENTRY_FRONTEND_DSN,
          tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
          integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({ history }),
            Sentry.replayIntegration,
          ],
      });
    } else {
      console.error("SENTRY_FRONTEND_DSN is not set");
    }
}

export function createAppRoutes({ theme, store }) {
  const Routes = () => {
    return (
      <Sentry.ErrorBoundary>
        <BrowserRouter>
            <SentryRoute path={preLoginRoutes}>
                <Switch>
                    <SetGlobalOptions options={[CMS_DATA, OPTIONS]}>
                        <React.Suspense fallback={<PageLoading />}>
                            {/* login */}
                            <SentryRoute exact path={[routeLogin, routeBackdoor]}>
                                <PreFrameworkBoilerplate
                                    animate
                                >
                                    <Login />
                                </PreFrameworkBoilerplate>
                            </SentryRoute>

                            {/* welcome */}
                            <SentryRoute exact path={routeWelcome}>
                                <PreFrameworkBoilerplate
                                    width="28.3125rem"
                                    background="#767676"
                                    contentBackground="#e3e3e3"
                                >
                                    <Welcome />
                                </PreFrameworkBoilerplate>
                            </SentryRoute>

                            {/* password reset */}
                            <SentryRoute
                                path={routePasswordResetWithToken}
                            >
                                <PreFrameworkBoilerplate>
                                    <PasswordReset />
                                </PreFrameworkBoilerplate>
                            </SentryRoute>

                            {/* client select */}
                            <SentryRoute path={routeClientSelect}>
                                <PreFrameworkBoilerplate>
                                    <ClientSelect />
                                </PreFrameworkBoilerplate>
                            </SentryRoute>
                        </React.Suspense>
                    </SetGlobalOptions>
                </Switch>
            </SentryRoute>

            <SentryRoute path={generalRoutes}>
                <Switch>
                    <React.Suspense fallback={<Boilerplate />}>
                        <SetGlobalOptions
                            options={[
                                CMS_DATA,
                                OPTIONS,
                                CLIENT_MANAGED_OPTIONS_VERSIONS,
                                USER_PERMISSIONS,
                                ACCESS_POLICY_CATALOGS,
                                CLIENT_MANAGED_OPTIONS_LANGUAGES,
                                RECORD_BUILDER,
                                CLIENT_MANAGED_LISTS,
                            ]}
                        >
                            {/* records search */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeRecord,
                                }}
                                permission="discovery.view"
                            >
                                <SentryRoute path={routeRecordsSearch}>
                                    <Provider store={store}>
                                        <MuiThemeProvider
                                            theme={theme}
                                        >
                                            <Boilerplate
                                                active={
                                                    TYPE_RECORDS_SEARCH
                                                }
                                                processIndicator
                                            >
                                                <RecordsSearch />
                                            </Boilerplate>
                                        </MuiThemeProvider>
                                    </Provider>
                                </SentryRoute>
                            </PermissionService>

                            {/* discovery */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeRecord,
                                }}
                                permission="discovery.view"
                            >
                                <SentryRoute path={routeDiscovery}>
                                    <Provider store={store}>
                                        <MuiThemeProvider
                                            theme={theme}
                                        >
                                            <Boilerplate
                                                active={
                                                    TYPE_DISCOVERY
                                                }
                                                processIndicator
                                            >
                                                <Discovery />
                                            </Boilerplate>
                                        </MuiThemeProvider>
                                    </Provider>
                                </SentryRoute>
                            </PermissionService>

                            {/* collections */}
                            <PermissionService
                                policyPermission={{
                                    pathField:
                                        policyTypeCollections,
                                }}
                                permission="collections.view"
                            >
                                <SentryRoute path={routeCollections}>
                                    <CollectionsContextState
                                        options={{
                                            location: "collections",
                                            category:
                                                CategoryTags
                                                    .Collection
                                                    .short,
                                            newRecordText:
                                                "create new collection",
                                            type: TITLE,
                                            pageSecondaryType: COLLECTIONS,
                                            categoriesList: [
                                                CategoryTags
                                                    .Collection
                                                    .short,
                                                CategoryTags
                                                    .FranchiseCollection
                                                    .short,
                                            ],
                                        }}
                                    >
                                        <Boilerplate
                                            active={
                                                TYPE_COLLECTIONS
                                            }
                                        >
                                            <Collections />
                                        </Boilerplate>
                                    </CollectionsContextState>
                                </SentryRoute>
                            </PermissionService>

                            {/* localisations */}
                            <PermissionService
                                policyPermission={{
                                    pathField:
                                        policyTypeLocalisations,
                                }}
                                permission="collections.view"
                            >
                                <SentryRoute path={routeLocalisations}>
                                    <CollectionsContextState
                                        options={{
                                            location:
                                                "localisations",
                                            category:
                                                CategoryTags
                                                    .LocalisationOrder
                                                    .short,
                                            type: LOCALISATION,
                                            pageSecondaryType: LOCALISATION,
                                            newRecordText:
                                                "create new localisation order",
                                        }}
                                    >
                                        <Boilerplate
                                            active={
                                                TYPE_LOCALISATIONS
                                            }
                                        >
                                            <Collections />
                                        </Boilerplate>
                                    </CollectionsContextState>
                                </SentryRoute>
                            </PermissionService>

                            {/* connect collections */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeSwimlanes,
                                }}
                                permission="connect.swimlanes.view"
                            >
                                <SentryRoute
                                    path={routeConnectCollections}
                                >
                                    <CollectionsContextState
                                        options={{
                                            location: "collections",
                                            type: CONNECT,
                                            pageSecondaryType: CONNECT,
                                            category:
                                                CategoryTags
                                                    .ConnectCollection
                                                    .short,
                                            newRecordText:
                                                "create new connect collection",
                                        }}
                                    >
                                        <Boilerplate
                                            active={
                                                TYPE_CONNECT_COLLECTIONS
                                            }
                                        >
                                            <Collections />
                                        </Boilerplate>
                                    </CollectionsContextState>
                                </SentryRoute>
                            </PermissionService>

                            {/* reporting */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeExports,
                                }}
                                permission="collections.view"
                            >
                                <SentryRoute path={routeReporting}>
                                    <CollectionsContextState
                                        options={{
                                            location: "reporting",
                                            type: REPORT,
                                            pageSecondaryType: REPORT,
                                            category:
                                                CategoryTags.Report
                                                    .short,
                                            newRecordText:
                                                "create new report collection",
                                        }}
                                    >
                                        <Boilerplate
                                            active={TYPE_REPORTING}
                                        >
                                            <Collections />
                                        </Boilerplate>
                                    </CollectionsContextState>
                                </SentryRoute>
                            </PermissionService>

                            {/* looker */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeReporting,
                                }}
                                permission="reporting.looker"
                            >
                                <SentryRoute
                                    path={routeAdvancedReporting}
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_REPORTING_LOOKER
                                        }
                                    >
                                        <ReportingLooker />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_REPORTING_EXPLORE
                                        }
                                    >
                                        <ReportingLookerExplore />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExploreTaskManager
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_REPORTING_EXPLORE_TASK_MANAGER
                                        }
                                    >
                                        <ReportingLookerExplore 
                                            type={TYPE_REPORTING_EXPLORE_TASK_MANAGER}
                                        />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore1
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_CATALOG_SNAPSHORT
                                        }
                                    >
                                        <ReportingLooker
                                            urlKey="lookerUrl1"
                                        />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore2
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_TITLE_DUPLICATE_DASHBOARD
                                        }
                                    >
                                        <ReportingLooker
                                            urlKey="lookerUrl2"
                                        />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore3
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_D2C_DELIVERY_DASHBOARD
                                        }
                                    >
                                        <ReportingLooker
                                            urlKey="lookerUrl3"
                                        />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore4
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_QVOD_DELIVERY_DASHBOARD
                                        }
                                    >
                                        <ReportingLooker
                                            urlKey="lookerUrl4"
                                        />
                                    </Boilerplate>
                                </SentryRoute>

                                <SentryRoute
                                    path={
                                        routeAdvancedReportingExplore5
                                    }
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_LOCALISATION_STATUS_DASHBOARD
                                        }
                                    >
                                        <ReportingLooker
                                            urlKey="lookerUrl5"
                                        />
                                    </Boilerplate>
                                </SentryRoute>
                            </PermissionService>

                            {/* connect manager */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeUserGroups,
                                }}
                                permission="lineupManager.view"
                            >
                                <SentryRoute path={routeConnectManager}>
                                    <ConnectManagerContextState>
                                        <Boilerplate
                                            active={
                                                TYPE_CONNECT_MANAGER
                                            }
                                        >
                                            <ConnectManager />
                                        </Boilerplate>
                                    </ConnectManagerContextState>
                                </SentryRoute>

                                <SentryRoute path={routeLineupManager}>
                                    <LineupManagerContextState>
                                        <Boilerplate
                                            active={
                                                TYPE_LINEUP_MANAGER
                                            }
                                        >
                                            <LineupManager />
                                        </Boilerplate>
                                    </LineupManagerContextState>
                                </SentryRoute>
                            </PermissionService>

                            <SentryRoute path={routeAccount}>
                                <Boilerplate active={TYPE_ACCOUNT}>
                                    <Account />
                                </Boilerplate>
                            </SentryRoute>
                        </SetGlobalOptions>
                    </React.Suspense>
                </Switch>
            </SentryRoute>

            <SentryRoute path={taskManagerRoutes}>
                <Switch>
                    <SentryRoute exact path={routeTaskManager}>
                        <React.Suspense
                            fallback={
                                <Boilerplate
                                    active={TYPE_TASK_MANAGER}
                                >
                                    <LoadingTaskManagerScreen />
                                </Boilerplate>
                            }
                        >
                            <SetGlobalOptions
                                options={[
                                    CMS_DATA,
                                    OPTIONS,
                                    USER_ROLES,
                                    USER_PERMISSIONS,
                                ]}
                            >
                                {/* task manager main page */}
                                <PermissionService
                                    policyPermission={{
                                        pathField:
                                            policyTypeTaskManager,
                                    }}
                                    permission="taskManager"
                                >
                                    <Boilerplate
                                        active={TYPE_TASK_MANAGER}
                                    >
                                        <TaskManager />
                                    </Boilerplate>
                                </PermissionService>
                            </SetGlobalOptions>
                        </React.Suspense>
                    </SentryRoute>

                    <SentryRoute exact path={routeTaskManagerTicket}>
                        <React.Suspense
                            fallback={
                                <Boilerplate
                                    active={TYPE_TASK_MANAGER}
                                >
                                    <LoadingTaskManagerScreen />
                                </Boilerplate>
                            }
                        >
                            <SetGlobalOptions
                                options={[
                                    CMS_DATA,
                                    OPTIONS,
                                    USER_ROLES,
                                    USER_PERMISSIONS,
                                ]}
                            >
                                {/* task manager ticket page */}
                                <PermissionService
                                    policyPermission={{
                                        pathField:
                                            policyTypeTaskManager,
                                    }}
                                    permission="taskManager"
                                >
                                    <Boilerplate
                                        active={TYPE_TASK_MANAGER}
                                    >
                                        <TaskManager />
                                    </Boilerplate>
                                </PermissionService>
                            </SetGlobalOptions>
                        </React.Suspense>
                    </SentryRoute>

                    <SentryRoute exact path={routeTaskManagerNewTicket}>
                        <React.Suspense
                            fallback={
                                <Boilerplate
                                    active={TYPE_TASK_MANAGER}
                                >
                                    <LoadingTaskManagerScreen />
                                </Boilerplate>
                            }
                        >
                            <SetGlobalOptions
                                options={[
                                    CMS_DATA,
                                    OPTIONS,
                                    USER_ROLES,
                                    USER_PERMISSIONS,
                                ]}
                            >
                                {/* task manager new ticket page */}
                                <PermissionService
                                    policyPermission={{
                                        pathField:
                                            policyTypeTaskManager,
                                    }}
                                    permission="taskManager"
                                >
                                    <Boilerplate
                                        active={TYPE_TASK_MANAGER}
                                    >
                                        <TaskManager />
                                    </Boilerplate>
                                </PermissionService>
                            </SetGlobalOptions>
                        </React.Suspense>
                    </SentryRoute>
                </Switch>
            </SentryRoute>

            <SentryRoute path={managementRoutes}>
                <Switch>
                    <React.Suspense fallback={<Boilerplate />}>
                        <SetGlobalOptions
                            options={[
                                CMS_DATA,
                                OPTIONS,
                                CLIENT_MANAGED_OPTIONS_VERSIONS,
                                USER_ROLES,
                                ACCESS_CATALOGS,
                                RECORD_BUILDER,
                                USER_PERMISSIONS,
                                CLIENT_MANAGED_LISTS,
                            ]}
                        >
                            {/* platform management */}
                            <PermissionService
                                policyPermission={{
                                    pathField:
                                        policyTypePlatformManagement,
                                }}
                                permission="platformManagement"
                            >
                                <SentryRoute
                                    path={routePlatformManagement}
                                >
                                    <Boilerplate
                                        active={
                                            TYPE_PLATFORM_MANAGEMENT
                                        }
                                    >
                                        <PlatformManagement
                                            type={
                                                TYPE_PLATFORM_MANAGEMENT
                                            }
                                        />
                                    </Boilerplate>
                                </SentryRoute>
                            </PermissionService>

                            {/* preferences */}
                            <PermissionService
                                policyPermission={{
                                    pathField:
                                        policyTypePlatformManagement,
                                }}
                                permission="platformManagement"
                            >
                                <SentryRoute path={`${routePreferences}*`}>
                                    <Boilerplate
                                        active={TYPE_PREFERENCES}
                                    >
                                        <Preferences />
                                    </Boilerplate>
                                </SentryRoute>
                            </PermissionService>

                            {/* user roles and policies */}
                            <PermissionService
                                policyPermission={{
                                    pathField: policyTypeUserRolesAndPolicies,
                                }}
                                permission="userRoleManagement"
                            >
                                <SentryRoute path={`${routeUserRoleManagement}*`}>
                                    <Boilerplate
                                        active={
                                            TYPE_USER_ROLE_MANAGEMENT
                                        }
                                    >
                                        <UserRoleManagement />
                                    </Boilerplate>
                                </SentryRoute>
                            </PermissionService>

                            {/* record builder */}
                            <PermissionService
                                policyPermission={{
                                    pathField:
                                        policyTypeRecordBuilder,
                                }}
                                permission="recordBuilder"
                            >
                                <SentryRoute path={routeRecordBuilder}>
                                    <Boilerplate
                                        active={TYPE_RECORD_BUILDER}
                                    >
                                        <RecordBuilder />
                                    </Boilerplate>
                                </SentryRoute>
                            </PermissionService>
                        </SetGlobalOptions>
                    </React.Suspense>
                </Switch>
            </SentryRoute>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  };

  return { Routes };
}
