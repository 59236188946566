import React from "react";
import styles from "../styles";
import Nav from "./Nav";
import InternetConnectionMonitor from "../../../components/InternetConnectionMonitor";
import { useStore } from "../../../components/Global/store/store";
import CoreSnackbar from "../../../core/Snackbar/CoreSnackbar"
import { setSnackbarRemove } from "../../../components/Global/store/reducer"

export default (props) => {
    const { children, cmsData = {}, ...propsWithoutChildren } = props;

    const [globalState, dispatch] = useStore();

    const {
        snackbars = []
    } = globalState

    return (
        <div
            style={styles().container}
            id="meta-boilerplate"
            className="navigation-design-4"
        >
            <Nav {...props} />

            <div style={styles().content} id="meta-boilerplate-content">
                {React.Children.toArray(children).map((child) =>
                    React.cloneElement(child, {
                        ...propsWithoutChildren,
                        cmsData,
                    }),
                )}
            </div>

            <InternetConnectionMonitor />

            {snackbars.map((snackbar, index) => {
                return (
                    <CoreSnackbar
                        open={snackbars.length > 0}
                        handleClose={() => dispatch(setSnackbarRemove(index))}
                        key={`snackbar-${index}`}
                        snackbar={snackbar}
                    />
                );
            })}
        </div>
    );
};
