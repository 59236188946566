import { gql } from "@apollo/client";
import { envTest } from "../../../../../components/Environment"
import { schemaGetAll as assetsTypesSchemaGetAll } from "./assetsTypes";
import { schemaGetAll as assetsPlatformsGetAll } from './assetsPlatforms';
import { schemaGetAll as assetsCodesGetAll } from './assetsCodes';
import { schemaGetAll as descriptionsSchemaGetAll } from "./descriptions";
import { schemaGetAll as idsSchemaGetAll } from "./ids";
import { schemaGetAll as rightsSchemaGetAll } from "./rights";
import { schemaGetAll as schedulingSchemaGetAll } from "./scheduling";
import { schemaGetAll as schedulingSystemsSchemaGetAll } from "./schedulingSystem";
import { schemaGetAll as schedulingPlatformsSchemaGetAll } from "./schedulingPlatform";
import { schemaGetAll as titlesSchemaGetAll } from "./titles";
import { schemaGetAll as originationSchemaGetAll } from './origination';
import { schemaGetAll as numberingSchemaGetAll } from './numbering'
import { schemaGetAll as descriptorsSchemaGetAll } from './descriptors'
import { schemaGetAll as knownAsSchemaGetAll } from './knownAs'
import { schemaGetAll as contributorSectionsGetAll } from './contributorSections';

export const getAllEntities = () => {
    const isEnabled = (envVar: string | boolean) => envVar === 'true' || envVar === true;

    const queries = [
        isEnabled(process.env.RECORD_BUILDER_ASSETS_ENABLED) ? assetsTypesSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_ASSETS_ENABLED) ? assetsPlatformsGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_ASSETS_ENABLED) ? assetsCodesGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_DESCRIPTIONS_ENABLED) ? descriptionsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_IDS_ENABLED) ? idsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_RIGHTS_ENABLED) ? rightsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_ORIGINATION_ENABLED) || isEnabled(process.env.RECORD_BUILDER_CUSTOM_FIELDS_ENABLED) ? originationSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_TITLES_ENABLED) ? titlesSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_SCHEDULING_PLATFORMS_ENABLED) ? schedulingSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_SCHEDULING_PLATFORMS_ENABLED) ? schedulingSystemsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_SCHEDULING_PLATFORMS_ENABLED) ? schedulingPlatformsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_CONTRIBUTORS_ENABLED) ? contributorSectionsGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_NUMBERING_ENABLED) ? numberingSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_SECTIONS_ENABLED) ? sectionsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_DESCRIPTORS_ENABLED) ? descriptorsSchemaGetAll : '',
        isEnabled(process.env.RECORD_BUILDER_KNOWNAS_ENABLED) ? knownAsSchemaGetAll : '',
    ].filter(Boolean).join('\n');

    return gql`
        query {
            ${queries}
        }
    `;
};

const getAllMap = {
    queryAll: !envTest ? getAllEntities() : '',
    dataPath: "",
};

export default getAllMap;