import React from 'react'
import { makeObservable, action, extendObservable } from 'mobx'
import { findIndex, isEmpty, isObject, isString } from "lodash"
import { v4 as uuidv4 } from 'uuid';
import update from "react-addons-update";
import { RootStore } from '../rootStore'
import { creditFormStoreInitialState } from "../state"
import { CreditInterface, CreditLocalisedData, CreditCharacterName } from "../interfaces"
import Field from '../../../../../models/RecordBuilder/Field.interface'
import CoreFormFieldInterface from '../../../../../core/Form/dynamic/interfaces/Field.interface'
import CoreDynamicFormFieldsMapper from '../../../../../components/RecordBuilder/map/dynamicCoreForm/coreDynamicForm'
import { getDefaultWrittenLanguageCodeFromCache } from "../../../../../models/ClientManaged/languagesModel"
import { fieldCharacterName, fieldCharacterNameLanguage, fieldRole, fieldName, fieldNotes } from "../../config/creditForm.config"
import { FieldJqueryInterface } from "../../../../../core/Form/dynamic/interfaces/Form.interface"

export class CreditFormStore {
    rootStore: RootStore;
    configuration: Field[];
    coreFormFields: CoreFormFieldInterface[];
    creditOriginal: CreditInterface;
    credit: CreditInterface;
    defaultLocale: string;
    fetching: boolean;
    changeId: string;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            initialize: action,

            setCreditOriginal: action,
            setCredit: action,
            setCoreFormFields: action,
            setDefaultLocale: action,
            setFetching: action,
            setChangeId: action,
            handleSetDefaultLocale: action,
            handleLocaleChange: action
        }); 

        extendObservable(this, creditFormStoreInitialState);
    }

    initialize() {
        this.reset()
    }

    reset() {
        Object.keys(creditFormStoreInitialState).forEach(
            (key) => (this[key] = creditFormStoreInitialState[key]),
        );
    }

    setConfiguration(configuration: any): void {
        this.configuration = configuration
    }

    setCreditOriginal(creditOriginal: CreditInterface): void {
        this.creditOriginal = creditOriginal
    }

    setCoreFormFields(coreFormFields: CoreFormFieldInterface[]): void {
        this.coreFormFields = coreFormFields
    }

    setCredit(credit: CreditInterface): void {
        this.credit = credit
    }

    setDefaultLocale(defaultLocale: string): void {
        this.defaultLocale = defaultLocale
    }

    setFetching(fetching: boolean): void {
        this.fetching = fetching
    }

    setChangeId(): void {
        this.changeId = uuidv4()
    }

    getFieldJquery(): FieldJqueryInterface {
        let result = {
            hide: [],
            slideDown: []
        }

        const actorRoleId = this.rootStore.optionsStore.getActorRoleId()

        if (this.credit?.[fieldRole.key] !== actorRoleId) {
            result.hide.push(".core-dynamic-form-input-cell-wrapper--locale")
        }

        if (isEmpty(this.credit[fieldCharacterNameLanguage.key])) {
            result.hide.push(".core-dynamic-form-input-cell-wrapper--character_name")
        }

        if (this.credit?.[fieldRole.key] == actorRoleId) {
            if (!isEmpty(this.credit[fieldCharacterNameLanguage.key])) {
                result.slideDown = [
                    ".core-dynamic-form-input-cell-wrapper--locale",
                    ".core-dynamic-form-input-cell-wrapper--character_name"
                ]
            } else {
                result.slideDown = [
                    ".core-dynamic-form-input-cell-wrapper--locale"
                ]
            }
        }

        return result
    }

    getDefaultCreditValues(): CreditInterface {
        return {
            ...this.credit,
            [fieldName.key]: '',
            [fieldNotes.key]: '',
            [fieldRole.key]: '',
            [fieldCharacterNameLanguage.key]: '',
            [fieldCharacterName.key]: []
        }
    }

    getCharacterNameOption(cn: any): any {
        let result = {
            name: cn.text_character_name || cn.name?.value || cn.name
        }
        
        if (cn.deleted) {
            result.deleted = true
        }

        return result
    }

    getExistingLocalisedData(): CreditLocalisedData {
        return this.credit.localised_data?.find((ld: CreditLocalisedData) => ld.locale === this.credit.locale)
    }

    getCharacterNamesFromCredit(credit: CreditInterface): CreditCharacterName[] {
        const localisedData: CreditLocalisedData = credit?.localised_data?.find((ld: CreditLocalisedData) => ld.locale === (credit.locale || this.defaultLocale))
        const characterNames: CreditCharacterName[] = localisedData?.character_names?.filter((ch: CreditCharacterName) => !ch.deleted)

        return characterNames?.map((cn: any) => {
            return {
                name: cn.name,
                value: cn.name
            }
        })
    }

    handleLocaleChange(): void {}

    handleSetOriginalData(): void {
        this.setCreditOriginal(
            this.getDefaultCreditValues()
        )
    }

    handleChange(credit: CreditInterface, item: any): void {
        let transformedCredit = {...credit}
        delete transformedCredit.contributor_id
        
        let updatedState: CreditInterface = {
            ...this.credit,
            ...transformedCredit
        }

        if (isString(credit.contributor_id) && credit.contributor_id !== this.credit.contributor_id) {
            updatedState.contributor_id = credit.contributor_id
        }

        const contributor = item?.values?.contributor

        if (item.name == 'contributor' && isObject(contributor?.contributor)) {
            this.rootStore.contributorsStore.handleAddContributor(contributor?.contributor)

            if (contributor?.contributor_id) {
                updatedState.contributor_id = contributor?.contributor_id
                updatedState.text_contributor_id = contributor?.contributor_id
            }
        }

        if (item.name == 'contributor' && !contributor) {
            updatedState.contributor_id = ""
            updatedState.text_contributor_id = ""
        }

        if (item.name === fieldCharacterNameLanguage.key) {
            updatedState = this.handleSetCharacterNameFromLocaleChange(updatedState)
        }

        if (item.name === `text_${fieldCharacterName.key}` || item.name === fieldCharacterName.key) {
            updatedState = this.handleSetLocalisedDataFromCharacterName(updatedState)
        }

        this.setCredit(updatedState)
        this.handleSetCoreFormFields()

        // Fetch locale data
        if (item.name === fieldCharacterNameLanguage.key) {
            this.handleLocaleChange()
        }
    }

    handleSetCharacterNameFromLocaleChange(credit: CreditInterface): CreditInterface {
        let result = {...credit}

        const localisedData: CreditLocalisedData | {} = credit?.localised_data?.find((ld) => ld.locale === credit.locale) || {}

        result = {
            ...credit,
            character_name: isEmpty(localisedData?.character_names) 
                ? [] 
                : localisedData?.character_names?.map((cn: any) => {
                    return {
                        name: cn.name,
                        value: cn.name
                    }
                }) || []
        }

        return result
    }

    handleSetLocalisedDataFromCharacterName(credit: CreditInterface): CreditInterface {
        let result: CreditInterface = {...credit}

        const localisedData: CreditLocalisedData[] = [...credit.localised_data || []]

        if (!isEmpty(this.creditOriginal.character_name)) {
            this.creditOriginal.character_name.forEach((characterName: any) => {
                if (!result.character_name.find((cn: any) => cn.name === characterName.name)) {
                    result.character_name.push({
                        ...characterName,
                        deleted: true
                    })
                }
            })
        }

        if (result.locale) {
            const localeIndex: number = findIndex(localisedData, function (item) {
                return item.locale == result.locale;
            });

            if (!Array.isArray(result.localised_data)) {
                result.localised_data = []
            }

            if (localeIndex == -1) {
                result = update(result, {
                    localised_data: {
                        $push: [{
                            locale: result.locale,
                            character_names: result.character_name?.map((cn: any) => this.getCharacterNameOption(cn))
                        }]
                    }
                });
            } else {
                result = update(result, {
                    localised_data: {
                        [localeIndex]: {
                            character_names: {
                                $set: result.character_name?.map((cn: any) => this.getCharacterNameOption(cn))
                            }
                        }
                    }
                });
            }

            return result
        }
    } 

    handleSetCoreFormFields(): void {
        const coreFormFields: CoreFormFieldInterface[] = CoreDynamicFormFieldsMapper({ 
            category: '', 
            configuration: this.configuration?.fields || [],
            path: this.configuration?.code,
            formState: 'edit'
        })

        this.setCoreFormFields(coreFormFields)
    }

    handleSetDefaultLocale(): void {
        this.setDefaultLocale(
            getDefaultWrittenLanguageCodeFromCache()
        )
    }
}
