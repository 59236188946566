import React from "react";
import _ from "lodash";
import styles from "../styles";
import "../styles/_styles.scss";
import DynamicTable from "../../DynamicTable/linkage";
import CategoryTags from "../../CategoryTags";
import Tag from "../../Tag";
import Index from "./Index";
import PlaceholderText from "../../PlaceholderText";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import AddRecordLinkage from "./createLinkage/AddRecordLinkage";
import Notification from "../../Notification";
import AddNewButtonWithLineMultiple from "../../AddNewButtonWithLine/Multiple";
import GetCategoryMatrix from "./createLinkage/src/GetCategoryMatrix";
import InheritToLinkedRecords from "../../DataInheritance/ToLinkedRecords";
import DataInheritance from "../../DataInheritance";
import FetchConfig from "../../DataInheritance/helpers/FetchConfig";
import FetchConvertConfig from "../../RecordConverter/src/FetchConfig";
import FetchCategoryList from "../../DataInheritance/helpers/FetchCategoryList";
import RecordConverter from "../../RecordConverter";
import AuthService from "../../../services/AuthService";
import { userHasPermission } from "../../../services/PermissionService";
import {
    accessTypeUpdate,
    policyTypeRecord,
    accessTypeStrictCreate,
    accessTypeStrictUpdate,
    accessTypeStrictDelete,
} from "../../../features/UserRoleManagement/utils/accessOptions";
import { linkageOption } from "../../../features/UserRoleManagement/utils/sectionOptions/recordSections";
import CollapsableCheckboxSection from "../../CollapsableCheckboxSection";
import { LinkagesTable } from "../../../core/Table/linkages/Linkages.table";
import { FirstReleaseYearCol } from "../../../core/Table/linkages/columns/FirstReleaseYear.column";
import { Button } from "@mui/material";
import Settings from "../../Settings";
import { sortCategories } from "../utils/helpers/linkage/sortCategories";
import { envTest } from "../../Environment"

export default class RelatedTitles extends Index {
    constructor(props) {
        super(props);

        const { copy = {} } = this.props;

        /**
         * @var object
         */
        this.styles = styles(this.props);

        /**
         * @var array
         */
        this.categories = this.getCategories();

        /**
         * @var object
         */
        this.copy = copy;

        /**
         * @var array
         */
        this.disabledData = [];

        /**
         * @var array
         */
        this.disabledCategories = [];

        this.userPermissions = {
            create: false,
            delete: false,
            update: false,
            inheritance: false
        }

        /**
         * @var object
         */
        this.state = {
            data: this.props.data || [],
            notification: {
                disabled: true,
                confirmed: false,
            },
            errorMessage: "",
            linkConverting: {
                enabled: false,
            },
            expanded: [],
        };
    }

    componentWillMount() {
        this.setUserPermissions()
    }

    componentDidMount() {
        this.setDisabledCategories();
        this.setFileteredData();
        this.setDisabledData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.meta_id, this.props.meta_id)) {
            return true;
        }

        if (!_.isEqual(nextProps.searchUrl, this.props.searchUrl)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    setUserPermissions() {
        const {
            clientDataStructure = {},
            clientFeatures,
            cmsData = {},
            userPermissions = [],
        } = this.props;

        const pathField = `${policyTypeRecord}.category.${this.props.category}`;

        const permissionUpdate = userHasPermission({
            cmsData: this.props.cmsData || {},
            userPermissions: userPermissions,
            pathField: pathField,
            access: accessTypeStrictUpdate,
            legacyPermission: 'disacovery.edit'
        });

        const permissionInherit = userHasPermission({
            cmsData: this.props.cmsData || {},
            userPermissions: userPermissions,
            pathField: pathField,
            access: accessTypeStrictUpdate,
            legacyPermission: 'discovery.inheritData'
        });

        const permissionCreate = userHasPermission({
            cmsData: this.props.cmsData || {},
            userPermissions: userPermissions,
            pathField: pathField,
            access: accessTypeStrictCreate,
            legacyPermission: 'disacovery.create'
        });

        const permissionDelete = userHasPermission({
            cmsData: this.props.cmsData || {},
            userPermissions: userPermissions,
            pathField: pathField,
            access: accessTypeStrictDelete,
            legacyPermission: 'disacovery.delete'
        });

        this.userPermissions = {
            create: permissionCreate,
            delete: permissionDelete,
            update: permissionUpdate,
            inheritance: permissionInherit,
        }

        if (envTest) {
            this.userPermissions = {
                create: true,
                delete: true,
                update: true,
                inheritance: true,
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.data, prevProps.data)) {
            if (Array.isArray(this.props.data)) {
                this.setStateValue("data", this.props.data);
            }
        }
    }

    getCategories() {
        const { 
            options, 
            category = "",
            clientDataStructure = {},
            clientFeatures = {},
        } = this.props;

        let categories = [];

        if (process.env.NODE_ENV == "test") {
            const transformedCategory = category.replace(" ", "");
            if (typeof CategoryTags[transformedCategory] == "object") {
                categories = deepcopy(
                    CategoryTags[transformedCategory].linkage,
                );
            }
        } else {
            categories = GetCategoryMatrix({
                clientDataStructure,
                clientFeatures,
                options,
                categoryName: category,
            });
        }

        const filteredCategories = this.filterCategories(
            categories.map((item) => item.value || item.text || item),
        );

        return sortCategories(this.props.category, filteredCategories);
    }

    filterCategories(items) {
        const { clientDataStructure = {} } = this.props;

        const { categories = {} } = clientDataStructure;

        const { hidden = [] } = categories;

        return items.filter((item) => !hidden.includes(item.replace(" ", "")));
    }

    setDisabledCategories() {
        let result = [];

        const { category } = this.props;

        if (typeof category == "string") {
            if (category == CategoryTags.Show.short) {
                result = [CategoryTags.Season.short];
            }

            if (category == CategoryTags.Season.short) {
                result = [
                    CategoryTags.Show.short,
                    CategoryTags.Episode.short,
                    CategoryTags.ConstructedEpisode.short,
                    CategoryTags.Storyline.short,
                ];
            }

            if (category == CategoryTags.Storyline.short) {
                result = [CategoryTags.Show.short, CategoryTags.Season.short];
            }

            if (
                category == CategoryTags.Episode.short ||
                category == CategoryTags.ConstructedEpisode.short ||
                category == CategoryTags.Storyline.short
            ) {
                result = [CategoryTags.Show.short, CategoryTags.Season.short];
            }
        }

        this.disabledCategories = this.filterCategories(result);
    }

    setFileteredData() {
        const { data } = this.props;

        let result = [];

        if (Array.isArray(data)) {
            result = deepcopy(data);

            if (!_.isEmpty(this.disabledCategories)) {
                this.disabledCategories.forEach((category) => {
                    result = result.filter(
                        (item) => item.category !== category,
                    );
                });
            }
        }

        this.setState({
            data: result,
        });
    }

    setDisabledData() {
        const { data } = this.props;

        let result = [];

        if (!_.isEmpty(this.disabledCategories) && Array.isArray(data)) {
            this.disabledCategories.forEach((category) => {
                data.forEach((item) => {
                    if (item.category == category) {
                        item.disabled = true;
                        result.push(item);
                    }
                });
            });
        }

        this.setState({data: [...this.state.data, ...result]})
    }

    getNotificationObject(category) {
        let result = false;

        // Feature
        if (this.props.category == CategoryTags.Feature.short) {
            if (category == CategoryTags.Feature.short) {
                result = {
                    status: "success",
                    title:
                        this.copy.linkageFeatureFeatureTitle ||
                        "Linking Features",
                    description:
                        this.copy.linkageFeatureFeatureDescription ||
                        "To group Movies, please do this through the creation of a new Collection that contains both films. This Linkage is specifically for the rare & exceptional case that a Film has a Hierarchical Direct Relationship to another Movie, for example: Hearts of Darkness & Apocalypse Now.",
                };
            }
        }

        // Short
        if (this.props.category == CategoryTags.Short.short) {
            if (category == CategoryTags.Short.short) {
                result = {
                    status: "success",
                    title:
                        this.copy.linkageShortShortTitle ||
                        "Linking Short Form",
                    description:
                        this.copy.linkageShortShortDescription ||
                        "Short Form titles are normally grouped through a Parental Hierarchy. This Linkage is specifically for the rare & exceptional case that a Short Form has a Hierarchical Direct Relationship to another Short Form, for example: A Commercial for the upcoming release of a Music Video.",
                };
            }
        }

        // Show
        if (this.props.category == CategoryTags.Show.short) {
            if (category == CategoryTags.Season.short) {
                result = {
                    status: "alert",
                    title: this.copy.linkageShowSeasonTitle || "Add Season",
                    description:
                        this.copy.linkageShowSeasonDescription ||
                        "Seasons are uniquely linked to Shows where they are created. To create a new Season for this Show, please exit this record, create a New Season from the homescreen and assign it to this show. It will then automcailly appear here.",
                };
            }

            if (category == CategoryTags.Storyline.short) {
                result = {
                    status: "alert",
                    title: this.copy.linkageShowStorylineTitle || "Add Season",
                    description:
                        this.copy.linkageShowStorylineDescription ||
                        "Storylines are uniquely linked to Shows where they are created. To create a new Storylines for this Show, please exit this record, create a New Storylines from the homescreen and assign it to this show. It will then automcailly appear here.",
                };
            }

            if (category == CategoryTags.Special.short) {
                result = {
                    status: "success",
                    title: this.copy.warning || "Warning",
                    description:
                        this.copy.linkageShowSpecialDescription ||
                        "Is it extremely unusual for a Special to be associated with more than one Show. If this is a crossover episode, please search and add the additional Show/s here. Otherwise, in most cases this should just be the single show listed.",
                };
            }
        }

        // Season
        if (this.props.category == CategoryTags.Season.short) {
            if (category == CategoryTags.Feature.short) {
                result = {
                    status: "success",
                    title: this.copy.linkageSeasonFeatureTitle || "Add Feature",
                    description:
                        this.copy.linkageSeasonFeatureDescription ||
                        "Use this only to add Feature Films to this Season that were created & uniquley intended for addition to this sequence of programmes.",
                };
            }

            if (category == CategoryTags.Show.short) {
                result = {
                    status: "alert",
                    title: this.copy.linkageSeasonShowTitle || "Add Show",
                    description:
                        this.copy.linkageSeasonShowDescription ||
                        "Shows are uniquely linked to Seasons where they are created. As such you cannot add a new Show to this season.",
                };
            }

            if (category == CategoryTags.Episode.short) {
                result = {
                    status: "success",
                    title: this.copy.linkageSeasonEpisodeTitle || "Add Episode",
                    description:
                        this.copy.linkageSeasonEpisodeDescription ||
                        "Episodes are uniquely linked to Shows or Seasons when they are created. As such all available Episodes should be listed here already. If you need to include an additional Episode into a Running Order, please do that through the Running Order Tab of the Season you need to update. If an Episode was created under a Show, and now you would like to add it to this Season, you can do that here.",
                };
            }

            if (category == CategoryTags.ConstructedEpisode.short) {
                result = {
                    status: "alert",
                    title:
                        this.copy.linkageSeasonEpisodeConstructedTitle ||
                        "Add Episode",
                    description:
                        this.copy.linkageSeasonEpisodeConstructedDescription ||
                        "Episodes are uniquely linked to Shows or Seasons when they are created. As such all available Episodes should be listed here already. If you need to include an additional Episode into a Running Order, please do that throguh the Running Order Tab of the Season you need to update.",
                };
            }

            if (category == CategoryTags.Storyline.short) {
                result = {
                    status: "alert",
                    title:
                        this.copy.linkageSeasonEpisodeConstructedTitle ||
                        "Add Storyline",
                    description:
                        this.copy.linkageSeasonEpisodeConstructedDescription ||
                        "Storylines are uniquely linked to Shows or Seasons when they are created. As such all available Storylines should be listed here already. If you need to include an additional Storyline into a Running Order, please do that throguh the Running Order Tab of the Season you need to update.",
                };
            }

            if (category == CategoryTags.Special.short) {
                result = {
                    status: "success",
                    title:
                        this.copy.linkageSeasonEpisodeConstructedTitle ||
                        "Add Special",
                    description:
                        this.copy.linkageSeasonEpisodeConstructedDescription ||
                        "Is it extremely unusual for a Special to be associated with more than one Season. If this is a crossover episode, please search and add the additional Show/s here. Otherwise, in most cases this should just be the single show listed.",
                };
            }
        }

        // Episode
        if (this.props.category == CategoryTags.Episode.short) {
            if (category == CategoryTags.Season.short) {
                result = {
                    status: "success",
                    title: this.copy.linkageEpisodeSeasonTitle || "Add Season",
                    description:
                        this.copy.linkageEpisodeSeasonDescription ||
                        "Episodes are uniquely linked to Seasons then they are created. As such the Parent season should be listed here already. If this Episode was created under a Show, and now you would like to assign it to a Season, you may do that here.",
                };
            }
        }

        // EpisodeConstructed
        if (this.props.category == CategoryTags.ConstructedEpisode.short) {
            if (category == CategoryTags.Season.short) {
                result = {
                    status: "alert",
                    title:
                        this.copy.linkageEpisodeConstructedSeasonTitle ||
                        "Add Season",
                    description:
                        this.copy.linkageEpisodeConstructedSeasonDescription ||
                        "Episodes are uniquely linked to Seasons when they are created and as such you are unable to add additional seasons here for this Episode. If you would like to create a Running Orde rcontaining Episode from Mutliple Seasons, you must do that throguh the Running Order Tab of the Source Season.",
                };
            }
        }

        // Storyline
        if (this.props.category == CategoryTags.Storyline.short) {
            if (category == CategoryTags.Show.short) {
                result = {
                    status: "alert",
                    title: this.copy.linkageStorylineShowTitle || "Add Show",
                    description:
                        this.copy.linkageStorylineShowDescription ||
                        "Shows are uniquely linked to Storylines where they are created. Is it extremely unusual for a Storyline to be associated with more than one Show. If this is a crossover Storyline, please search and add the additional Show/s here. Otherwise, in most cases this should just contain the single show listed.",
                };
            }

            if (category == CategoryTags.Season.short) {
                result = {
                    status: "alert",
                    title:
                        this.copy.linkageStorylineSeasonTitle || "Add Season",
                    description:
                        this.copy.linkageStorylineSeasonDescription ||
                        "Storylines are uniquely linked to Shows or Seasons when they are created and as such you are unable to add additional seasons here for this Storyline. If you would like to create a Running Order containing Storylines from Mutliple Seasons, you must do that throguh the Running Order Tab of the Source Season.",
                };
            }
        }

        // Storyline
        if (this.props.category == CategoryTags.Special.short) {
            if (category == CategoryTags.Show.short) {
                result = {
                    status: "success",
                    title: this.copy.warning || "Warning",
                    description:
                        this.copy.linkageSpecialShowDescription ||
                        "Is it extremely unusual for a Special to be associated with more than one Show. If this is a crossover episode, please search and add the additional Show/s here. Otherwise, in most cases this should just be the single Show listed.",
                };
            }

            if (category == CategoryTags.Season.short) {
                result = {
                    status: "success",
                    title: this.copy.warning || "Warning",
                    description:
                        this.copy.linkageSpecialSeasonDescription ||
                        "Is it extremely unusual for a Special to be associated with more than one Season. If this is a crossover episode, please search and add the additional Season/s here. Otherwise, in most cases this should just be the single Season listed.",
                };
            }
        }

        return result;
    }

    filterData() {
        let result = deepcopy(this.props.data);

        return result;
    }

    getCategoriesWithData() {
        const { data } = this.state;

        return _.uniq(
            data.concat(this.disabledData)
                .map((item) => item.category)
        );
    }

    expandAllOnClick = () => {
        const categoriesWithData = this.getCategoriesWithData();
        
        if (this.state.expanded.length !== categoriesWithData.length) {
            this.setState({ expanded: categoriesWithData });
        } else {
            this.setState({ expanded: [] });
        }
    }

    handleChange(key, data) {
        if (typeof key == "string" && Array.isArray(data)) {
            const stateData = deepcopy(this.state.data || []);
            const deletedData = stateData.filter(
                (item) => item.category !== key,
            );
            const updatedData = update(deletedData, {
                $push: data,
            });

            this.setState(
                {
                    data: updatedData,
                },
                () => {
                    if (typeof this.props.handleFormChange == "function") {
                        this.props.handleFormChange(updatedData);
                    }
                },
            );
        }
    }

    renderNoneAdded() {
        return false;
    }

    renderTags(props) {
        const { 
            tag, 
            readOnly, 
            count,
            tagStyles = {} 
        } = props;

        return (
            <div>
                <Tag
                    text={tag.multiple}
                    background={tag.color}
                    styles={{
                        display: "inline-block",
                        marginBottom: "0.625rem",
                        fontWeight: 500,
                        ...tagStyles
                    }}
                />

                {tag.type && (
                    <Tag
                        text={tag.type}
                        background={tag.color}
                        styles={{
                            display: "inline-block",
                            background: "#dcdcdc",
                            marginLeft: "0.3125rem",
                            color: "#767676",
                            fontWeight: 500,
                        }}
                    />
                )}

                {readOnly &&
                    <Tag
                        text="Primary"
                        background="grey"
                        styles={{
                            display: "inline-block",
                            marginBottom: "0.625rem",
                            fontWeight: 500,
                            marginLeft: "0.3125rem",
                            ...tagStyles
                        }}
                    />
                }

                {count &&
                    <Tag
                        text={count}
                        background="grey"
                        styles={{
                            display: "inline-block",
                            marginBottom: "0.625rem",
                            fontWeight: 500,
                            marginLeft: "0.3125rem",
                            ...tagStyles
                        }}
                    />
                }
            </div>
        )
    }

    renderItems(options) {
        const { categories, disabled, data, readOnly } = options;

        const {
            customIds,
            clientDataStructure = {},
            clientFeatures = {},
        } = this.props;

        const { linkage_numbering_tags = [] } = clientDataStructure;

        const { linkageNumberingV2 = false, linkagesVersion = 'v1' } = clientFeatures;

        return (
            <div style={this.styles.marginItem}>
                {categories.map((category, index) => {
                    const transformedCategory = category.replace(" ", "");
                    let tag = deepcopy(CategoryTags[transformedCategory]) || {};
                    let categoryData = data.filter(
                        (item) => item.category == category,
                    );
                    let categoryDataWithoutDeleted = categoryData.filter(
                        (item) => !(item.deleted && item.deleted == true),
                    );

                    if (
                        categoryData &&
                        Array.isArray(categoryData) &&
                        categoryData.length > 0 &&
                        categoryDataWithoutDeleted.length > 0
                    ) {
                        let searchUrl = this.props.searchUrl;
                        if (typeof this.props.searchUrl == "string") {
                            searchUrl = this.props.searchUrl.replace(
                                "{category}",
                                category.toLowerCase(),
                            );
                        }

                        let linkedRecordTitle = this.copy.linkedRecordCategory;
                        if (typeof linkedRecordTitle == "string") {
                            linkedRecordTitle = linkedRecordTitle.replace(
                                "{category}",
                                tag.short,
                            );
                        }

                        if (!linkedRecordTitle) {
                            linkedRecordTitle = `Linked ${tag.short} Record`;
                        }

                        const sortContent = (key) => {
                            return categoryData.sort((a, b) => {
                                return parseFloat(a[key]) - parseFloat(b[key]);
                            });
                        };

                        switch (category) {
                            case CategoryTags.Season.short:
                                categoryData = sortContent("season_number");
                                break;
                            case CategoryTags.Episode.short:
                                categoryData = sortContent("episode_number");
                                break;
                            case CategoryTags.Storyline.short:
                                categoryData = sortContent("storyline_number");
                                break;
                        }

                        if (linkagesVersion == 'v2') {
                            return (
                                <CollapsableCheckboxSection
                                    shouldShowChildren={true}
                                    checkbox={false}
                                    titleElement={this.renderTags({ 
                                        tag, 
                                        readOnly,
                                        count: categoryData.length,
                                        tagStyles: {
                                            marginBottom: 0,
                                        } 
                                    })}
                                    setExpanded={() => {
                                        if (!this.state.expanded.includes(category)) {
                                            this.setState({ expanded: [...this.state.expanded, category] });
                                        } else {
                                            this.setState({ expanded: this.state.expanded.filter((item) => item !== category) });
                                        }
                                    }}
                                    expanded={this.state.expanded.includes(category)}
                                    key={`related-titles-section-${category}-${index}`}>

                                    <>
                                        {_.isEmpty(categoryData) && disabled && (
                                            <PlaceholderText
                                                copy={this.props.copy || {}}
                                            />
                                        )}

                                        <LinkagesTable 
                                            customIds={customIds}
                                            category={category}
                                            linkages={categoryData.map((item, index) => {
                                                return {
                                                    ...item,
                                                    id: `linkage_${item.meta_id}_${index}`
                                                }
                                            })}
                                            disabled={this.disabledCategories?.includes(category) || !this.userPermissions.delete || disabled}
                                            handleLinkSearch={(searchTerm, url) => {
                                                url.categoryTag = tag;
                                                this.props.handleLinkSearch(
                                                    searchTerm,
                                                    url,
                                                );
                                            }}
                                            handleChange={this.handleChange.bind(
                                                this,
                                                category,
                                            )}
                                            key={`related-titles-section-${category}-${index}-${disabled}`}
                                        />
                                    </>

                                </CollapsableCheckboxSection>
                            )
                        }

                        return (
                            <div
                                key={`related-titles-section-${category}-${index}`}
                                className={`meta-linkage--item meta-linkage--item-${category}`}
                            >

                                {this.renderTags({ 
                                    tag, 
                                    tagStyles: {} 
                                })}

                                {_.isEmpty(categoryData) && disabled && (
                                    <PlaceholderText
                                        copy={this.props.copy || {}}
                                    />
                                )}

                                <DynamicTable
                                    disabled={categoryData.disabled || disabled}
                                    data={categoryData}
                                    customIds={customIds}
                                    numberingStructure={linkage_numbering_tags}
                                    linkageNumberingV2={linkageNumberingV2}
                                    links={true}
                                    meta_id={this.props.meta_id}
                                    handleLinkSearch={(searchTerm, url) => {
                                        url.categoryTag = tag;
                                        this.props.handleLinkSearch(
                                            searchTerm,
                                            url,
                                        );
                                    }}
                                    handleChange={this.handleChange.bind(
                                        this,
                                        category,
                                    )}
                                    searchUrl={searchUrl}
                                    api_token={this.props.api_token}
                                    category={category}
                                    linkTitle={linkedRecordTitle}
                                    notificationClass={`meta-linkage-notification-${category}`}
                                    notification={this.getNotificationObject(
                                        category,
                                    )}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    handleLinkConverting(linkConverting) {
        this.setState({ linkConverting });
    }

    renderAddRecordLinkNotificationHtml() {
        const {
            options = {},
            category = "",
            recordType = "",
            clientFeatures = {},
            clientDataStructure = {},
        } = this.props;

        return (
            <AddRecordLinkage
                searchLinkageFullUrl={this.props.searchUrlFull}
                searchLinkageUrl={this.props.searchUrl}
                handleConverting={(data) => this.handleLinkConverting(data)}
                handleChange={(value) => this.handleAddRecordLinkResult(value)}
                data={this.state.data}
                options={options}
                category={category}
                recordType={recordType}
                setError={this.setError.bind(this)}
                clientFeatures={clientFeatures}
                clientDataStructure={clientDataStructure}
            />
        );
    }

    handleAddNewRecordLinkNotification() {
        let addRecordLinkNotification = {
            html: this.renderAddRecordLinkNotificationHtml(),
            disabled: false,
            onClick: this.disableAddRecordLinkNotification.bind(this),
            confirm: this.confirmAddRecordLinkNotification.bind(this),
            okText: "Link + Close",
        };

        this.setState({ notification: addRecordLinkNotification });
    }

    handleInheritRecordNotification() {
        let addRecordLinkNotification = {
            title: "Inherit Record Data",
            html: (
                <InheritToLinkedRecords
                    options={this.props.options}
                    linkages={this.props.data}
                    clientFeatures={this.props.clientFeatures}
                    clientDataStructure={this.props.clientDataStructure}
                    recordCategory={this.props.category}
                    handleChange={(records) => {
                        this.setState({
                            inheritanceData: {
                                targetRecords: records,
                                filters: [],
                                sourceRecord: this.props.originRecordData || {},
                            },
                        });
                    }}
                />
            ),
            disabled: false,
            onClick: this.disableAddRecordLinkNotification.bind(this),
            confirm: () => {
                const { inheritanceData = {} } = this.state;
                if (
                    inheritanceData.targetRecords &&
                    !_.isEmpty(inheritanceData.targetRecords)
                ) {
                    this.handleInheritanceFiltersNotification();
                } else {
                    this.disableAddRecordLinkNotification();
                }
            },
            okText: "Propagate Data",
        };

        this.setState({ notification: addRecordLinkNotification });
    }

    handleInheritanceFiltersNotification() {
        const { clientDataStructure, originRecordData = {} } = this.props;

        const { inheritanceData = {} } = this.state;

        const targetCategory =
            inheritanceData.targetRecords &&
            Array.isArray(inheritanceData.targetRecords) &&
            inheritanceData.targetRecords.length > 0
                ? inheritanceData.targetRecords[0].category
                : "";

        const inheritanceConfiguration = FetchConfig({
            clientDataStructure,
            targetCategory,
            sourceCategory: originRecordData.category,
        });

        let notification = {
            title:
                inheritanceConfiguration.notificationTitle ||
                "Attribute Inheritance",
            html: (
                <DataInheritance
                    sourceRecord={originRecordData}
                    configuration={inheritanceConfiguration}
                    handleChange={(filters) => {
                        let inheritanceData = deepcopy(
                            this.state.inheritanceData,
                        );
                        inheritanceData.filters = filters;
                        this.setState({ inheritanceData });
                    }}
                    cmsData={this.props.cmsData}
                />
            ),
            disabled: false,
            confirm: () => {
                this.props.handleInheritanceChange(
                    this.state.inheritanceData || {},
                );
                this.disableAddRecordLinkNotification();
            },
            onClick: this.disableAddRecordLinkNotification.bind(this),
        };

        this.setState({ notification });
    }

    handleConvertRecordNotification() {
        const {
            clientDataStructure: dataStructure = {},
            originRecordData = {},
            category: sourceCategory,
        } = this.props;

        const convertConfig = FetchConvertConfig({
            dataStructure,
            sourceCategory,
        });
        let convertNotification = {
            title: convertConfig.notificationTitle || "Convert",
            html: (
                <RecordConverter
                    configuration={convertConfig}
                    sourceRecordData={originRecordData}
                    handleChange={(filters) => {
                        this.setState({
                            convertingData: {
                                meta_id: originRecordData.meta_id,
                                filters,
                                delete_source: !!convertConfig.deleteSource,
                                target_category: convertConfig.targetCategory,
                            },
                        });
                    }}
                />
            ),
            disabled: false,
            confirm: () => {
                this.props.handleConvertingChange(
                    this.state.convertingData || {},
                );
                this.disableAddRecordLinkNotification();
            },
            onClick: this.disableAddRecordLinkNotification.bind(this),
        };

        this.setState({ notification: convertNotification });
    }

    handleAddRecordLinkResult(value) {
        this.setState({ tmpCreatedRecordLinks: value });
    }

    disableAddRecordLinkNotification() {
        this.setState({ notification: {} });
    }

    confirmAddRecordLinkNotification() {
        if (
            this.state.tmpCreatedRecordLinks &&
            Array.isArray(this.state.tmpCreatedRecordLinks) &&
            this.state.tmpCreatedRecordLinks.length > 0
        ) {
            let data = deepcopy(this.state.data || []);
            data.push(...this.state.tmpCreatedRecordLinks);

            data = data.filter((item, index) => {
                let record = data.find(
                    (value) => value.meta_id === item.meta_id,
                );
                return data.indexOf(record) === index;
            });

            this.props.handleFormChange(data);
            if (this.state.linkConverting.enabled) {
                this.props.handleConvertingChange({
                    filters: [],
                    meta_id: this.props.meta_id,
                    delete_source: this.state.linkConverting.deleteSource,
                    target_category: this.state.linkConverting.targetCategory,
                    target_parent_record_meta_id:
                        this.state.linkConverting.targetParentRecordMetaId,
                });
            }
            this.setState({ data: data });
        }

        this.setState({ notification: {} });
    }

    setError(error) {
        this.setState({ errorMessage: error });
    }

    handleNotificationClose() {
        this.setError("");
        this.state.notification.onClick();
    }

    getLineButtons() {
        const {
            clientDataStructure = {},
            clientFeatures,
            cmsData = {}
        } = this.props;

        let btns = [
            {
                title: "Add New Linkage",
                id: "meta-linkage-add-btn",
                onClick: this.handleAddNewRecordLinkNotification.bind(this),
                disabled: !this.userPermissions.create,
            },
        ];

        const availableCategoriesForInheritance = FetchCategoryList({
            clientFeatures,
            clientDataStructure,
            linkages: this.props.data,
            sourceCategory: this.props.category,
            options: this.props.options || {},
        });

        if (!_.isEmpty(availableCategoriesForInheritance)) {
            btns.unshift({
                title: "Propagate Record Data",
                id: "meta-inherit-add-btn",
                onClick: this.handleInheritRecordNotification.bind(this),
                disabled: !this.userPermissions.inheritance,
            });
        }

        if (
            FetchConvertConfig({
                dataStructure: clientDataStructure,
                sourceCategory: this.props.category,
            })
        ) {
            btns.unshift({
                title: "Convert pilot to show",
                id: "meta-convert-pilot-to-show",
                onClick: this.handleConvertRecordNotification.bind(this),
                disabled: !this.userPermissions.update,
            });
        }

        return btns;
    }

    renderContent() {
        let data = deepcopy(this.state.data || []);

        const { disabled, canCreateNew = true, clientFeatures = {} } = this.props;

        const { errorMessage = "" } = this.state;

        const { linkagesVersion = 'v1' } = clientFeatures;
        
        const categoriesWithData = this.getCategoriesWithData();

        return (
            <div>
                {(disabled && _.isEmpty(data) && _.isEmpty(this.disabledData)) && (
                    <PlaceholderText
                        copy={this.props.copy || {}}
                        styles={{ marginTop: "1.5rem" }}
                    />
                )}

                {!disabled && (
                    <AddNewButtonWithLineMultiple
                        buttons={this.getLineButtons()}
                        disabled={!canCreateNew}
                    />
                )}

                {(linkagesVersion == 'v2' && (!_.isEmpty(data) || !_.isEmpty(this.disabledData))) && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: !disabled && "1.5rem",
                        }}
                    >
                        <Button
                            onClick={this.expandAllOnClick}
                            sx={{
                                textTransform: "none",
                                display: "flex",
                                gap: "0.625rem",
                                color: "rgba(110, 110, 110, 0.87)",
                                ":hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                            }}
                            className="expand-linkages"
                        >
                            {this.state.expanded.length !== categoriesWithData.length ? "Expand All" : "Collapse All"}
                            <img src={`${Settings.images.path}/svg/${this.state.expanded.length == categoriesWithData.length ? "arrows-expand-all" : "arrows-collapse-all"}.svg`}/>
                        </Button>
                    </div>
                )}

                {this.renderItems({
                    categories: this.categories,
                    disabled,
                    data,
                })}

                {!this.state.notification.disabled && (
                    <Notification
                        title={
                            this.state.notification.title || "Add New Linkage"
                        }
                        intercationStatus="info"
                        errorMessage={errorMessage}
                        description={
                            this.state.notification.description || false
                        }
                        html={this.state.notification.html}
                        background={"white"}
                        disabled={this.state.notification.disabled || false}
                        onClick={this.handleNotificationClose.bind(this)}
                        confirm={this.state.notification.confirm}
                        okText={this.state.notification.okText || undefined}
                        datalist={this.state.notification.datalist || undefined}
                        interactionButtonClassName={
                            this.interactionButtonClassName
                        }
                        cancelText="Cancel"
                        keyPressEnterDisabled={true}
                        className={this.notificationClass}
                        titlePadding={this.state.notification.titlePadding}
                        contentNoPadding={
                            this.state.notification.contentNoPadding
                        }
                    />
                )}
            </div>
        );
    }
}
