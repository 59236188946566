import { 
    TITLES, 
    DESCRIPTIONS, 
    IDS, 
    ASSETS,
    RIGHTS, 
    ORIGINATION,
    SCHEDULING,
    SCHEDULING_PLATFORMS,
    CUSTOM_FIELDS,
    NUMBERING,
    DESCRIPTORS,
    KNOWN_AS
} from '../../models/RecordBuilder/index'
import { envTest } from "../Environment"
import builder from '../../config/builder'
import _ from "lodash"

type Props = {
    page: string
    clientFeatures: any
}

export const IsVersionV1 = (clientFeatures) => _.get(clientFeatures, 'recordBuilder.recordBuilderVersion') == 'v1'

export const IsEnabled = (clientFeatures: any) => {
    const { recordBuilder = {} } = clientFeatures
    const { enabled = false } = recordBuilder
    
    if (!envTest && !builder.url) {
        return false;
    }
    
    return enabled
}

const IsActiveModel = ({ page, clientFeatures }: Props) => {

    const { recordBuilder = {} } = clientFeatures

    const {
        enabled,
        origination,
        customFields,
        ids,
        titles,
        descriptions,
        assets,
        scheduling,
        rights,
        schedulingPlatforms,
        numbering,
        descriptors,
        knownAs,
    } = recordBuilder

    if (!enabled) {
        return false;
    }

    if (!envTest && !builder.url) {
        return false;
    }

    switch (page) {
        case IDS:
            return ids

        case TITLES:
            return titles

        case DESCRIPTIONS:
            return descriptions

        case ASSETS:
            return assets

        case SCHEDULING:
            return scheduling

        case RIGHTS:
            return rights

        case SCHEDULING_PLATFORMS:
            return schedulingPlatforms

        case ORIGINATION:
            return origination

        case CUSTOM_FIELDS:
            return customFields

        case NUMBERING:
            return numbering

        case DESCRIPTORS:
            return descriptors
        
        case KNOWN_AS:
            return knownAs
    }

    return false;
}

export default IsActiveModel;
