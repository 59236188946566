import React from "react";
import { extendObservable, makeObservable, action } from "mobx";
import { AncillariesStore } from "./ancillariesStore";
import { AncillariesCreateStore } from "./ancillariesCreateStore";
import { AncillariesUpdateStore } from "./ancillariesUpdateStore"
import { OptionsStore } from "./optionsStore";
import { ValidationStore } from "./validationStore";
import { PermissionsStore } from "./permissionsStore";
import { CmsInterface } from "../../../components/Global/interfaces";
import { RootStoreInitializeInterface } from "../interfaces"
import { rootStoreInitialState } from "./initialState"

export class RootStore {
    rootStore: RootStore;
    ancillariesStore: AncillariesStore;
    ancillariesCreateStore: AncillariesCreateStore;
    ancillariesUpdateStore: AncillariesUpdateStore;
    optionsStore: OptionsStore;
    validationStore: ValidationStore;
    permissionsStore: PermissionsStore;

    cmsData: CmsInterface | {}
    disabled: boolean;
    category: string

    constructor() {
        this.rootStore = this
        this.ancillariesStore = new AncillariesStore(this)
        this.ancillariesCreateStore = new AncillariesCreateStore(this)
        this.ancillariesUpdateStore = new AncillariesUpdateStore(this)

        this.optionsStore = new OptionsStore(this)
        this.validationStore = new ValidationStore(this)
        this.permissionsStore = new PermissionsStore(this)

        extendObservable(this, rootStoreInitialState);

        makeObservable(this, {
            setDisabled: action,
            setCategory: action,
        });
    }

    initialize({
        globalState,
        policyType,
        pathField,
        category
    }: RootStoreInitializeInterface): void {
        this.setCmsData(globalState?.cmsData)
        this.setCategory(category)
        
        this.permissionsStore.initialize(globalState?.userPermissions, policyType, pathField)
    }

    get isEditable(): boolean {
        return this.cmsData?.clientFeatures?.ancillariesEditable
    }

    setCmsData(cmsData: CmsInterface | {}): void {
        this.cmsData = cmsData;
    }

    setCategory(category: string): void {
        this.category = category
    }

    setDisabled(disabled: boolean): void {
        this.disabled = disabled;
    }

    handleResetForm(): void {
        this.ancillariesCreateStore.setType(null)
        this.ancillariesUpdateStore.setType(null)

        this.ancillariesStore.setFormData({})
        this.ancillariesStore.setDefaultFormData({})
        this.ancillariesStore.setParentOptions(null)

        this.optionsStore.reset()
        this.validationStore.reset()
    }
}

const StoresContext = React.createContext(new RootStore());
export const useStores = () => React.useContext(StoresContext);
