import React, { useState, useRef, useEffect } from "react";
import { isString, isEmpty, isEqual, isObject, cloneDeep } from "lodash";
import "./styles/_styles.scss";
import {
    SortableContainer,
    SortableElement,
    arrayMove,
} from "react-sortable-hoc";
import Input from "../Input/basic";
import DatalistItems from "../DatalistItems";
import OutsideClick from "../OutsideClick";
import HandleSearch from "./src/HandleSearch";
import KeywordLabel from "../KeywordLabel";
import Settings from "../Settings";
import NoneEntered from "../KeywordLabel/noneEntered";
import { getPipeDividedPasteData } from "../Helpers/copyPaste"

const AutocompleteInput = (props) => {
    const {
        label,
        placeholder,
        id,
        value,
        disabled,
        displayNoneEntered = false,
        styles = {},
        inputStyles = {},
        handleListSelection = () => {},
        handleOnPaste = () => {},
        copyPasteEnabled,
        className,
        searchKey,
        apiUrls = {},
        simpleInput,
        searchUrl,
        searchOnClick,
        setFocusOnMount,
        staticDatalist,
        datalistMultiDimensional,
        datalistMultiDimensionalUseSingle,
        openOnClick,
        handleChange,
        filterStaticDataList,
        inputName,
        datalistId,
        useEnterButtonOnSelect,
        validateEnterButton,
        bubbleOnSelect,
        keywordLabel = {},
        callBackOnDelete = () => {},
        limitResults,
        keepSelected,
        selectOnlyItem,
        selectOnlyItemObject,
        multiDimensionalSelectionPreset = {},
        openList = false,
        bubbleListData,
        displayInput = true,
        orderBubbles,
        filterKey,
        filterData,
        handleMove = () => {},
        handleSetCheck = () => {},
        autoFocus = false,
        openStaticDataListOnFocus = false,
        itemTextKey,
        chevronBackground = true,
        limit = "",
        noMatchText = null,
        secondaryLabel,
        loading
    } = props;

    const {
        background,
        color,
        postfix,
        deleteWhite = true,
        deleteGrey,
    } = keywordLabel;

    const [displayDataList, setDisplayDataList] = useState(openList);
    const [fetchingDatalist, setFetchingDatalist] = useState(false);
    const [createNew, setCreateNew] = useState(false);
    const [searchValue, setSearchValue] = useState(value);
    const [datalist, setDatalist] = useState(staticDatalist || []);
    const [stamp, setStamp] = useState("");
    const ref = useRef();
    const shouldDisplayChevron = openOnClick || searchOnClick;
    const datalistToUse = staticDatalist || datalist;
    const displayBubbles = bubbleListData && !isEmpty(bubbleListData);
    const [updatingData, setUpdatingData] = useState([]);
    const [valueExists, setValueExists] = useState(false);

    OutsideClick(ref, () => {
        setDisplayDataList(false);
    });

    useEffect(() => {
        if (
            selectOnlyItem &&
            !value &&
            Array.isArray(staticDatalist) &&
            staticDatalist.length == 1
        ) {
            const first = staticDatalist[0];
            const selectedValue =
                selectOnlyItemObject && typeof first === "object"
                    ? first
                    : first.value || first.text || first;
            handleListSelection(selectedValue);
        }
    }, []);

    useEffect(() => {
        if (
            !isEmpty(bubbleListData) &&
            !isEmpty(updatingData) &&
            isEqual(bubbleListData, updatingData)
        ) {
            setUpdatingData([]);
        }
    });

    useEffect(() => {
        if (
            autoFocus &&
            !isEmpty(staticDatalist) &&
            !displayDataList &&
            openStaticDataListOnFocus
        ) {
            setDisplayDataList(true);
        }
    }, [autoFocus]);

    const handleOnSelection = (entry) => {
        let item = cloneDeep(entry);

        if (validateEnterButton) {
            if (isString(item)) {
                if (datalistToUse.length) {
                    item = datalistToUse[0];
                }
            }
        }

        setCreateNew(false);
        setDisplayDataList(false);
        handleListSelection(item);

        if (!keepSelected) {
            setSearchValue("");
        }

        if (keepSelected) {
            setSearchValue(itemTextKey ? item[itemTextKey] : item);
        }
    };

    const handleOnSelectCreateNew = (entry) => {
        setCreateNew(false);
        setDisplayDataList(false);
        handleListSelection(entry);
        if (!keepSelected) {
            setSearchValue("");
        }
        if (keepSelected) {
            setSearchValue(itemTextKey ? item[itemTextKey] : item);
        }
    };

    const handleSetDataList = (dataList) => {
        const result =
            filterKey && Array.isArray(filterData) && !isEmpty(filterData)
                ? dataList.filter(
                      (item) =>
                          !filterData.find(
                              (it) => it[filterKey] == item[filterKey],
                          ),
                  )
                : dataList;

        setDatalist(result);
    };

    const handleSearch = (value, limit) => {
        setSearchValue(value);
        setDisplayDataList(true);
        setCreateNew(props.createNew);

        setValueExists(handleSetCheck(value));

        if (!staticDatalist) {
            setDatalist([]);

            HandleSearch({
                value,
                setSearchValue,
                setFetchingDatalist,
                stamp,
                setStamp,
                setDatalist: (dataList) => handleSetDataList(dataList),
                searchKey,
                apiUrls,
                setDisplayDataList,
                searchUrl,
                limit,
            });
        }

        if (typeof handleChange === "function") {
            handleChange(value);
        }
    };

    const handleOnClick = () => {
        if (searchValue) setDisplayDataList(true);

        if (searchOnClick) {
            handleSearch(searchValue, limit);
        }

        if (openOnClick && !isEmpty(staticDatalist)) {
            setDisplayDataList(true);
        }
        if (datalistMultiDimensional && displayDataList) {
            setDisplayDataList(false);
        }
    };

    const handleBubbleDelete = (item) => {
        callBackOnDelete(item);
        setSearchValue("");
    };

    const handlePasteEvent = (e) => {
        if (!copyPasteEnabled) {
            return
        }

        const items = getPipeDividedPasteData(e)

        if (items && Array.isArray(items)) {
            handleOnPaste(items)
        }
    }

    const RenderLabel = (props) => (
        <KeywordLabel
            title={props.title}
            postfix={props.postfix}
            weighting={false}
            background={background || "grey"}
            color={color || "white"}
            deleteWhite={deleteWhite}
            deleteGrey={deleteGrey}
            margin="0.71875rem 0.5rem 0px 0px"
            delete
            disabled={disabled}
            handleDelete={() => handleBubbleDelete(props.title)}
        />
    );

    const displayBubbleTag = bubbleOnSelect && value;

    const sortableOptions = {
        axis: "xy",
        lockToContainerEdges: true,
        lockOffset: "0%",
        helperClass: "sortableHelper",
        distance: 2,
    };

    let bubbleListDataToUse = bubbleListData;
    if (!isEmpty(updatingData)) {
        bubbleListDataToUse = updatingData;
    }

    const SortableList = SortableContainer(() => {
        return (
            <div
                style={{
                    display: "inline-block",
                    verticalAlign: "top",
                }}
            >
                {bubbleListDataToUse.map((item, index) => {
                    const sortableItem = {
                        item,
                        index,
                    };

                    return (
                        <SortableItem
                            sortableItem={sortableItem}
                            key={`item-${index}`}
                            index={index}
                        />
                    );
                })}
            </div>
        );
    });

    const SortableItem = SortableElement((props) => {
        const { sortableItem = {} } = props;

        const { item } = sortableItem;

        return <RenderLabel title={item.text || item.value || item} />;
    });

    const handleArrayMove = (data) => {
        if (isObject(data)) {
            const updatedState = arrayMove(
                bubbleListDataToUse,
                data.oldIndex,
                data.newIndex,
            );
            setUpdatingData(updatedState);
            handleMove(updatedState);
        }
    };

    const showNoneEnteredBubble = displayNoneEntered && disabled;

    return (
        <div
            className={`meta-autocomplete-input ${className}`}
            ref={ref}
            style={styles}
        >
            {label && <span style={Settings.components.label}>{label}</span>}

            {secondaryLabel && (
                <span style={Settings.components.secondaryLabel}>
                    {secondaryLabel}
                </span>
            )}

            {displayBubbles && Array.isArray(bubbleListDataToUse) && (
                <>
                    {orderBubbles && (
                        <SortableList
                            onSortEnd={(data) => handleArrayMove(data)}
                            {...sortableOptions}
                        />
                    )}

                    {!orderBubbles &&
                        bubbleListDataToUse.map((item, index) => (
                            <RenderLabel
                                title={item.text || item.value || item}
                                key={index}
                            />
                        ))}
                </>
            )}

            {!displayInput &&
                bubbleListDataToUse &&
                isEmpty(bubbleListDataToUse) && <NoneEntered />}

            {displayNoneEntered && isEmpty(value) && disabled && (
                <NoneEntered />
            )}

            {!simpleInput &&
                !displayBubbleTag &&
                displayInput &&
                !showNoneEnteredBubble && (
                    <Input
                        placeholder={placeholder}
                        id={id}
                        className={className}
                        name={inputName}
                        value={
                            searchValue && searchValue.text
                                ? searchValue.text
                                : searchValue
                        }
                        disabled={disabled}
                        recordBasic
                        borderHiddenPadding
                        onClick={disabled ? () => {} : () => handleOnClick()}
                        chevronBackground={
                            !disabled &&
                            shouldDisplayChevron &&
                            chevronBackground
                        }
                        chevronbackgroundLightDark={
                            !disabled && shouldDisplayChevron
                        }
                        loading={loading || fetchingDatalist}
                        handleChange={(value) => handleSearch(value, limit)}
                        textOverflow="ellipsis"
                        styles={{
                            ...inputStyles,
                            transition: 0,
                        }}
                        useEnterButtonOnSelect={useEnterButtonOnSelect}
                        handleEnter={(item) => handleOnSelection(item)}
                        color={props.color}
                        focusColor={props.focusColor}
                        autoFocus={autoFocus}
                        noDelay={props.noDelay}
                        onPaste={(e) => handlePasteEvent(e)}
                    />
                )}

            {simpleInput && !displayBubbleTag && displayInput && (
                <Input
                    placeholder={placeholder}
                    id={id}
                    value={value}
                    disabled={disabled}
                    recordBasic
                    borderHiddenPadding
                    handleChange={(item) => handleOnSelection(item)}
                    textOverflow="ellipsis"
                    setFocusOnMount={setFocusOnMount}
                    setFocusWithId
                    styles={inputStyles}
                    useEnterButtonOnSelect={useEnterButtonOnSelect}
                    onPaste={(e) => handlePasteEvent(e)}
                />
            )}

            {displayBubbleTag && (
                <RenderLabel
                    title={value.text || value.name || value.value || value}
                    postfix={postfix}
                />
            )}

            <DatalistItems
                value={searchValue}
                datalist={datalistToUse}
                onSelection={(item) => handleOnSelection(item)}
                onSelectCreateNew={(item) => handleOnSelectCreateNew(item)}
                filterStaticDataList={filterStaticDataList}
                display={displayDataList}
                createNew={createNew}
                datalistMultiDimensional={datalistMultiDimensional}
                datalistMultiDimensionalUseSingle={
                    datalistMultiDimensionalUseSingle
                }
                datalistId={datalistId}
                limitResults={limitResults}
                multiDimensionalSelectionPreset={
                    multiDimensionalSelectionPreset
                }
                valueExists={valueExists}
                noMatchText={noMatchText}
            />
        </div>
    );
};

export default AutocompleteInput;
