import { extendObservable, makeObservable, action } from "mobx";
import update from "react-addons-update";
import { RootStore } from "./rootStore"
import { AncillariesInterface } from "../../../models/Record/ancillaries"
import { ParentOptions } from "../interfaces"
import { typeCategory, typeSubCategory } from "../config/fields"
import { typeAncillary } from "../config/fields";

const initialState = {
    type: null
}

export class AncillariesUpdateStore {
    rootStore: RootStore;

    type: string | null

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        extendObservable(this, {
            type: null
        });

        makeObservable(this, {
            reset: action,

            setType: action,
            handleUpdateAncillary: action,
            handleUpdateCategory: action,
            handleUpdateSubCategory: action,
            handleUpdateAncillaryType: action,
            handleEditClick: action,
            handleDeleteClick: action,
            handleDeleteAncillary: action,
            handleDeleteCategory: action,
            handleDeleteSubCategory: action,
        });
    }

    reset() {
        Object.keys(initialState).forEach(
            (key) => (this[key] = initialState[key]),
        );
    }

    setType(type: string | null) {
        this.type = type
    }

    handleEditClick(type: string, parentOptions: ParentOptions = null): void {
        if (!parentOptions) {
            return
        }

        this.setType(type)

        this.rootStore.optionsStore.handleSetCoreFormFields(type, false)
        this.rootStore.ancillariesStore.setParentOptions(parentOptions)

        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(parentOptions?.ancillaryUuuid)
        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(ancillaryIndex, parentOptions?.categoryUuuid)
        const subCategoryIndex: number = this.rootStore.ancillariesStore.getSubCategoryIndex(ancillaryIndex, categoryIndex, parentOptions?.subCategoryUuuid)

        let defaultFormData: AncillariesInterface | {} = {}

        switch (type) {
            case typeAncillary:
                defaultFormData = this.rootStore.ancillariesStore.data[ancillaryIndex] || {}
                break;

            case typeCategory:
                defaultFormData = this.rootStore.ancillariesStore.data[ancillaryIndex]?.children[categoryIndex] || {}
                break;

            case typeSubCategory:
                defaultFormData = this.rootStore.ancillariesStore.data[ancillaryIndex]?.children[categoryIndex]?.children[subCategoryIndex] || {}
                break;

            default:
                break;
        }

        this.rootStore.ancillariesStore.setDefaultFormData(defaultFormData)
    }

    handleDeleteClick(type: string, parentOptions: ParentOptions = null): void {
        if (!parentOptions) {
            return
        }

        this.rootStore.ancillariesStore.setParentOptions(parentOptions)

        switch (type) {
            case typeAncillary:
                this.handleDeleteAncillary()
                break;

            case typeCategory:
                this.handleDeleteCategory()
                break;

            case typeSubCategory:
                this.handleDeleteSubCategory()
                break;

            default:
                break;
        }

        this.rootStore.handleResetForm()
    }

    handleUpdateAncillaryType(): void {
        switch (this.type) {
            case typeAncillary:
                this.handleUpdateAncillary()
                break;

            case typeCategory:
                this.handleUpdateCategory()
                break;

            case typeSubCategory:
                this.handleUpdateSubCategory()
                break;

            default:
                break;
        }

        this.rootStore.handleResetForm()
    }

    handleUpdateAncillary(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        if (ancillaryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    $set: {
                        ...this.rootStore.ancillariesStore.data[ancillaryIndex],
                        ...this.rootStore.ancillariesStore.formData
                    }
                }
            })

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }

    handleUpdateCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(
            ancillaryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.categoryUuuid
        )

         if (ancillaryIndex !== -1 && categoryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        [categoryIndex]: {
                            $set: {
                                ...this.rootStore.ancillariesStore.data[ancillaryIndex]?.children[categoryIndex] || {},
                                ...this.rootStore.ancillariesStore.formData
                            }
                        }
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
         }
    }

    handleUpdateSubCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(
            ancillaryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.categoryUuuid
        )

        const subCategoryIndex: number = this.rootStore.ancillariesStore.getSubCategoryIndex(
            ancillaryIndex, 
            categoryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.subCategoryUuuid
        )

        if (ancillaryIndex !== -1 && categoryIndex !== -1 && subCategoryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        [categoryIndex]: {
                            children: {
                                [subCategoryIndex]: {
                                    $set: {
                                        ...this.rootStore.ancillariesStore.data[ancillaryIndex]?.children[categoryIndex]?.children[subCategoryIndex] || {},
                                        ...this.rootStore.ancillariesStore.formData
                                    }
                                }
                            }
                        }
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }

    handleDeleteAncillary(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        if (ancillaryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    deleted: {
                        $set: true
                    }
                }
            })

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }

    handleDeleteCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(
            ancillaryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.categoryUuuid
        )

         if (ancillaryIndex !== -1 && categoryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        [categoryIndex]: {
                            deleted: {
                                $set: true
                            }
                        }
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
         }
    }

    handleDeleteSubCategory(): void {
        const ancillaryIndex: number = this.rootStore.ancillariesStore.getAncillaryIndex(
            this.rootStore.ancillariesStore.parentOptions?.ancillaryUuuid
        )

        const categoryIndex: number = this.rootStore.ancillariesStore.getCategoryIndex(
            ancillaryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.categoryUuuid
        )

        const subCategoryIndex: number = this.rootStore.ancillariesStore.getSubCategoryIndex(
            ancillaryIndex, 
            categoryIndex, 
            this.rootStore.ancillariesStore.parentOptions?.subCategoryUuuid
        )

        if (ancillaryIndex !== -1 && categoryIndex !== -1 && subCategoryIndex !== -1) {
            const data = update(this.rootStore.ancillariesStore.data, {
                [ancillaryIndex]: {
                    children: {
                        [categoryIndex]: {
                            children: {
                                [subCategoryIndex]: {
                                    deleted: {
                                        $set: true
                                    }
                                }
                            }
                        }
                    }
                }
            });

            this.rootStore.ancillariesStore.handleDataChange(data)
        }
    }
}