import { Box, Grid, TextFieldProps } from "@mui/material";
import _, { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CORE_FORM_STRING_DEFAULT_VALUE } from "../../constants";
import { CoreFormLayout, CoreFormPath } from "../../types";
import { CoreFormLabel, CoreFormLabelProps } from "../styled/CoreForm.label";
import { CoreFormCaption } from "../styled/CoreFormCaption";
import { CoreFormFieldError } from "../styled/CoreFormFieldError";
import { StyledTextField } from "../styled/CoreFormText.field"
import NoneElement from "./NoneElement";
import { CoreTooltip } from "../../../Tooltip/CoreTooltip";
import { getStringLengthPx } from "../../../../components/Helpers/getStringLengthPx";

export type CoreInputTextProps<T> = CoreFormPath<T> &
    CoreFormLayout & { registerDisabled?: boolean } & Omit<
        TextFieldProps,
        "error"
    > & {
        path: string;
        disabled?: boolean;
        labelProps?: CoreFormLabelProps;
        caption?: React.ReactNode;
        customStyles?: React.CSSProperties;
        textarea?: boolean
        showLongTextTooltip?: boolean
        error?: string
    };

export function CoreInputText<T>({
    path,
    labelProps,
    caption,
    disabled = false,
    customStyles = {},
    layout = "vertical",
    registerDisabled = false,
    textarea = false,
    showLongTextTooltip = false,
    error,
    ...muiTextFieldProps
}: CoreInputTextProps<T>) {
    const id = `core-text-field-${path}`
    const formMethods = useFormContext<T>();
    const value = formMethods?.watch(path);
    const errorMessage = _.get(formMethods?.formState.errors, path)?.message || error;

    const [showExtraTextTooltip, setShowExtraTextTooltip] = useState<boolean>(false)

    useEffect(() => {
        const selectElementWidth = document.getElementById(id)?.offsetWidth

        if (disabled && !textarea && showLongTextTooltip && getStringLengthPx(value) > selectElementWidth) {
            setShowExtraTextTooltip(true)
        }
    }, [])
    
    let registerOptions = {};

    if (muiTextFieldProps.type === "number") {
        registerOptions = {
            // valueAsNumber: muiTextFieldProps.type === "number",
            setValueAs: (v) => Number(v),
            // setValueAs: (v) => (isEmpty(v) ? undefined : parseInt(v, 10)),
        };
    }

    const labelElement = <CoreFormLabel {...labelProps} />;
    let registerMethods = formMethods?.register(path, registerOptions);
    if (registerDisabled) {
        registerMethods = {};
    }

    const fieldElement = (
        <CoreTooltip title={(showExtraTextTooltip && !isEmpty(value)) ? value : null} >
            <StyledTextField
                id={id}
				data-testid={`core-text-field-${path}`}
                disabled={disabled}
                multiline={textarea}
                minRows={1}
                maxRows={textarea ? 5 : 1}
                // defaultValue={CORE_FORM_STRING_DEFAULT_VALUE}
                error={!_.isEmpty(errorMessage)}
                classes={{ 
                    root: textarea ? 'core-text-area-root' : 'core-text-root',
                }}
                sx={{
                    ...customStyles,
                }}
                inputProps={{
                    "data-testid": `input-${path}`,
                }}
                {...muiTextFieldProps}
                {...registerMethods}
            />
        </CoreTooltip>
        
    );

    const element = (
        <>
            {((!value || value == '') && disabled)
                ? (<NoneElement />)
                : fieldElement
            }
        </>
    )

    const elements =
        layout === "vertical" ? (
            <>
                {labelProps && labelElement}
                {element}
            </>
        ) : (
            <Grid container alignItems="center">
                {labelProps ? (
                    <Grid item xs={4}>
                        {labelElement}
                    </Grid>
                ) : null}
                <Grid item xs={labelProps ? 8 : 12}>
                    {element}
                </Grid>
            </Grid>
        );

    return (
        <Box>
            {elements}
            {caption && !errorMessage && (
                <CoreFormCaption>{caption}</CoreFormCaption>
            )}
            {errorMessage && !disabled && (
                <CoreFormFieldError path={path} message={errorMessage} />
            )}
        </Box>
    );
}

CoreInputText.defaultProps = {
    caption: null,
};
