import { FieldInterface, AncillaryCategoryFieldsInterface } from "../interfaces"

export const typeAncillary = 'ancillary'
export const typeCategory = 'category'
export const typeSubCategory = 'sub-category'

export const typeNames = {
    [typeAncillary]: 'Ancillary',
    [typeCategory]: 'Category',
    [typeSubCategory]: 'Sub Category'
}

export const fields: FieldInterface[] = [
    {
        key: 'meta_id',
        type: 'string',
        name: "Fabric {type} ID"
    },
    {
        key: 'name',
        type: 'string',
        name: "{type} Name",
    },
    {
        name: 'External ID',
        key: 'external_id',
        type: 'string'
    },
    {
        key: 'end_date',
        type: 'string',
        name: "End Date"
    }
]

export const ancillaryCategoryFields: AncillaryCategoryFieldsInterface = {
    [typeAncillary]: [
        'meta_id',
        'name',
        'external_id',
        'end_date'
    ],
    [typeCategory]: [
        'meta_id',
        'name',
        'external_id'
    ],
    [typeSubCategory]: [
        'meta_id',
        'name',
        'external_id'
    ]
}

export const ancillaryRecordBuilderFields: AncillaryCategoryFieldsInterface = {
    [typeAncillary]: [
        'meta_id',
        'ancillary_name',
        'external_id',
        'end_date'
    ],
    [typeCategory]: [
        'meta_id',
        'category_name',
        'external_id'
    ],
    [typeSubCategory]: [
        'meta_id',
        'sub_category_name',
        'external_id'
    ]
}

export const getRecordBuilderFields = (date_format: string, create: boolean): any[] => {
    let result = [
        {
            key: "meta_id",
            displayName: "Fabric Ancillary ID",
            dataType: "String",
            options: {
                editable: {
                    enabled: false,
                },
                mandatory: {
                    enabled: false,
                },
                validation: {
                    required: {
                        enabled: false,
                    },
                    readOnly: {
                        enabled: true,
                    }
                }
            }
        },
        {
            key: "name",
            filterKey: 'ancillary_name',
            displayName: "Ancillary Name",
            dataType: "String",
            options: {
                editable: {
                    enabled: true,
                },
                mandatory: {
                    enabled: true,
                },
                validation: {
                    required: {
                        enabled: true,
                    }
                }
            }
        },
        {
            key: "name",
            filterKey: 'category_name',
            displayName: "Category Name",
            dataType: "String",
            options: {
                editable: {
                    enabled: true,
                },
                mandatory: {
                    enabled: true,
                },
                validation: {
                    required: {
                        enabled: true,
                    }
                }
            }
        },
        {
            key: "name",
            filterKey: 'sub_category_name',
            displayName: "Sub Category Name",
            dataType: "String",
            options: {
                editable: {
                    enabled: true,
                },
                mandatory: {
                    enabled: true,
                },
                validation: {
                    required: {
                        enabled: true,
                    }
                }
            }
        },
        {
            key: "external_id",
            displayName: "External ID",
            dataType: "String",
            options: {
                editable: {
                    enabled: true,
                },
                mandatory: {
                    enabled: true,
                },
                validation: {
                    required: {
                        enabled: true,
                    }
                }
            }
        },
        {
            key: "end_date",
            displayName: "End Date",
            dataType: "String",
            options: {
                editable: {
                    enabled: true,
                    inputType: "Date",
                    dateTime: {
                        format: date_format || "YYYY-DD-MM"
                    }
                },
                mandatory: {
                    enabled: true,
                },
                validation: {
                    required: {
                        enabled: true,
                    }
                }
            }
        }
    ]

    if (create) {
        result.shift()
    }

    return result
}