import { action, extendObservable, makeObservable, observable } from "mobx";
import { RootStore } from "./rootStore";
import { selectedCreditsObject } from "../config";
import {
    CreditInterface,
    CreditListInterface,
    CreditsInitialStateInterface,
    CreditRankMap,
    DataTablePayload,
    SelectedCredits,
    formValidationObject,
    CreditFormProperties,
    ContributorFormProperties,
    CreditDeleteFormProperties,
    NameComponents
} from "../credits.interface";
import {
    getActiveCredits,
    getInactiveCredits,
} from "../services/TableViewService";
import {
    setCreditsLocalId,
    attachSingleCreditToList,
    attachGroupCreditsToList,
    detachGroupCreditsFromList,
    deleteCredits,
    setCreditsRanking,
    syncCreditsOnRankReorder,
    matchCreditsOnContributorUpdate,
    assignParsedNameToActiveCredit
} from "../services/CreditService";
import {
    getContributorScriptedNamesValue,
    validateCreditForm,
    validateContributorForm,
    validateCreditSubmission,
} from "../services/FormService";
import { addNewContributor } from "../utils/helpers/CreditFormHelpers";
import { isEmpty, deepCopy, parseName } from "../utils/helpers";
import { isEqual } from "lodash";

const storeInitialState: CreditsInitialStateInterface = {
    credits: [],
};

export default class CreditsStore {
    rootStore: RootStore;

    credits: CreditInterface[] = [];
    activeCredit: CreditInterface | {} = {};
    selectedCredits: SelectedCredits = selectedCreditsObject;
    activeTableCredits: DataTablePayload[] = [];
    inActiveTableCredits: DataTablePayload[] = [];

    creditFormProperties: CreditFormProperties = {
        isActive: false,
        isDisabled: true,
        searchIsActive: false,
        isNewCredit: false,
        creditValidationIsOn: false,
    };

    creditFormValidation: { [key: string]: formValidationObject } = {
        contributor: {
            valid: true,
            message: "",
        },
        role: {
            valid: true,
            message: "",
        },
    };

    creditRoleCheck: formValidationObject = {
        valid: true,
        message: "",
    };

    contributorFormProperties: ContributorFormProperties = {
        isActive: false,
        isDisabled: true,
        isNewContributor: false,
        contributorValidationIsOn: false,
    };

    contributorFormValidation: { [key: string]: formValidationObject } = {
        first_name: {
            valid: true,
            message: "",
        },
    };

    contributorFormScriptDisabled: boolean = true;

    creditDeleteFormProperties: CreditDeleteFormProperties = {
        isActive: false,
        source: null,
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            activeCredit: observable,
            selectedCredits: observable,
            activeTableCredits: observable,
            inActiveTableCredits: observable,
            creditFormProperties: observable,
            contributorFormProperties: observable,
            contributorFormScriptDisabled: observable,
            creditDeleteFormProperties: observable,
            creditFormValidation: observable,
            creditRoleCheck: observable,
            contributorFormValidation: observable,
            initialize: action,
            loadCreditswithLocalId: action,
            setCredits: action,
            setActiveTableCredits: action,
            setInActiveTableCredits: action,
            setTablesData: action,
            refreshTableData: action,
            setSelectedCredits: action,
            assignCreditsToCreditList: action,
            setActiveCredit: action,
            assignParsedNametoCredit: action,
            createCredit: action,
            updateCredit: action,
            addCreditsToCreditList: action,
            removeCreditsFromCreditList: action,
            deleteCredits: action,
            syncCreditsOnReorder: action,
            handleContributorEdit: action,
            addContributor: action,
            updateContributor: action,
            setCreditFormProperty: action,
            setCreditFormStatus: action,
            setCreditDeleteFormStatus: action,
            setContributorFormProperty: action,
            setContributorFormStatus: action,
            runFormValidation: action,
            runRoleUniquenessValidation: action,
            runAllCreditValidations: action,
            runAllContributorFormValidations: action,
            resetValidationObjects: action,
            handleOnSubmitCreditForm: action,
            handleOnCancelCreditForm: action,
            handleOnSubmitContributorForm: action,
            handleOnCancelContributorForm: action,
        });

        extendObservable(this, storeInitialState);
    }

    initialize({ credits }: CreditsInitialStateInterface): void {
        this.setCredits(this.loadCreditswithLocalId(credits), false);
        this.refreshTableData();
    }

    loadCreditswithLocalId(credits: CreditInterface[]): CreditInterface[] {
        return setCreditsLocalId(credits);   
    }

    setCredits(credits: CreditInterface[], withSave: boolean = true): void {
        this.credits = credits;
        if (withSave) {
            this.rootStore.handleDataChange();
        }
    }

    setActiveTableCredits(): void {
        this.activeTableCredits = getActiveCredits(
            this.credits,
            this.rootStore.creditListsStore.getActiveCreditList,
            (data) => this.handleContributorEdit(data),
            this.rootStore.getScriptValue,
            this.rootStore.getDefaultScriptValue,
        );
    }

    setInActiveTableCredits(): void {
        this.inActiveTableCredits = getInactiveCredits(
            this.credits,
            this.rootStore.creditListsStore.getActiveCreditList,
            (data) => this.handleContributorEdit(data),
            this.rootStore.getScriptValue,
            this.rootStore.getDefaultScriptValue,
        );
    }

    setTablesData(credits: CreditInterface[]): void {
        this.setCredits(credits);
        this.setActiveTableCredits();
        this.setInActiveTableCredits();

        this.setSelectedCredits([], "active");
        this.setSelectedCredits([], "inactive");
    }

    refreshTableData(): void {
        this.setActiveTableCredits();
        this.setInActiveTableCredits();
    }

    setSelectedCredits(
        credits: DataTablePayload[],
        variant: "active" | "inactive",
    ): void {
        this.selectedCredits[variant] = credits;
    }

    assignCreditsToCreditList(targetCreditList: CreditListInterface): void {
        let assignableCredits: CreditInterface[] = this.credits.filter(
            (credit: CreditInterface) => {
                if (!credit.credit_lists?.length || credit.deleted) return false;

                return credit?.credit_lists.some(
                    (creditList) =>
                        creditList.code === targetCreditList.creditsSource &&
                        !creditList.deleted,
                );
            },
        );

        assignableCredits.forEach((credit) => {
            const sourceListData: CreditRankMap | undefined =
                credit.credit_lists.find(
                    (creditList) =>
                        creditList.code === targetCreditList.creditsSource,
                );
            if (!isEmpty(sourceListData)) {
                credit.credit_lists.push({
                    name: targetCreditList.name,
                    code: targetCreditList.code,
                    ranking: (sourceListData as CreditRankMap).ranking,
                });
            }
        });

        this.setCredits(
            assignableCredits.concat(
                this.credits?.filter(
                    (credit: CreditInterface) =>
                        !credit.credit_lists.some(
                            (creditList: CreditRankMap) =>
                                creditList.code === targetCreditList.code &&
                                !targetCreditList.deleted,
                        ),
                ),
            ),
            true,
        );
    }

    setActiveCredit(credit: CreditInterface | {}) {
        this.activeCredit = credit;
    }

    assignParsedNametoCredit() {
        const activeCredit = assignParsedNameToActiveCredit(
                {
                    credit: this.activeCredit as CreditInterface,
                    defaultScriptValue: this.rootStore.getDefaultScriptValue,
                    isNewContributor: this.getContributorFormProperties.isNewContributor as boolean,
                    setScriptValue: () => this.rootStore.setScriptValue(this.rootStore.getDefaultScriptValue),
                    refreshTableData: () => this.refreshTableData()
                }
            ) as CreditInterface
        this.setActiveCredit(activeCredit)
    }

    createCredit(): void {
        let updatedCredits = setCreditsRanking(
            attachSingleCreditToList(
                this.activeCredit as CreditInterface,
                this.credits,
                this.rootStore.creditListsStore.activeCreditList,
            ),
            this.rootStore.creditListsStore.creditLists,
        );

        this.setTablesData(updatedCredits);
    }

    updateCredit(): void {
        let creditsCopy: CreditInterface[] = deepCopy(this.credits);
        const targetIndex: number = creditsCopy.findIndex(
            (credit) =>
                credit.local_id ===
                (this.activeCredit as CreditInterface).local_id,
        );

        if (targetIndex !== -1) {
            if (
                creditsCopy[targetIndex].role !== (this.activeCredit as CreditInterface).role || 
                creditsCopy[targetIndex].role_name !== (this.activeCredit as CreditInterface).role_name
            ) {
                creditsCopy[targetIndex].deleted = true;
                creditsCopy[targetIndex].ranking = false;
                creditsCopy.push(this.activeCredit as CreditInterface)
            } else {
                creditsCopy[targetIndex] = this.activeCredit as CreditInterface;
            }
        }

        this.setTablesData(creditsCopy);
    }

    addCreditsToCreditList(): void {
        let updatedCredits = setCreditsRanking(
            attachGroupCreditsToList(
                this.selectedCredits.inactive,
                this.credits,
                this.rootStore.creditListsStore.activeCreditList,
            ),
            this.rootStore.creditListsStore.creditLists,
        );

        this.setTablesData(updatedCredits);
    }

    removeCreditsFromCreditList(): void {
        const updatedCredits = setCreditsRanking(
            detachGroupCreditsFromList(
                this.selectedCredits.active,
                this.credits,
                this.rootStore.creditListsStore.activeCreditList,
            ),
            this.rootStore.creditListsStore.creditLists,
        );

        this.setTablesData(updatedCredits);
    }

    deleteCredits(variant: "active" | "inactive"): void {
        let updatedCredits = setCreditsRanking(
            deleteCredits(this.selectedCredits[variant], this.credits),
            this.rootStore.creditListsStore.creditLists,
        );

        this.setTablesData(updatedCredits);
    }

    syncCreditsOnReorder(data: DataTablePayload[]): void {
        let updatedCredits = setCreditsRanking(
            syncCreditsOnRankReorder(
                data,
                this.credits,
                this.rootStore.creditListsStore.activeCreditList,
            ),
            this.rootStore.creditListsStore.creditLists,
        );

        this.setTablesData(updatedCredits);
    }

    handleContributorEdit(row: DataTablePayload): void {
        const targetCredit = this.credits.find(
            (credit) => credit.local_id === row.localID,
        );

        this.setActiveCredit(deepCopy(targetCredit as CreditInterface));
        this.setContributorFormStatus({
            isActive: true,
            isDisabled: true,
            isNewContributor: false,
            contributorValidationIsOn: true,
        });
    }

    addContributor(contributor: CreditInterface): void {
        addNewContributor({
            value: contributor,
            defaultScript: this.rootStore.getDefaultScriptValue,
            creditList: this.rootStore.creditListsStore.activeCreditList,
            activeCredit: contributor,
            setActiveCredit: (credit) => this.setActiveCredit(credit),
        });
    }

    updateContributor(contributor: CreditInterface): void {
        let creditsCopy = deepCopy(this.credits);
        let targetCredit = creditsCopy?.findIndex(
            (credit) => credit.local_id === contributor.local_id,
        );

        if (
            targetCredit !== -1 &&
            !isEqual(creditsCopy[targetCredit], contributor)
        ) {
            creditsCopy[targetCredit] = contributor;

            let updatedCredits = setCreditsRanking(
                matchCreditsOnContributorUpdate(creditsCopy, contributor),
                this.rootStore.creditListsStore.creditLists,
            );

            this.setTablesData(updatedCredits);
        }
    }

    setCreditFormProperty(key: string, value: boolean) {
        this.creditFormProperties[key as keyof CreditFormProperties] = value;
    }

    setCreditDeleteFormProperty(key: string, value: string | boolean) {
        // @ts-ignore
        this.creditDeleteFormProperties[
            key as keyof CreditDeleteFormProperties
        ] = value as string | boolean;
    }

    setContributorFormProperty(key: string, value: boolean) {
        this.contributorFormProperties[key as keyof ContributorFormProperties] =
            value;
    }

    setCreditFormStatus({
        isActive,
        isDisabled,
        searchIsActive,
        isNewCredit,
    }: CreditFormProperties): void {
        this.creditFormProperties = {
            isActive,
            isDisabled,
            searchIsActive,
            isNewCredit,
        };
    }

    setContributorFormScriptStatus() {
        this.contributorFormScriptDisabled = 
            (this.activeCredit as CreditInterface).first_name === "" ||
            isEmpty((this.activeCredit as CreditInterface).first_name)
    }

    setCreditDeleteFormStatus(value: boolean) {
        this.creditDeleteFormProperties.isActive = value;
    }

    setContributorFormStatus({
        isActive,
        isDisabled,
        isNewContributor,
        contributorValidationIsOn,
    }: ContributorFormProperties): void {
        this.contributorFormProperties = {
            isActive,
            isDisabled,
            isNewContributor,
            contributorValidationIsOn,
        };
    }

    runFormValidation(form: string, field: string, value: any) {
        switch (form) {
            case "credit":
                this.creditFormValidation[field as string] = validateCreditForm(
                    this.creditFormProperties.creditValidationIsOn as boolean,
                    field,
                    value,
                )[field];
                break;

            case "contributor":
                this.contributorFormValidation[field as string] =
                    validateContributorForm(
                        this.contributorFormProperties
                            .contributorValidationIsOn as boolean,
                        field,
                        value,
                    )[field];
                break;
        }
    }

    runRoleUniquenessValidation() {
        this.setCreditFormProperty("creditValidationIsOn", true);
        this.creditRoleCheck = validateCreditSubmission(
            this.creditFormProperties.creditValidationIsOn as boolean,
            this.creditFormProperties.isNewCredit as boolean,
            this.activeCredit as CreditInterface,
            this.credits,
        );
    }

    runAllCreditValidations() {
        this.runFormValidation(
            "credit",
            "contributor",
            (this.activeCredit as CreditInterface).local_id as string,
        );

        this.runFormValidation(
            "credit",
            "role",
            (this.activeCredit as CreditInterface).role_name as string,
        );

        this.runRoleUniquenessValidation();
    }

    runAllContributorFormValidations() {
        this.runFormValidation(
            "contributor",
            "first_name",
            (this.activeCredit as CreditInterface).first_name as string,
        );

        this.runFormValidation(
            "contributor",
            "last_name",
            (this.activeCredit as CreditInterface).last_name as string,
        );
    }

    resetValidationObjects() {
        this.creditFormValidation = {
            contributor: {
                valid: undefined,
                message: "",
            },
            role: {
                valid: undefined,
                message: "",
            },
        };

        this.contributorFormValidation = {
            first_name: {
                valid: undefined,
                message: "",
            },
            last_name: {
                valid: undefined,
                message: "",
            },
        };

        this.creditRoleCheck = {
            valid: undefined,
            message: "",
        };
    }

    handleOnSubmitCreditForm() {
        if (!this.creditFormProperties.isDisabled) {
            this.setCreditFormProperty("isDisabled", true);
            this.setCreditFormProperty("creditValidationIsOn", true);
            this.runAllCreditValidations();

            const isFormValid =
                this.creditRoleCheck.valid &&
                (this.activeCredit as CreditInterface).first_name !== "";
            if (!isFormValid) return;

            this.setCreditFormProperty("isDisabled", false);
            this.creditFormProperties.isNewCredit
                ? this.createCredit()
                : this.updateCredit();
            this.setCreditFormStatus({
                isActive: false,
                isDisabled: false,
                searchIsActive: false,
                isNewCredit: false,
            });
            this.setActiveCredit({});
            this.resetValidationObjects();
        }
    }

    handleOnCancelCreditForm() {
        this.setCreditFormStatus({
            isActive: false,
            isDisabled: false,
            searchIsActive: false,
            isNewCredit: false,
        });
        this.setActiveCredit({});
        this.resetValidationObjects();
    }

    handleOnSubmitContributorForm() {
        this.setContributorFormProperty("contributorValidationIsOn", true);
        this.runAllContributorFormValidations();

        const isFormValid =
            (this.activeCredit as CreditInterface).first_name !== "";
        if (!isFormValid) return;

        let updatedContributor = Object.assign(
            {},
            this.activeCredit as CreditInterface,
        );
        updatedContributor.name = getContributorScriptedNamesValue(
            this.activeCredit as CreditInterface,
            true,
            false,
            "",
        ).name;

        this.setActiveCredit(updatedContributor);

        if (this.contributorFormProperties.isNewContributor) {
            this.addContributor(this.activeCredit as CreditInterface);
            this.setCreditFormStatus({
                isActive: true,
                isDisabled: true,
                searchIsActive: false,
                isNewCredit: true,
            });
        } else {
            this.updateContributor(this.activeCredit as CreditInterface);
            this.setCreditFormStatus({
                isActive: false,
                isDisabled: true,
                searchIsActive: false,
                isNewCredit: false,
            });
        }

        this.setContributorFormStatus({
            isActive: false,
            isDisabled: true,
            isNewContributor: false,
            contributorValidationIsOn: false,
        });
    }

    handleOnCancelContributorForm() {
        this.setContributorFormStatus({
            isActive: false,
            isDisabled: true,
            isNewContributor: false,
            contributorValidationIsOn: false,
        });

        this.setActiveCredit({});

        if (this.contributorFormProperties.isNewContributor) {
            this.setCreditFormStatus({
                isActive: true,
                isDisabled: true,
                searchIsActive: true,
                isNewCredit: true,
            });
        }

        this.resetValidationObjects();
    }

    get getCredits() {
        return this.credits;
    }

    get getCreditFormValidationStatus() {
        return this.creditFormProperties.creditValidationIsOn;
    }

    get getCreditFormProperties() {
        return this.creditFormProperties;
    }

    get getCreditFormValidation() {
        return this.creditFormValidation;
    }

    get getCreditRoleCheck() {
        return this.creditRoleCheck;
    }

    get getContributorFormValidationStatus() {
        return this.contributorFormProperties.contributorValidationIsOn;
    }

    get getContributorFormProperties() {
        return this.contributorFormProperties;
    }

    get getContributorFormScriptStatus() {
        this.setContributorFormScriptStatus();
        return this.contributorFormScriptDisabled;
    }

    get getContributorFormValidation() {
        return this.contributorFormValidation;
    }

    get getCreditDeleteFormProperty() {
        return this.creditDeleteFormProperties;
    }
}
