import { isEmpty } from "lodash"
import { ContributorInterface, ContributorV1Interface } from '../../../Preferences/Contributors/interfaces';

export default function(contributors: ContributorInterface[]): ContributorV1Interface[] {
    return contributors?.map((contributor: ContributorInterface) => {

        let localisedMetadata: ContributorInterface = {}
        if (!isEmpty(contributor.localised_metadata)) {
            localisedMetadata = contributor.localised_metadata?.find((it: any) => it.locale === 'ENG') || contributor.localised_metadata[0]
        }

        return {
            contributor_meta_id: contributor.id,
            name: localisedMetadata?.full_name,
        }
    })
}