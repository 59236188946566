import React from "react";
import _ from "lodash";
import styles from "../../components/RecordBody/styles";
import "./styles/_images.scss";
import Notification from "../../components/Notification";
import Input from "../../components/Input/basic";
import Switch from "../../components/Switch";
import Select from "../../components/Select";
import SelectWithTag from "../../components/SelectWithTag";
import BubbleList from "../../components/BubbleList";
import Button from "../../components/Button";
import ButtonFileUpload from "../../components/Button/buttonFileUpload";
import Settings from "../../components/Settings";
import Index from "../../components/RecordBody/views/Index";
import SendRequest from "../../components/SendRequest";
import update from "react-addons-update";
import deepcopy from "deepcopy";
import { diff } from "deep-object-diff";
import OptionsSearchApi from "../../components/Options/OptionsSearchApi";
import ClientManagedCountries, {
    isClientManagedCountries,
    formatClientManagedCountries,
} from "../../components/ClientManagedOptions/countries";
import {
    isClientManagedLanguages,
    tranformClientManagedLanguages,
} from "../../components/ClientManagedOptions/languages";
import {
    IMAGES_LANGUAGES,
    CLIENT_MANAGED_COUNTRIES,
} from "../../components/ClientManagedOptions/options";
import ClientManagedLanguages from "../../components/ClientManagedOptions/languages";
import BubbleListSimpleOnEnter from "../../components/BubbleList/simpleOnEnter";
import PlaceholderText from "../../components/PlaceholderText";
import GridView from "./views/GridView.view"
import TableView from "./views/TableView.view"
import ViewSwitchIcons from "./views/ViewSwitchIcons.view"
import ButtonBarContainer from "../../containers/ButtonBarContainer"
import { userHasPermission } from "../../services/PermissionService"; 
import { accessTypeStrictCreate, accessTypeStrictUpdate, accessTypeStrictDelete } from "../../features/UserRoleManagement/utils/accessOptions";

export const viewTable = "table"
export const viewGrid = "grid"

export default class Images extends Index {
    constructor(props) {
        super(props);

        let data = [];

        if (Array.isArray(this.props.data)) {
            data = this.props.data;
        }

        /**
         * @var object
         */
        this.state = {
            data,
            activeImage: {},
            editIndex: false,
            notification: {},
            uploading: false,
            error: false,
            datalists: {},
            clientManagedLanguages: [],
            fetchingClientManagedLanguages: false,
            clientManagedCountries: [],
            fetchingClientManagedCountries: false,
            view: viewGrid
        };

        /**
         * @var object
         */
        this.copy = this.props.copy || {};

        /**
         * @var object
         */
        this.styles = styles(this.props);

        this.clientManagedLanguages = isClientManagedLanguages({
            clientFeatures: this.props.clientFeatures || {},
            languageKey: IMAGES_LANGUAGES,
        });

        this.clientManagedCountries = isClientManagedCountries({
            clientFeatures: this.props.clientFeatures || {},
        });

        this.userPermissions = {
            delete: false,
            create: false,
            update: false
        }
    }

    componentWillMount() {
        this.setUserPermissions()
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.clientManagedLanguages) {
            this.fetchClientManagedLanguages();
        }

        if (this.clientManagedCountries) {
            this.fetchClientManagedCountries();
        }
    }

    fetchClientManagedLanguages() {
        const { apiUrls = {} } = this.props;

        const { getClientManagedLanguages = "" } = apiUrls;

        this.setState({ fetchingClientManagedLanguages: true });

        OptionsSearchApi({
            updateList: (value) => {
                this.setState({
                    clientManagedLanguages: tranformClientManagedLanguages({
                        client_managed_languages: value || {},
                        languageKey: IMAGES_LANGUAGES,
                    }),
                    fetchingClientManagedLanguages: false,
                });
            },
            url: getClientManagedLanguages,
        });
    }

    fetchClientManagedCountries() {
        const { apiUrls = {} } = this.props;

        const { getClientManaged = "" } = apiUrls;

        this.setState({ fetchingClientManagedCountries: true });

        OptionsSearchApi({
            updateList: (value) => {
                this.setState({
                    clientManagedCountries: formatClientManagedCountries(value),
                    fetchingClientManagedCountries: false,
                });
            },
            url: getClientManaged.replace("{table}", CLIENT_MANAGED_COUNTRIES),
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextPropsData = nextProps.data || {};
        const thisPropsData = this.props.data || {};
        const difference = diff(nextPropsData, thisPropsData);

        if (!_.isEmpty(difference)) {
            return true;
        }

        if (!_.isEqual(nextProps.disabled, this.props.disabled)) {
            return true;
        }

        if (!_.isEqual(nextProps.options, this.props.options)) {
            return true;
        }

        if (!_.isEqual(nextProps.audits, this.props.audits)) {
            return true;
        }

        if (!_.isEqual(nextProps.recordImageUrl, this.props.recordImageUrl)) {
            return true;
        }

        if (!_.isEqual(nextProps.meta_id, this.props.meta_id)) {
            return true;
        }

        if (!_.isEqual(nextProps.meta_id_temp, this.props.meta_id_temp)) {
            return true;
        }

        if (!_.isEqual(nextProps.userPermissions, this.props.userPermissions)) {
            return true;
        }

        if (!_.isEqual(nextState, this.state)) {
            return true;
        }

        return false;
    }

    setUserPermissions() {
        const deletePermission = userHasPermission({
            cmsData: this.props.cmsData,
            userPermissions: this.props.userPermissions,
            pathField: this.props.pathField,
            access: accessTypeStrictDelete,
            legacyPermission: 'discovery.images.edit'
        });

        const createPermission = userHasPermission({
            cmsData: this.props.cmsData,
            userPermissions: this.props.userPermissions,
            pathField: this.props.pathField,
            access: accessTypeStrictCreate,
            legacyPermission: 'discovery.images.edit'
        });

        const updatePermission = userHasPermission({
            cmsData: this.props.cmsData,
            userPermissions: this.props.userPermissions,
            pathField: this.props.pathField,
            access: accessTypeStrictUpdate,
            legacyPermission: 'discovery.images.edit'
        });

        this.userPermissions = {
            delete: deletePermission,
            create: createPermission,
            update: updatePermission
        }
    }

    setStateValues(key, value) {
        if (!_.isEqual(this.state[key], value)) {
            this.setState({
                [key]: value,
            });
        }
    }

    getNotificationObject() {
        const validated = this.isValidated();

        const result = {
            okText: this.copy.save || "Save",
            html: this.renderNotificationHtml(),
            confirm: this.handleSave.bind(this),
            status: "alert",
            disabled: !validated,
            background: "white",
            contentNoPadding: true,
        };

        return result;
    }

    getTempImageObject() {
        const activeImage = {
            ...this.state.activeImage,
            poster: this.getPosterCheckedValue(this.state.activeImage),
        };

        return activeImage;
    }

    getPosterCheckedValue(data) {
        let result = data.poster;

        if (Array.isArray(this.state.data)) {
            if (this.state.data.length === 0) {
                result = true;
            }
        }

        return result;
    }

    handleSave() {
        let data = deepcopy(this.state.data);
        const activeImage = this.state.activeImage || {};

        // Resets poster
        if (Array.isArray(data) && activeImage.poster) {
            data.map((item) => (item.poster = false));
        }

        if (this.state.editIndex !== false) {
            data = update(data, {
                [this.state.editIndex]: { $set: this.state.activeImage },
            });
        }

        if (this.state.editIndex === false) {
            data = update(data, { $push: [this.state.activeImage] });
        }

        this.setStateValues("data", data);
        this.setStateValues("activeImage", {});
        this.setStateValues("editIndex", false);
        this.setStateValues("error", false);
        this.setStateValues("notification", {});

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(data);
        }
    }

    handleAddNewImageClick() {
        this.setStateValues("notification", this.getNotificationObject());
    }

    async handleNotificationCancel() {
        await this.setStateValues("editIndex", false);
        await this.setStateValues("activeImage", {});
        this.setStateValues("notification", {});
    }

    handleError(message = false) {
        let title =
            this.copy.technicalError || "Technical error, please try again";

        if (typeof message == "string") {
            title = message;
        }

        let input = document.getElementById("file-upload");
        input.value = "";
        this.setStateValues("error", title);
    }

    async handleImageChange(e) {
        if (typeof e == "object") {
            if (typeof e.target == "object") {
                const target = e.target.files[0];
                let meta_id = this.props.meta_id || this.props.meta_id_temp;
                let formData = new FormData();

                formData.append("image", target);
                await this.setStateValues("uploading", true);
                await this.setStateValues("error", false);
                this.setStateValues(
                    "notification",
                    this.getNotificationObject(),
                );

                const options = {
                    url: this.props.recordImageUrl.replace(
                        "{meta_id}",
                        meta_id,
                    ),
                    api_token: this.props.api_token,
                    method: "POST",
                    basic: true,
                    noJson: true,
                    formData,
                };

                try {
                    SendRequest(
                        options,
                        async (data) => {
                            if (typeof data == "object") {
                                let responseData = data.data || {};

                                if (data.status == 200) {
                                    let activeImage = {
                                        ...this.state.activeImage,
                                    };
                                    (activeImage.filename =
                                        responseData.filename),
                                        (activeImage.original_filename =
                                            responseData.original_filename),
                                        (activeImage.extension =
                                            responseData.extension),
                                        (activeImage.meta_id =
                                            responseData.meta_id),
                                        (activeImage.horizontal_resolution =
                                            responseData.horizontal_resolution),
                                        (activeImage.vertical_resolution =
                                            responseData.vertical_resolution),
                                        (activeImage.filesize =
                                            responseData.filesize),
                                        (activeImage.source = "open");

                                    await this.setStateValues(
                                        "uploading",
                                        false,
                                    );
                                    await this.setStateValues(
                                        "activeImage",
                                        activeImage,
                                    );
                                    await this.setStateValues("error", false);
                                    this.setStateValues(
                                        "notification",
                                        this.getNotificationObject(),
                                    );
                                }

                                if (data.status !== 200) {
                                    await this.setStateValues(
                                        "uploading",
                                        false,
                                    );
                                    const message = responseData.message;
                                    await this.handleError(message);
                                    this.setStateValues(
                                        "notification",
                                        this.getNotificationObject(),
                                    );
                                }
                            }
                        },
                        async (e) => {
                            await this.setStateValues("uploading", false);
                            await this.handleError();
                            this.setStateValues(
                                "notification",
                                this.getNotificationObject(),
                            );
                        },
                    );
                } catch (e) {
                    await this.setStateValues("uploading", false);
                    await this.handleError();
                    this.setStateValues(
                        "notification",
                        this.getNotificationObject(),
                    );
                }
            }
        }
    }

    async handleImageDelete() {
        let activeImage = this.getTempImageObject();
        activeImage.extension = false;
        activeImage.filename = false;
        activeImage.original_filename = false;
        activeImage.filesize = false;
        activeImage.horizontal_resolution = false;
        activeImage.vertical_resolution = false;

        await this.setStateValues("activeImage", activeImage);
        this.setStateValues("notification", this.getNotificationObject());
    }

    handleChange(key, value) {
        let activeImage = this.getTempImageObject();
        activeImage[key] = value;

        if (key == "poster") {
            if (typeof value == "object") {
                activeImage.poster = value.checked;
            }
        }

        this.setState(
            {
                activeImage: activeImage,
                error: false,
            },
            () => {
                this.updateNotificationValidation();
            },
        );
    }

    updateNotificationValidation() {
        const validated = this.isValidated();
        let updateNotification = { ...this.state.notification };
        updateNotification.disabled = !validated;

        this.setState({
            notification: updateNotification,
        });
    }

    fetchList(props) {
        const { apiUrls = {} } = this.props;

        const { recordService = "" } = apiUrls;

        const { url = "", searchTerm, state_key } = props;

        try {
            const options = {
                url: `${recordService}${url}?s=${searchTerm}`,
                method: "GET",
            };

            const handleError = () => {
                console.log("searchTerm");
            };

            SendRequest(
                options,
                (data) => {
                    if (data.status == 200) {
                        const dataState = data.data || [];

                        let updatedState = { ...this.state.datalists };
                        updatedState[state_key] = dataState;

                        this.setState(
                            {
                                datalists: updatedState,
                            },
                            () => {
                                this.updateNotificationHtml();
                            },
                        );
                    } else {
                        handleError();
                    }
                },
                (error) => {
                    handleError();
                },
            );
        } catch (e) {
            handleError();
        }
    }

    updateNotificationHtml() {
        let updateNotification = { ...this.state.notification };
        updateNotification.html = this.renderNotificationHtml();

        this.setState({
            notification: updateNotification,
        });
    }

    async handleEdit(index) {
        await this.setStateValues("editIndex", index);
        await this.setStateValues("activeImage", { ...this.state.data[index] });
        this.setStateValues("notification", this.getNotificationObject());
    }

    handleDelete(index) {
        const notification = {
            title: this.copy.deleteImage || "Delete image",
            description:
                this.copy.deleteDescription ||
                "Are you sure you want to delete image?",
            okText: this.copy.delete || "Delete",
            confirm: this.deleteImage.bind(this, index),
            disabled: false,
            interactionButtonClassName: "meta-delete-image-confirmation",
            status: "error",
        };

        this.setStateValues("notification", notification);
    }

    deleteImage(index) {
        let data = deepcopy(this.state.data)
        let updatedItem = deepcopy(data[index]);

        if (typeof updatedItem == "object") {
            updatedItem.deleted = true;
        }

        const updatedData = update(this.state.data, {
            [index]: {
                $set: updatedItem,
            },
        });

        this.setStateValues("data", updatedData);
        this.setStateValues("notification", {});

        if (typeof this.props.handleFormChange == "function") {
            this.props.handleFormChange(updatedData);
        }
    }

    isValidated() {
        let result = false;
        const activeImage = this.state.activeImage;

        if (
            activeImage.name &&
            activeImage.name !== "" &&
            !this.state.uploading
        ) {
            result = true;
        }

        return result;
    }

    renderItem(props) {
        const { options = {} } = this.props;

        const {
            component_type,
            title,
            placeholder,
            id,
            className,
            list,
            disabled_text,
            styles = {},
            datalistId,
            datalist,
            background,
            color,
            state_key,
            url = "",
        } = props;

        const data = this.state.activeImage || {};
        const stateKey = state_key || props.stateKey;

        const selectProps = {
            name: stateKey,
            id: id,
            value: data[stateKey],
            clear: true,
            label: title,
            noneSelectedText: "None Selected",
            disabledText: disabled_text,
            data: options[list],
            handleChange: this.handleChange.bind(this, stateKey),
            wrapperStyles: styles,
            placeholder,
        };

        return (
            <React.Fragment>
                {component_type == "input" && (
                    <Input
                        placeholder={placeholder}
                        label={title}
                        id={id}
                        value={data[stateKey]}
                        recordBasic={true}
                        borderHiddenPadding={true}
                        handleChange={this.handleChange.bind(this, stateKey)}
                    />
                )}

                {component_type == "select" && <Select {...selectProps} />}

                {component_type == "select_with_tag" && (
                    <SelectWithTag {...selectProps} />
                )}

                {component_type == "list" && (
                    <BubbleList
                        emptyPlaceholder={placeholder}
                        className={className}
                        label={title}
                        data={data[stateKey]}
                        datalistId={datalistId}
                        datalist={options[list] || datalist}
                        background={background}
                        color={color}
                        deleteWhite={true}
                        useEnterButton={false}
                        dataListUseEnterButton={true}
                        handleFormChange={this.handleChange.bind(
                            this,
                            stateKey,
                        )}
                        filterStaticDataList={true}
                    />
                )}

                {component_type == "api_list" && (
                    <BubbleList
                        emptyPlaceholder={placeholder}
                        className={className}
                        label={title}
                        datalistId={datalistId}
                        data={data[stateKey]}
                        datalist={this.state.datalists[state_key] || []}
                        background={background}
                        color={color}
                        deleteWhite={true}
                        useEnterButton={false}
                        dataListUseEnterButton={true}
                        handleFormChange={this.handleChange.bind(
                            this,
                            stateKey,
                        )}
                        handleChange={(value) => {
                            this.fetchList({
                                url,
                                searchTerm: value,
                                state_key,
                            });
                        }}
                        filterStaticDataList={true}
                        ranking={true}
                        noMatchText="No match for {value}. Create New?"
                    />
                )}

                {component_type == "image_upload" &&
                    this.renderImageUpload({
                        data,
                    })}

                {component_type == "list_on_enter" && (
                    <BubbleListSimpleOnEnter
                        inputId={id}
                        emptyPlaceholder={placeholder}
                        className={className}
                        label={`${title} (press enter to select)`}
                        data={
                            typeof data[stateKey] == "string"
                                ? data[stateKey].split("|")
                                : []
                        }
                        datalistId={datalistId}
                        background={background}
                        color={color}
                        useEnterButtonOnSelect={true}
                        useEnterButton={true}
                        dataListUseEnterButton={true}
                        handleFormChange={(data) =>
                            this.handleChange(stateKey, data.join("|"))
                        }
                        noDelay={true}
                    />
                )}
            </React.Fragment>
        );
    }

    renderImageUpload(props) {
        const { data = {} } = props;

        return (
            <div
                style={Object.assign({}, this.styles.images.inlineBottom, {
                    marginTop: "1.84375rem",
                })}
            >
                <Input
                    label={this.copy.imageFile || "Image File"}
                    placeholder={this.copy.filename || "Filename"}
                    id="filename"
                    value={data.original_filename || data.filename}
                    name="filename"
                    width="100%"
                    margin="0 0.46875rem 0 0"
                    disabled={true}
                    recordBasic={true}
                />

                {!data.original_filename && (
                    <ButtonFileUpload
                        value={this.copy.upload || "Upload"}
                        multiple={false}
                        background="#dbdbdb"
                        hoverBackground="#dbdbdb"
                        hoverColor="#656565"
                        color="#656565"
                        padding="0.625rem"
                        accept="image/x-png,image/jpeg"
                        leftIcon={`${Settings.images.path}/svg/upload-dark-grey.svg`}
                        handleChange={this.handleImageChange.bind(this)}
                        loading={this.state.uploading}
                        loadingSrc={Settings.images.loadingBlack || false}
                    />
                )}

                {data.original_filename && (
                    <Button
                        value={this.copy.delete || "Delete"}
                        background="#e8375c"
                        hoverBackground="#e8375c"
                        hoverColor="white"
                        color="white"
                        padding="0.625rem"
                        minWidth="6.28125rem"
                        type="button"
                        onClick={this.handleImageDelete.bind(this)}
                    />
                )}
            </div>
        );
    }

    renderNotificationHtml() {
        const {
            options = {},
            cmsData = {}
        } = this.props;

        const {
            clientFeatures = {},
            clientDataStructure = {}
        } = cmsData

        const {
            images = {}
        } = clientDataStructure

        const {
            image_types = [],
            countries = [],
            spoken_languages = [],
        } = options;

        const { 
            half_width = [], 
            full_width = [] 
        } = images;

        const {
            clientManagedLanguages = [],
            clientManagedCountries = [],
            fetchingClientManagedLanguages,
            fetchingClientManagedCountries,
        } = this.state;

        const data = this.state.activeImage || {};

        const countriesList = ClientManagedCountries({
            options,
            clientFeatures,
            fallback: countries,
        });

        const languages = ClientManagedLanguages({
            options,
            written: true,
            fallback: spoken_languages,
            clientFeatures,
            languageKey: "images",
        });

        return (
            <React.Fragment>
                <div
                    style={{
                        padding: "1.125rem",
                    }}
                >
                    <div style={this.styles.images.inline}>
                        <span
                            id="meta-notification--title"
                            style={this.styles.images.inline}
                        >
                            {this.copy.imageInformation || "Image Information"}
                        </span>

                        <div style={this.styles.images.recordPosterContainer}>
                            <span style={this.styles.images.recordPoster}>
                                {this.copy.recordPoster || "Record Poster"}
                            </span>

                            <Switch
                                name="notification_image_poster"
                                checked={this.getPosterCheckedValue(data)}
                                handleChange={this.handleChange.bind(
                                    this,
                                    "poster",
                                )}
                            />
                        </div>
                    </div>

                    {!_.isEmpty(clientDataStructure?.images) &&
                        typeof clientDataStructure?.images == "object" && (
                            <div className="flex-image-container">
                                {half_width.map((item, index) => {
                                    return (
                                        <div
                                            className={`flex-image-container--half-wdth item ${item.customClass}`}
                                            key={index}
                                        >
                                            {this.renderItem(item)}
                                        </div>
                                    );
                                })}

                                {full_width.map((item, index) => {
                                    return (
                                        <div
                                            className="flex-image-container--full-wdth item"
                                            key={index}
                                        >
                                            {this.renderItem(item)}
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                    {_.isEmpty(clientDataStructure?.images) && (
                        <React.Fragment>
                            <div
                                style={Object.assign(
                                    {},
                                    { marginTop: "1.84375rem" },
                                )}
                            >
                                {this.renderItem({
                                    component_type: "input",
                                    title: "Image Name",
                                    placeholder: "Enter Image Name",
                                    id: "notification_image_name",
                                    stateKey: "name",
                                })}
                            </div>

                            <div
                                style={Object.assign(
                                    {},
                                    this.styles.images.inline,
                                    { marginTop: "1.84375rem" },
                                )}
                            >
                                <Select
                                    name="image_type_id"
                                    id="notification_image_type_id"
                                    value={data.image_type_id}
                                    clear={true}
                                    label={this.copy.imageType || "Image Type"}
                                    noneSelectedText={
                                        this.copy.noneSelected ||
                                        "None Selected"
                                    }
                                    disabledText={
                                        this.copy.selectImageType ||
                                        "Select Image Type"
                                    }
                                    data={image_types}
                                    wrapperStyles={{
                                        width: "calc(50% - 0.390625rem)",
                                        marginRight: "0.390625rem",
                                    }}
                                    handleChange={this.handleChange.bind(
                                        this,
                                        "image_type_id",
                                    )}
                                />

                                <SelectWithTag
                                    name="locale"
                                    id="notification_image_locale"
                                    value={data.locale}
                                    clear={true}
                                    label={
                                        this.copy.titleLanguage ||
                                        "Title Language"
                                    }
                                    noneSelectedText={
                                        this.copy.noneSelected ||
                                        "None Selected"
                                    }
                                    disabledText={
                                        this.copy.selectLanguage ||
                                        "Select Language"
                                    }
                                    loading={fetchingClientManagedLanguages}
                                    data={
                                        this.clientManagedLanguages
                                            ? clientManagedLanguages
                                            : languages
                                    }
                                    wrapperStyles={{
                                        width: "calc(50% - 0.390625rem)",
                                        marginLeft: "0.390625rem",
                                    }}
                                    handleChange={this.handleChange.bind(
                                        this,
                                        "locale",
                                    )}
                                />
                            </div>

                            <div
                                style={Object.assign(
                                    {},
                                    { marginTop: "1.84375rem" },
                                )}
                            >
                                <BubbleList
                                    className="notification_image_countries"
                                    label={this.copy.countries || "Countries"}
                                    emptyPlaceholder={
                                        this.copy.startTypingCountries ||
                                        "Start Typing Countries"
                                    }
                                    data={data.countries}
                                    datalistId="notification_image_countries"
                                    loading={fetchingClientManagedCountries}
                                    datalist={
                                        this.clientManagedCountries
                                            ? clientManagedCountries
                                            : countriesList
                                    }
                                    background="#9b78cf"
                                    color="white"
                                    deleteWhite={true}
                                    useEnterButton={false}
                                    dataListUseEnterButton={true}
                                    handleFormChange={this.handleChange.bind(
                                        this,
                                        "countries",
                                    )}
                                    filterStaticDataList={true}
                                />
                            </div>

                            <div
                                style={Object.assign(
                                    {},
                                    { marginTop: "1.84375rem" },
                                )}
                            >
                                <Input
                                    label={
                                        this.copy.assetBankId || "Asset Bank ID"
                                    }
                                    placeholder={
                                        this.copy.enterId || "Enter ID"
                                    }
                                    id="notification_image_asset_bank_id"
                                    value={data.asset_bank_id}
                                    recordBasic={true}
                                    borderHiddenPadding={true}
                                    handleChange={this.handleChange.bind(
                                        this,
                                        "asset_bank_id",
                                    )}
                                />
                            </div>

                            <div
                                style={Object.assign(
                                    {},
                                    { marginTop: "1.84375rem" },
                                )}
                            >
                                <Input
                                    label={this.copy.comments || "Comments"}
                                    placeholder={
                                        this.copy.enterComments ||
                                        "Enter Comments"
                                    }
                                    id="notification_image_comment"
                                    value={data.comment}
                                    recordBasic={true}
                                    borderHiddenPadding={true}
                                    handleChange={this.handleChange.bind(
                                        this,
                                        "comment",
                                    )}
                                />
                            </div>

                            {clientFeatures.imageContributors && (
                                <div
                                    style={Object.assign(
                                        {},
                                        { marginTop: "1.84375rem" },
                                    )}
                                >
                                    {this.renderItem({
                                        component_type: "api_list",
                                        title: "Contributors",
                                        placeholder: "Enter Contributor Name",
                                        className:
                                            "notification_image_contributors",
                                        stateKey: "contributors",
                                        url: "/options/contributors",
                                        background: "#9b78cf",
                                        color: "white",
                                    })}
                                </div>
                            )}

                            {clientFeatures.imageTags && (
                                <div
                                    style={Object.assign(
                                        {},
                                        { marginTop: "1.84375rem" },
                                    )}
                                >
                                    {this.renderItem({
                                        component_type: "api_list",
                                        title: "Tags",
                                        placeholder: "Enter Tag",
                                        className: "notification_image_tag",
                                        stateKey: "tags",
                                        list: "tags",
                                        url: "/options/tags",
                                        background: "#9b78cf",
                                        color: "white",
                                    })}
                                </div>
                            )}

                            {clientFeatures.imageSeoTags && (
                                <div
                                    style={Object.assign(
                                        {},
                                        { marginTop: "1.84375rem" },
                                    )}
                                >
                                    {this.renderItem({
                                        component_type: "api_list",
                                        title: "SEO Tags",
                                        placeholder: "Enter Tag",
                                        className: "notification_image_seo_tag",
                                        stateKey: "seo_tags",
                                        list: "tags",
                                        url: "/options/seo-tags",
                                        background: "#9b78cf",
                                        color: "white",
                                    })}
                                </div>
                            )}

                            {this.renderImageUpload({
                                data,
                            })}
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }

    renderTitle() {
        return false
    }

    renderNoneAdded() {
        return false
    }

    renderContent() {
        const data = deepcopy(this.state.data || []);

        let {
            options = {},
            meta_id,
            meta_id_temp,
            disabled,
            audits = [],
            cmsData = {}
        } = this.props;

        const {
            clientFeatures = {},
            clientDataStructure = {}
        } = cmsData

        const {
            imageListViewEnabled
        } = clientFeatures

        const { languages = [], image_types = [] } = options;

        const { clientManagedLanguages = [] } = this.state;

        const { notification = {}, error } = this.state;

        const {
            title,
            description,
            status,
            html,
            confirm,
            background,
            okText,
            contentNoPadding,
            interactionButtonClassName = "meta-notification-image-save",
        } = notification;

        if (!meta_id && meta_id_temp) {
            meta_id = meta_id_temp;
        }

        const isEmptyDisabled = disabled && _.isEmpty(data)
        const createNewDisabled = disabled || !this.userPermissions.create

        return (
            <>
                <div className="image-title-container">
                    <h2 style={this.styles.section.title}>
                        Images
                    </h2>

                    {imageListViewEnabled &&
                        <ViewSwitchIcons 
                            view={this.state.view}
                            handleSetView={view => this.setState({
                                view
                            })}
                        />
                    }
                </div>

                <ButtonBarContainer
                    className="image-bar-container"
                    rightChildren={
                        <>
                            <Button
                                value="Add Image"
                                onClick={() => this.handleAddNewImageClick()}
                                type="button"
                                disabled={createNewDisabled}
                                margin="0"
                                className={`add-image ${createNewDisabled ? "disabled" : ""}`}
                            />
                        </>
                    }
                />

                <div style={this.styles.marginItemXSmall}>
                    {this.state.view == viewTable &&
                        <TableView 
                            {...this.props}
                            data={data}
                            meta_id={meta_id}
                            userPermissions={this.userPermissions}
                            handleEdit={index => this.handleEdit(index)}
                            handleDelete={index => this.handleDelete(index)}
                        />
                    }

                    {this.state.view == viewGrid &&
                        <>
                            {isEmptyDisabled && 
                                <div style={this.styles.marginItemXSmall}>
                                    <PlaceholderText />
                                </div>
                            }

                            {!isEmptyDisabled &&
                                <div style={this.styles.images.container}>
                                    <GridView 
                                        {...this.props}
                                        meta_id={meta_id}
                                        data={data}
                                        clientManagedLanguages={clientManagedLanguages}
                                        userPermissions={this.userPermissions}
                                        languages={languages}
                                        image_types={image_types}
                                        handleEdit={index => this.handleEdit(index)}
                                        handleDelete={index => this.handleDelete(index)}
                                        handleAddNewImageClick={() => this.handleAddNewImageClick()}
                                    />
                                </div>
                            }
                        </>
                    }
                </div>

                <Notification
                    title={title}
                    description={description}
                    intercationStatus={status}
                    html={html}
                    confirm={confirm}
                    background={background}
                    disabled={this.state.notification.disabled}
                    onClick={this.handleNotificationCancel.bind(this)}
                    okText={okText}
                    errorMessage={error}
                    contentNoPadding={contentNoPadding}
                    interactionButtonClassName={interactionButtonClassName}
                    closeOnWrapperClick={false}
                    confirmOnEnter={false}
                    keyPressEnterDisabled={true}
                />
            </>
        );
    }
}
