import React from "react";
import { isEmpty } from "lodash"
import { observer } from "mobx-react";
import { useStores } from "../store/rootStore";
import MultiLevelExpandable from "../../../components/RecordBuilder/views/MultiLevelExpandable";
import AddNewRowButton from "../../../components/AddNewRowButton";
import { typeAncillary } from "../config/fields";
import { CoreDialog } from "../../../core/Dialog/CoreDialog";
import CoreDynamicForm from '../../../core/Form/dynamic'

const AncillariesView = observer(() => {
    const { 
        rootStore,
        ancillariesStore,
        ancillariesCreateStore,
        ancillariesUpdateStore,
        optionsStore,
        validationStore,
        permissionsStore
    } = useStores() || {};

    if (ancillariesStore.isEmptyData && rootStore.disabled) {
        return <></>
    }

    return (
        <>
            {!ancillariesStore.isEmptyData &&
                <MultiLevelExpandable 
                    data={ancillariesStore.multiLevelViewData}
                    expandAll={false}
                    disabled={rootStore.disabled}
                    showOptions={rootStore.isEditable}
                />
            }

            {(!rootStore.disabled && rootStore.isEditable) &&
                <AddNewRowButton 
                    onClick={() => ancillariesCreateStore.handleCreateNewClick(typeAncillary)}
                    disabled={!permissionsStore.permissions.create}
                    styles={{
                        background: 'rgb(242, 242, 241)',
                        marginTop: '1rem',
                        borderRadius: '0.5rem'
                    }}
                />
            }

            {(ancillariesCreateStore.type || ancillariesUpdateStore.type) && 
                <CoreDialog
                    open={true}
                    dialogMinWidth="35rem"
                    dialogMaxWidth="45rem"
                    dialogContentPadding="0 1.25rem 1.25rem 1.25rem"
                    title={`${ancillariesCreateStore.type ? 'Add New' : 'Update'} ${ancillariesStore.typeTitle}`}
                    setOpen={() => rootStore.handleResetForm()}
                    buttons={{
                        cancelTopRightIcon: true,
                        submit: {
                            handleOnSubmit: () => ancillariesCreateStore.type 
                                ? ancillariesCreateStore.handleAddNewAncillaryType()
                                : ancillariesUpdateStore.handleUpdateAncillaryType(),
                            label: ancillariesCreateStore.type 
                                ? "Add" 
                                : "Update",
                            disabled: !validationStore.isValidated
                        },
                        cancel: {
                            label: "Cancel",
                        },
                    }}
                >
                    <CoreDynamicForm 
                        fields={optionsStore.coreFormFields} 
                        data={ancillariesStore.formData}
                        defaultValues={ancillariesStore.defaultFormData}
                        onChange={(values: any) => ancillariesStore.handleChange(values)}
                        stackStyles={{
                            paddingTop: 0
                        }}
                        triggerEnabled={true}
                    />
                </CoreDialog>
            }
        </>
    )
});

export default AncillariesView;
