import React from "react";
import _ from "lodash";
import Button from "../Button";
import Settings from "../Settings";
import "./styles/_styles.scss";

const AddNewButtonWithLineMultiple = (props) => {
    const {
        buttons = [],
        styles,
        leftIcon = Settings.images.path + "/svg/plus-sign-white-grey.svg",
        loadingIcon = Settings.images.path + "/svg/loading-white.svg",
        className = ""
    } = props;

    if (_.isEmpty(buttons)) {
        return false;
    }

    return (
        <div className="meta-add-new-button-with-line" style={styles}>
            <div className={`multiple ${className}`}>
                {buttons.map((item, index) => {
                    const icon = item.loading ? loadingIcon : leftIcon;
                    const disabled = item.disabled || props.disabled

                    return (
                        <div key={index}>
                            <Button
                                value={item.title}
                                id={item.id}
                                type="button"
                                margin="0 0 0 auto"
                                padding="0.5625rem 0.6875rem"
                                background="#8f8f8f"
                                hoverBackground="#8f8f8f"
                                color="white"
                                fontSize="0.78125rem"
                                fontWeight="500"
                                border="0.0625rem solid #8f8f8f"
                                borderRadius="0.25rem"
                                leftIcon={!disabled && icon}
                                leftIconStyles={{
                                    width: "0.75rem",
                                }}
                                onClick={item.onClick}
                                disabled={disabled}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AddNewButtonWithLineMultiple;
