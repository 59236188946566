/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import CloseIcon from "@mui/icons-material/Close";
import {
    Alert,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    IconButton,
    Stack,
    TabProps,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";
import React, { SyntheticEvent, useEffect, useState } from "react";
import StyledTab from "../../features/TaskManager/views/Tab.styled";
import StyledTabs from "../../features/TaskManager/views/Tabs.styled";
import { CoreFormTitle } from "../Form/components/styled/CoreFormTitle";
import { CoreTooltip } from "../Tooltip/CoreTooltip";
import {
    CoreDialogCircleIcon,
    CoreDialogTabIconColorEnum,
} from "./CoreDialogCircleIcon";

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    borderTop: "1px solid #E5E5E5",
    padding: "1.125rem",
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    fontSize: "0.875rem",
    boxShadow: "none",
    fontWeight: "400",
    letterSpacing: "normal",
    "&:hover": {
        boxShadow: "none",
    },
}));

export const DiscardButton = styled(SubmitButton)(({ theme }) => ({
    color: "rgba(47, 47, 47, 0.87)",
    "&:hover": {
        background: "rgba(0,0,0,.05)",
    },
}));

export const CancelButton = styled(SubmitButton)(({ theme }) => ({
    color: "rgba(47, 47, 47, 0.87)",
    "&:hover": {
        background: "rgba(0,0,0,.05)",
    },
}));

export const DeleteButton = styled(SubmitButton)(({ theme }) => ({
    background: "#D6465E",
    color: "#FFF",
    padding: "0.375rem 1rem",
    "&:hover": {
        background: "#D03152",
    },
}));

export const StyledAlertWarning = styled(Alert)(({ theme }) => ({
    backgroundColor: "rgb(255, 244, 229) !important",
    ".MuiAlert-icon": {
        alignSelf: "center",
    }
}));

const StyledAlertError = styled(StyledAlertWarning)(({ theme }) => ({
    backgroundColor: "#ffcccb !important",
    position: "relative",
    top: '1rem',
    width: '100%',
}));

export type DiscardChangesAlertType = {
    message: string;
    discardButtonLabel?: string;
    formChanged?: boolean;
};

export type CoreDialogTabData = {
    label: string;
    content: React.ReactNode;
    iconColorVariant?: CoreDialogTabIconColorEnum;
    muiProps?: Omit<TabProps, "label">;
    tooltipText?: string;
};

export type CoreDialogButtonsType = {
    cancelTopRightIcon?: boolean;
    cancel?: {
        label?: string;
    };
    discard?: {
        label: string;
        handleOnDiscard: () => void;
    };
    submit?: {
        label: string;
        handleOnSubmit: any; // TODO: replace any with corresponding type,
        disabled?: boolean;
    };
    delete?: {
        label?: string;
        position?: string;
        handleOnDelete: () => void;
    };
};

export type CoreDialogProps = {
    tabs?: CoreDialogTabData[];
    open: boolean;
    setOpen: (newOpenValue: boolean) => void;
    buttons?: CoreDialogButtonsType;
    title?: string;
    subTitle?: string;
    dialogMaxWidth?: string;
    dialogMinWidth?: string;
    dialogContentWidth?: string;
    dialogMinHeight?: string;
    dialogWidth?: string;
    dialogContentMaxWidth?: string;
    dialogContentPadding?: string;
    discardChangesAlertConfig?: DiscardChangesAlertType;
    children: React.ReactNode;
    onTabChange?: (newValue: number) => void;
    errorAlert?: string;
    errorAlerts?: string[];
    loading?: boolean;
} & Pick<DialogProps, "scroll">;

export function CoreDialog({
    tabs,
    open,
    setOpen,
    scroll,
    children,
    buttons = {},
    title,
    subTitle,
    dialogMaxWidth,
    dialogMinWidth,
    dialogMinHeight,
    dialogContentWidth,
    dialogContentMaxWidth,
    dialogContentPadding,
    dialogWidth,
    discardChangesAlertConfig = {
        message: "You have unsaved changes",
        discardButtonLabel: "LEAVE PAGE",
        formChanged: false,
    },
    onTabChange,
    errorAlert,
    errorAlerts,
    loading,
}: CoreDialogProps) {
    const [showAlert, setShowAlert] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!isEmpty(onTabChange) && typeof onTabChange === "function") {
            onTabChange(tabValue);
        }
    }, [tabValue, onTabChange]);

    const handleTabChange = (event: SyntheticEvent, newValue: number): void => {
        setTabValue(newValue);
    };

    const handleDiscard = () => {
        setOpen(false);
        setShowAlert(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const disabled = isSubmitting || loading;

    return (
        <Dialog
            open={open}
            // eslint-disable-next-line no-confusing-arrow
            onClose={() =>
                discardChangesAlertConfig?.formChanged
                    ? setShowAlert(true)
                    : setOpen(false)
            }
            scroll={scroll}
            sx={{
                ".MuiDialogContent-root": {
                    padding: dialogContentPadding || "1.125rem",
                    maxWidth: dialogContentMaxWidth || dialogMaxWidth || "30.125rem",
                    minWidth: dialogMinWidth || "",
                    minHeight: dialogMinHeight || 'auto',
                    ...(dialogWidth && { width: dialogWidth })
                },
                ".MuiPaper-root": {
                    overflowY: "unset",
                    maxWidth: dialogMaxWidth || "30.125rem",
                    minWidth: dialogMinWidth || "",
                    minHeight: dialogMinHeight || 'auto',
                    ...(dialogWidth && { width: dialogWidth }),
                },
            }}
        >
            {title && (
                <DialogTitle
                    sx={{
                        padding: "1rem 1.125rem",
                    }}
                    data-testid={`core-dialog-title_${title}`}
                >
                    <CoreFormTitle text={title} />
                </DialogTitle>
            )}

            {buttons?.cancelTopRightIcon ? (
                <IconButton
                    aria-label="close"
                    onClick={() => setOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}

            {subTitle && (
                <DialogContent>
                    <DialogContentText>{subTitle}</DialogContentText>
                </DialogContent>
            )}

            <DialogContent
                sx={{
                    borderTop: "none",
                    width: dialogContentWidth || "100%",
                }}
                dividers={scroll === "paper"}
            >
                {/* TODO: move StyledTabs to CoreTab */}
                {isEmpty(tabs) ? (
                    children
                ) : (
                    <>
                        <StyledTabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map((tab: CoreDialogTabData) => {
                                const icon = tab?.tooltipText ? (
                                    <CoreTooltip title={tab.tooltipText}>
                                        <CoreDialogCircleIcon
                                            variant={tab.iconColorVariant}
                                        />
                                    </CoreTooltip>
                                ) : (
                                    tab?.iconColorVariant && (
                                        <CoreDialogCircleIcon
                                            variant={tab.iconColorVariant}
                                        />
                                    )
                                );
                                return (
                                    <StyledTab
                                        key={tab.label}
                                        label={tab.label}
                                        icon={icon}
                                        iconPosition="end"
                                        {...tab?.muiProps}
                                    />
                                );
                            })}
                        </StyledTabs>

                        <Box sx={{ marginTop: "1rem" }}>
                            {tabs.map(
                                (tab: CoreDialogTabData, index: number) => (
                                    <div
                                        key={`content-for-label-${tab.label}`}
                                        hidden={tabValue !== index}
                                    >
                                        {tab.content}
                                    </div>
                                ),
                            )}
                        </Box>
                    </>
                )}
            </DialogContent>

            {(buttons?.cancel ||
                buttons?.discard ||
                buttons?.submit ||
                buttons?.delete) && (
                <StyledDialogActions>
                    <Stack direction="column" spacing={4} width="100%">
                        {/* Error Alert */}
                        {!isEmpty(errorAlert) && (
                            <StyledAlertError severity="error">
                                {errorAlert}
                            </StyledAlertError>
                        )}

                        {!isEmpty(errorAlerts) &&
                           <StyledAlertError severity="error">
                                {errorAlerts.map((error: string, index: number) => {
                                    return (
                                        <div>
                                            {error}
                                        </div>
                                    )
                                })}
                            </StyledAlertError>
                        }

                        {/* Warning Alert */}
                        {discardChangesAlertConfig?.formChanged &&
                            showAlert && (
                                <StyledAlertWarning
                                    variant="standard"
                                    severity="warning"
                                    action={
                                        <div>
                                            <Button
                                                aria-label="discard"
                                                color="inherit"
                                                size="small"
                                                onClick={() => handleDiscard()}
                                            >
                                                {discardChangesAlertConfig?.discardButtonLabel
                                                    ? discardChangesAlertConfig?.discardButtonLabel
                                                    : "LEAVE PAGE"}
                                            </Button>
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                sx={{ marginLeft: "1rem" }}
                                                onClick={() => {
                                                    handleCloseAlert();
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        </div>
                                    }
                                >
                                    {discardChangesAlertConfig?.message
                                        ? discardChangesAlertConfig?.message
                                        : "You have unsaved changes"}
                                </StyledAlertWarning>
                            )}

                        <Stack direction="row" spacing={2} width="100%">
                            <Box flex={1} />
                            {buttons?.cancel && (
                                <CancelButton
                                    onClick={() => {
                                        // If form is dirty, show alert, otherwise close the dialog
                                        // If form is dirty and you click again
                                        // on cancel button, close the dialog

                                        if (showAlert) {
                                            setOpen(false);
                                        } else {
                                            if (
                                                discardChangesAlertConfig?.formChanged
                                            ) {
                                                setShowAlert(true);
                                            } else {
                                                setOpen(false);
                                            }
                                        }
                                    }}
                                    data-testid="cancel-button"
                                    disabled={disabled}
                                >
                                    {buttons?.cancel?.label || "Cancel"}
                                </CancelButton>
                            )}

                            {buttons?.discard && (
                                <DiscardButton
                                    onClick={buttons?.discard?.handleOnDiscard}
                                    data-testid="discard-button"
                                    disabled={disabled}
                                    disableRipple={true}
                                >
                                    {buttons?.discard?.label || "Discard"}
                                </DiscardButton>
                            )}

                            {buttons?.submit && (
                                <SubmitButton
                                    variant="contained"
                                    disabled={
                                        disabled || buttons?.submit?.disabled
                                    }
                                    onClick={async (e) => {
                                        setIsSubmitting(true);

                                        try {
                                            await buttons?.submit?.handleOnSubmit(
                                                e,
                                            );
                                        } finally {
                                            setIsSubmitting(false);
                                        }
                                    }}
                                    data-testid="submit-button"
                                >
                                    {disabled ? (
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <CircularProgress
                                                size="1.25rem"
                                                color="inherit"
                                            />
                                            <Box component="span">
                                                Loading...
                                            </Box>
                                        </Stack>
                                    ) : (
                                        buttons?.submit?.label || "Submit"
                                    )}
                                </SubmitButton>
                            )}

                            {buttons?.delete && (
                                <DeleteButton
                                    onClick={() => {
                                        const {
                                            delete: {
                                                handleOnDelete = () => {},
                                            },
                                        } = buttons || {};

                                        const handleDelete = () => {
                                            handleOnDelete();
                                        };

                                        handleDelete();
                                    }}
                                    data-testid="delete-button"
                                    sx={{
                                        position: buttons?.delete?.position
                                            ? "absolute"
                                            : "unset",
                                        left: buttons?.delete?.position
                                            ? "1.125rem"
                                            : "unset",
                                        marginLeft: buttons?.delete?.position
                                            ? "0 !important"
                                            : "1rem",
                                    }}
                                >
                                    {buttons?.delete?.label || "Delete"}
                                </DeleteButton>
                            )}
                        </Stack>
                    </Stack>
                </StyledDialogActions>
            )}
        </Dialog>
    );
}
