import deepcopy from "deepcopy";
import { ValidationResult } from "../interfaces/Validation";
import ConfigModel, { DataType, ValidationLayer } from '../../../models/RecordBuilder/Field.interface'
import _ from "lodash"
import BuilderFilterByCategory from '../../RecordBuilder/map/filterCategory'
import OriginationViewType from "../../../features/Preferences/RecordBuilder/interfaces/OriginationView.type";
import { MAX_CHARACTER_COUNT } from "../../../features/Preferences/RecordBuilder/views/forms/origination/tile/validation/tileVS";

export default function ValidateOrigination(
    result: ValidationResult,
    options: any,
    configuration: ConfigModel[],
    origination: any = {}
): ValidationResult {
    let response: ValidationResult = deepcopy(result);

    const {
        data = {},
        status,
    } = options;

    const { category } = data;

    const originationBuilderConfig: ConfigModel[] = BuilderFilterByCategory({
        category,
        models: configuration
    }).filter(config => !config.archived)


    const genericMessage = `
        Oops! It seems there are some issues that need your attention before saving your changes: \n
        \n
        ● Certain fields have exceeded character limits or remain empty. \n
        ● Ensure all required fields are filled correctly within the specified limits.\n
        ● Please review the highlighted areas to resolve these issues before proceeding.\n    
    `

    const assignAlert = (key) => {
        response.result = false;
        response.notification = {
            title: `Validation Alert`,
            description: genericMessage,
            maxWidth: "40rem",
            status,
        }
        response.validationKeys = result.validationKeys.concat([key])
    }

    const isEmptyRequiredField = (item): boolean => {
        const required: boolean = !!_.get(item, 'options.validation.required.enabled', false)

        if (required && _.isEmpty(origination[item.key])) {
            assignAlert(item.key)
            return true
        }

        return false
    }

    const isEmptyRequiredArrayField = (item): boolean => {
        const required: boolean = !!_.get(item, 'options.validation.required.enabled', false)

        if (required && !isEmptyRequiredField(item) && _.isArray(origination[item.key])) {
            const nonDeletedOptions = origination[item.key].filter((option) => !option.deleted)

            if (_.isEmpty(nonDeletedOptions)) {
                assignAlert(item.key)
                return true
            }
        }

        return false
    }

    originationBuilderConfig.forEach((configModel: ConfigModel) => {
        const value = origination[configModel.key] || null

        //options
        const editable = _.get(configModel, 'options.editable.enabled', false)
        const readOnly = _.get(configModel, 'options.validation.readOnly.enabled', false)

        if (!readOnly && editable) {

            // String | Text Input
            if (configModel.dataType == DataType.String) {
                if (isEmptyRequiredField(configModel)) {
                    return
                }

                const characterLimit = _.get(configModel, 'options.validation.characterLimit', {})
                if (characterLimit?.enabled && characterLimit?.validation != ValidationLayer.None) {
                    let characterLimitLength = characterLimit.limit || 0

                    if (configModel.view === OriginationViewType.ThreeWay && !characterLimitLength) {
                        characterLimitLength = MAX_CHARACTER_COUNT
                    }
        
                    if (!!characterLimitLength && value?.length > characterLimitLength) {
                        assignAlert(configModel.key)
                        return
                    }
                }
            }

            // Array | SelectMultiple Input
            if (configModel.dataType == DataType.Array) {

                if (isEmptyRequiredArrayField(configModel)) {
                    return
                }

                const rangeLimit = _.get(configModel, 'options.validation.rangeLimit')
                const rangeLimitMin = (rangeLimit?.enabled && rangeLimit?.validation != ValidationLayer.None) ? rangeLimit.min : 0
                const rangeLimitMax = (rangeLimit?.enabled && rangeLimit?.validation != ValidationLayer.None) ? rangeLimit.max : 999999


                let valueActive = []

                if (Array.isArray(value) && !_.isEmpty(value)) {
                    valueActive = value.filter(item => !item.deleted)
                }

                if (Array.isArray(valueActive) && !_.isEmpty(valueActive) && rangeLimit?.enabled) {

                    if (!!rangeLimitMin 
                        && (valueActive.length < rangeLimitMin || valueActive.length > rangeLimitMax)
                    ) {
                        assignAlert(configModel.key)
                        return
                    }
                }
            }

            // Object | Select Input
            if (configModel.dataType == DataType.Object) {
                if (isEmptyRequiredField(configModel)) {
                    return
                }
            }
        }
    })

    return response;
}
