import { RootStoreInitialStateInterface, AncillariesStoreInitialStateInterface } from "../interfaces"

export const rootStoreInitialState: RootStoreInitialStateInterface = {
    cmsData: {},
    disabled: true,
    category: ""
}

export const ancillariesStoreInitialState: AncillariesStoreInitialStateInterface = {
    data: [],
    formData: {},
    multiLevelViewData: [],
    createNewType: null,
    updateType: null,
};