import React, { useContext } from "react";
import { SectionInterface } from "./interface";
import Awards from "../../../../features/Awards";
import { AppContext } from "../../store/context";
import _ from "lodash";
import { getSectionCommonProps } from "../../actions";
import { v2_credits } from "../../../../features/RecordsSearch/config/recordServiceV2Fields";

const SectionAwards = (props: SectionInterface) => {
    const { state } = useContext(AppContext);

    const { data = {} } = props;

    const { script } = state;

    const contributors = data[v2_credits]?.contributors 
        ? data[v2_credits].contributors 
        : data.contributors;

    return (
        <React.Fragment>
            <Awards
                {...getSectionCommonProps({ ...props, field: "awards" })}
                name="awards"
                className="meta-record-awards"
                title="Awards"
                data={data.awards || []}
                contributors={contributors || []}
                lastSection={true}
                script={script}
                allowCreateNew={true}
            />
        </React.Fragment>
    );
};

export default SectionAwards;
