import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import { useStore } from "../../Global/store/store";
import Select from "../../Select"
import { envTest } from "../../Environment"
import { GetFromLocalStorage, SetLocalStorage } from "../../Storage/localStorage";
import { CLIENT_MANAGED_RECORD_TYPES, CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES } from "../../ClientManagedOptions/options";
import HanldeFetchDataListRequest from "../../../services/ApiClients/CML/HanldeFetchDataListRequest";
import { isClientManagedRecordTypes } from "../../ClientManagedOptions/record_types";
import isActiveRecordBuilder from '../../../components/RecordBuilder/IsActive'
import { ORIGINATION } from "../../../models/RecordBuilder";
import { isFieldEnabledForCategory } from "../../../components/RecordBuilder/utils/fieldCategoryValidator";

interface RecordTypeSelectInterface {
    category: string,
    value: string,
    wrapperStyles?: any,
    fallBackData?: Array<any>,
    name?: string,
    id?: string,
    label?: string | false,
    disabled?: boolean,
    error?: boolean,
    handleChange: (item: string) => void
}

function getOriginationConfig(recordBuilder, clientFeatures = {}) {
    if (isActiveRecordBuilder({ page: ORIGINATION,  clientFeatures })) {
        return recordBuilder[ORIGINATION] || []
    }

    return false
}

function getOriginationRecordTypeField(recordBuilder = {}, clientFeatures = {}) {
    const config = getOriginationConfig(recordBuilder, clientFeatures)

    if(config === false) {
        return {
            displayName:  "Record Type",
            archived: false
        }
    }

    return config?.find((config) => config.key === "type") || {}
}

const RecordTypeSelect = (props: RecordTypeSelectInterface): JSX.Element => {
    const {
        category = "",
        value,
        fallBackData = [],
        wrapperStyles = {},
        name = "selectType",
        id = "selectType",
        label = false,
        disabled,
        error,
        handleChange = () => {}
    } = props

    const [state] = useStore() || [{}]

    const {
        cmsData = {},
        recordBuilder = {}
    } = state

    const {
        apiUrls = {},
        clientFeatures = {}
    } = cmsData

    const [data, setData] = useState<any[]>([])
    const [field, setField] = useState<any>({})
    const isClientManaged: boolean = isClientManagedRecordTypes({ clientFeatures })

    if (!envTest) {
        useEffect(() => {
            setField(
                getOriginationRecordTypeField(recordBuilder, clientFeatures)
            )
        }, [recordBuilder[ORIGINATION]])
    }

    useEffect(() => {
        if (!isClientManaged) {
            setData(fallBackData)
        } else {
            let recordTypes: any = GetFromLocalStorage(CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES)

            if (recordTypes) {
                setData(JSON.parse(recordTypes))
            } else {
                HanldeFetchDataListRequest({
                    apiUrls,
                    list: CLIENT_MANAGED_RECORD_TYPES,
                    onError: (error) => {
                        //
                    },
                    onSuccess: (list: Array<any>) => {
                        setData(list)

                        SetLocalStorage({
                            name: CLIENT_MANAGED_RECORD_TYPES_WITH_CATEGORIES,
                            value: JSON.stringify(list)
                        })
                    },
                })
            }
        }
    }, [])

    let categoryTypes = isClientManaged ? [] : fallBackData
    if (isClientManaged && category && !isEmpty(data)) {
        categoryTypes = data.filter((item: any) => item?.categories.includes(category))
    }

    const isFieldEnabled = isFieldEnabledForCategory({
        field,
        category 
    })

    if (!isFieldEnabled) {
        return <></>
    }

    return (
        <Select
            label={label}
            name={name}
            id={id}
            value={value}
            readOnly={disabled}
            disabled={disabled}
            error={error}
            handleChange={(item: string) => handleChange(item)}
            disabledText={`Select ${field.displayName}`}
            noneSelectedText="None Selected"
            wrapperStyles={wrapperStyles}
            key={`category-type-${value}`}
            data={categoryTypes?.map((item: any) => {
                return {
                    text: item.name,
                    value: item.value
                }
            })}
        />
    );
};

export default RecordTypeSelect;
